// src/components/blog/ArticleMeta.jsx
import React from 'react';
import { Clock, ChartBar, Calendar, User } from 'lucide-react';

const ArticleMeta = ({ readingTime, difficulty, date, author }) => {
  return (
    <div className="flex flex-wrap items-center gap-4 text-gray-600 mb-8">
      <div className="flex items-center">
        <Calendar className="w-4 h-4 mr-1.5" />
        <span>{date}</span>
      </div>
      <div className="flex items-center">
        <User className="w-4 h-4 mr-1.5" />
        <span>{author}</span>
      </div>
      <div className="flex items-center">
        <Clock className="w-4 h-4 mr-1.5" />
        <span>{readingTime} read</span>
      </div>
      <div className="flex items-center">
        <ChartBar className="w-4 h-4 mr-1.5" />
        <span className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-sm">
          {difficulty} level
        </span>
      </div>
    </div>
  );
};

export default ArticleMeta;