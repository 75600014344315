// src/utils/constants/puzzleConstants.js

/**
 * Thresholds for puzzle-specific operations
 */
export const THRESHOLDS = {
    // Edge piece scoring
    EDGE_PIECE_SCORES: {
      CORNER: 1.0,
      EDGE: 0.8,
      INTERNAL: 0.5
    },
  
    // Pattern matching scores
    PATTERN_SCORE: {
      DEFAULT: 0.5,
      BASIC_MATCH: 0.7,
      PERFECT_MATCH: 1.0,
      NO_MATCH: 0.0
    },
  
    // Pattern analysis weights
    PATTERN_WEIGHTS: {
      COLOR: 0.4,
      EDGES: 0.3,
      TEXTURE: 0.3
    },
  
    // Match confidence levels
    MATCH_CONFIDENCE: {
      HIGH: 0.9,
      MEDIUM: 0.7,
      LOW: 0.5
    },
  
    // Analysis weights for different aspects
    ANALYSIS_WEIGHTS: {
      PATTERN: 0.4,
      SHAPE: 0.3,
      CONTEXT: 0.3
    },
  
    // Region analysis
    REGION_SCORES: {
      CORNER_REGION: 1.0,
      EDGE_REGION: 0.8,
      INTERNAL_REGION: 0.6
    },
  
    // Color analysis
    COLOR_ANALYSIS: {
      HISTOGRAM_BINS: 64, // 4x4x4 color bins
      SIMILARITY_THRESHOLD: 0.85,
      VARIANCE_WEIGHT: 0.3
    },
  
    // Texture analysis
    TEXTURE_ANALYSIS: {
      LBP_POINTS: 8,
      LBP_RADIUS: 1,
      VARIANCE_THRESHOLD: 0.2
    }
  };
  
  /**
   * Configuration for puzzle solving operations
   */
  export const PUZZLE_CONFIG = {
    // Grid analysis configuration
    GRID: {
      MIN_SIZE: 2, // Minimum puzzle dimension
      MAX_SIZE: 100, // Maximum puzzle dimension
      ASPECT_RATIO_TOLERANCE: 0.1 // Allowed deviation from square
    },
  
    // Piece analysis configuration
    PIECE: {
      EDGE_SAMPLE_POINTS: 10, // Points to sample along each edge
      CORNER_ANGLE_TOLERANCE: 15, // Degrees
      MIN_EDGE_LENGTH: 20 // Pixels
    },
  
    // Match analysis settings
    MATCHING: {
      MAX_SUGGESTIONS: 3, // Number of suggestions per gap
      MIN_CONFIDENCE: 0.6,
      ROTATION_STEPS: 4 // 90-degree increments
    },
  
    // Solver behavior
    SOLVER: {
      PREFER_EDGES: true, // Start with edges/corners
      MAX_BACKTRACK_STEPS: 3,
      TIMEOUT_MS: 30000 // 30 seconds
    }
  };
  
  /**
   * Messages for solver feedback
   */
  export const SOLVER_MESSAGES = {
    HINTS: {
      EDGE_PIECE: "Focus on edge pieces for this region",
      STRONG_MATCH: "This piece is a very strong match",
      PATTERN_MATCH: "Try matching the patterns on the edges",
      NO_MATCH: "No suitable matches found for this position"
    },
    ERRORS: {
      NO_GAPS: "No valid gaps found in the puzzle",
      NO_PIECES: "No remaining pieces to analyze",
      INVALID_MATCH: "Invalid piece match detected"
    },
    SUCCESS: {
      PIECE_PLACED: "Piece placed successfully",
      REGION_COMPLETED: "Region completed successfully",
      PUZZLE_COMPLETED: "Puzzle completed successfully"
    }
  };
  
  /**
   * Debug levels for solver operations
   */
  export const DEBUG_LEVELS = {
    NONE: 0,
    BASIC: 1,
    DETAILED: 2,
    VERBOSE: 3
  };
  
  /**
   * States for puzzle pieces and positions
   */
  export const PIECE_STATES = {
    UNPLACED: 'unplaced',
    SUGGESTED: 'suggested',
    PLACED: 'placed',
    LOCKED: 'locked',
    INVALID: 'invalid'
  };
  
  /**
   * Region types for puzzle analysis
   */
  export const REGION_TYPES = {
    CORNER: 'corner',
    EDGE: 'edge',
    INTERNAL: 'internal',
    UNKNOWN: 'unknown'
  };