// src/services/sudoku/sudokuApi.js
import axios from 'axios';

const SUDOKU_API_URL = process.env.REACT_APP_SUDOKU_API_URL;

class SudokuApiService {
  constructor() {
    if (!SUDOKU_API_URL) {
      console.error('API URL is missing');
    }
    
    this.client = axios.create({
      baseURL: SUDOKU_API_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000,
      withCredentials: true, // Enable credentials
    });

    this.client.interceptors.response.use(
      response => response,
      error => {
        console.error('API Error:', error.response?.data || error.message);
        if (error.response?.status === 413) {
          throw new Error('Image file is too large. Please use a smaller image.');
        }
        if (error.response?.status === 500) {
          throw new Error('Server error processing image. Please try a different image.');
        }
        if (error.response?.data?.detail) {
          throw new Error(error.response.data.detail);
        }
        throw new Error('Failed to connect to the server. Please try again.');
      }
    );
  }

  async checkApiStatus(retryCount = 0, maxRetries = 3) {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Checking API status at:', SUDOKU_API_URL);
      }
      const response = await this.client.get('/api/v1/health');
      return response.data?.status === 'healthy';
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('API status check failed:', error);
      }
      
      // Handle Heroku cold start
      if (retryCount < maxRetries) {
        const waitTime = (retryCount + 1) * 3000; // Progressive delay: 3s, 6s, 9s
        if (process.env.NODE_ENV === 'development') {
          console.log(`Waiting ${waitTime/1000}s for Heroku dyno to wake up...`);
        }
        await new Promise(resolve => setTimeout(resolve, waitTime));
        return this.checkApiStatus(retryCount + 1, maxRetries);
      }

      // Try localhost fallback if applicable
      if (SUDOKU_API_URL.includes('0.0.0.0')) {
        const altUrl = SUDOKU_API_URL.replace('0.0.0.0', 'localhost');
        // Only log in development
        if (process.env.NODE_ENV === 'development') {
          console.log('Trying alternate URL:', altUrl);
        }
        try {
          const altResponse = await axios.get(`${altUrl}/api/v1/health`);
          return altResponse.data?.status === 'healthy';
        } catch (altError) {
          if (process.env.NODE_ENV === 'development') {
            console.error('Alternate URL check failed:', altError);
          }
          return false;
        }
      }
      return false;
    }
  }

  setProcessingStateCallback(callback) {
    this.onProcessingStateChange = callback;
  }

  async processImage(imageData) {
    try {
      this.onProcessingStateChange?.('WAKING_UP');
      
      // More aggressive initial check for cold starts
      const isAwake = await this.checkApiStatus(0, 3);
      
      if (!isAwake) {
        throw new Error('API service is not responding. The server might be starting up, please try again in a few moments.');
      }

      this.onProcessingStateChange?.('PROCESSING');
      
      const formData = new FormData();
      const file = imageData.get('file');
      
      // Ensure we're sending the file with the correct content type
      formData.append('file', file, {
        contentType: file.type || 'image/jpeg'
      });
      
      try {
        console.log('Attempting /process-sudoku endpoint...');
        const response = await this.client.post('/api/v1/process-sudoku', formData);
        this.onProcessingStateChange?.('COMPLETE');
        return this._processResponse(response);
      } catch (firstError) {
        console.log('Falling back to /process-image endpoint...', firstError);
        const response = await this.client.post('/api/v1/process-image', formData);
        return this._processResponse(response);
      }
    } catch (error) {
      this.onProcessingStateChange?.('ERROR');
      console.error('Processing error:', error);
      this._handleError(error);
      throw error;
    }
  }

  async validateGrid(grid) {
    try {
      const response = await this.client.post('/api/v1/validate-grid', { 
        grid,
        validate_solution: true
      });

      return {
        isValid: response.data.is_valid,
        errors: response.data.errors || [],
        warnings: response.data.warnings || []
      };

    } catch (error) {
      throw error;
    }
  }

  _processResponse(response) {
    const data = response.data;
    
    // Check for basic success/failure
    if (!data.success) {
      throw new Error(data.error || 'Failed to process image');
    }

    // Extract the core data we need
    const result = {
      success: data.success,
      grid: data.grid,
      confidences: data.confidences,
      warnings: this._extractWarnings(data)
    };

    // Only include debug images if they're valid bytes
    if (data.debug_images) {
      try {
        // Filter out invalid debug images
        const validDebugImages = {};
        Object.entries(data.debug_images).forEach(([key, value]) => {
          if (value && typeof value === 'string') {  // Check if it's a base64 string or similar
            validDebugImages[key] = value;
          }
        });
        
        if (Object.keys(validDebugImages).length > 0) {
          result.debug_images = validDebugImages;
        }
      } catch (e) {
        console.warn('Failed to process debug images:', e);
        // Continue without debug images
      }
    }

    // Include debug info if available
    if (data.debug_info) {
      result.debug_info = data.debug_info;
    }

    return result;
  }

  _handleError(error) {
    throw error;
  }

  _extractWarnings(responseData) {
    const warnings = [];

    if (responseData.confidences) {
      responseData.grid.forEach((row, i) => {
        row.forEach((digit, j) => {
          if (digit && responseData.confidences[i][j] < 0.6) {
            warnings.push({
              type: 'low_confidence',
              message: `Low confidence (${(responseData.confidences[i][j] * 100).toFixed(1)}%) for digit at row ${i + 1}, column ${j + 1}`,
              position: { row: i, col: j },
              confidence: responseData.confidences[i][j]
            });
          }
        });
      });
    }

    if (responseData.warnings) {
      warnings.push(...responseData.warnings);
    }

    return warnings;
  }
}

export const sudokuApi = new SudokuApiService();