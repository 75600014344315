// src/utils/solverUtils.js

import { THRESHOLDS } from '../constants/jigsaw/puzzleConstants';

export const analyzePuzzleStructure = (gaps, pieces, referenceData) => {
  if (!gaps || !Array.isArray(gaps) || gaps.length === 0) {
    return [];
  }

  if (!referenceData) {
    throw new Error('Reference data is missing');
  }

  const corners = findCornerGaps(gaps);
  
  return gaps.map(gap => {
    const surroundingGaps = getSurroundingGaps(gap, gaps);
    const edgeScore = calculateEdgeScore(gap, corners);
    const contextScore = calculateContextScore(gap, surroundingGaps);
    const patternScore = calculatePatternScore(gap, referenceData);
    
    return {
      ...gap,
      analysisScore: {
        edge: edgeScore,
        context: contextScore,
        pattern: patternScore,
        total: (edgeScore + contextScore + patternScore) / 3
      }
    };
  });
};

export const findCornerGaps = (gaps) => {
  if (!gaps || !Array.isArray(gaps) || gaps.length === 0) {
    return [];
  }

  const maxRow = Math.max(...gaps.map(g => g.position.row));
  const maxCol = Math.max(...gaps.map(g => g.position.col));

  return gaps.filter(gap => {
    const isCorner = (
      (gap.position.row === 0 && gap.position.col === 0) || // Top-left
      (gap.position.row === 0 && gap.position.col === maxCol) || // Top-right
      (gap.position.row === maxRow && gap.position.col === 0) || // Bottom-left
      (gap.position.row === maxRow && gap.position.col === maxCol) // Bottom-right
    );
    return isCorner;
  });
};

export const getSurroundingGaps = (gap, allGaps) => {
  if (!gap || !allGaps) return {};

  return {
    top: allGaps.find(g => g.position.row === gap.position.row - 1 && 
                          g.position.col === gap.position.col),
    right: allGaps.find(g => g.position.row === gap.position.row && 
                            g.position.col === gap.position.col + 1),
    bottom: allGaps.find(g => g.position.row === gap.position.row + 1 && 
                             g.position.col === gap.position.col),
    left: allGaps.find(g => g.position.row === gap.position.row && 
                           g.position.col === gap.position.col - 1)
  };
};

export const calculateEdgeScore = (gap, corners) => {
  if (!gap || !corners) return THRESHOLDS.EDGE_PIECE_SCORES.INTERNAL;

  if (corners.includes(gap)) return THRESHOLDS.EDGE_PIECE_SCORES.CORNER;
  if (gap.position.row === 0 || gap.position.col === 0) return THRESHOLDS.EDGE_PIECE_SCORES.EDGE;
  return THRESHOLDS.EDGE_PIECE_SCORES.INTERNAL;
};

export const calculateContextScore = (gap, surroundingGaps) => {
  if (!gap || !surroundingGaps) return 0;

  const filledNeighbors = Object.values(surroundingGaps)
    .filter(Boolean).length;
  return filledNeighbors / 4;
};

export const calculatePatternScore = (gap, referenceData) => {
  if (!gap || !referenceData) return THRESHOLDS.PATTERN_SCORE.DEFAULT;

  try {
    const { x, y, width, height } = gap;
    const gapArea = getImageRegion(gap.imageData, { x, y, width, height });
    const referenceArea = getImageRegion(referenceData, { x, y, width, height });

    const colorDistribution = compareColorDistribution(gapArea, referenceArea);
    const edgePatterns = compareEdgePatterns(gapArea, referenceArea);
    const textureSimularity = compareTextures(gapArea, referenceArea);

    return (
      colorDistribution * THRESHOLDS.PATTERN_WEIGHTS.COLOR +
      edgePatterns * THRESHOLDS.PATTERN_WEIGHTS.EDGES +
      textureSimularity * THRESHOLDS.PATTERN_WEIGHTS.TEXTURE
    );
  } catch (error) {
    console.error('Pattern analysis failed:', error);
    return THRESHOLDS.PATTERN_SCORE.DEFAULT;
  }
};

const getImageRegion = (imageData, bounds) => {
  const { x, y, width, height } = bounds;
  const region = new Uint8ClampedArray(width * height * 4);
  
  for (let row = 0; row < height; row++) {
    for (let col = 0; col < width; col++) {
      const sourceIdx = ((y + row) * imageData.width + (x + col)) * 4;
      const targetIdx = (row * width + col) * 4;
      
      region[targetIdx] = imageData.data[sourceIdx];         // R
      region[targetIdx + 1] = imageData.data[sourceIdx + 1]; // G
      region[targetIdx + 2] = imageData.data[sourceIdx + 2]; // B
      region[targetIdx + 3] = imageData.data[sourceIdx + 3]; // A
    }
  }
  
  return { data: region, width, height };
};

const compareColorDistribution = (area1, area2) => {
  const hist1 = createColorHistogram(area1);
  const hist2 = createColorHistogram(area2);
  
  return compareHistograms(hist1, hist2);
};

const compareEdgePatterns = (area1, area2) => {
  const edges1 = detectEdgePattern(area1);
  const edges2 = detectEdgePattern(area2);
  
  return compareEdges(edges1, edges2);
};

const compareTextures = (area1, area2) => {
  const texture1 = calculateTextureFeatures(area1);
  const texture2 = calculateTextureFeatures(area2);
  
  return compareTextureFeatures(texture1, texture2);
};

const createColorHistogram = (area) => {
  const bins = new Array(64).fill(0);
  const { data, width, height } = area;
  
  for (let i = 0; i < data.length; i += 4) {
    const r = Math.floor(data[i] / 64);
    const g = Math.floor(data[i + 1] / 64);
    const b = Math.floor(data[i + 2] / 64);
    
    const binIndex = (r * 16) + (g * 4) + b;
    bins[binIndex]++;
  }
  
  const total = width * height;
  return bins.map(bin => bin / total);
};

const compareHistograms = (hist1, hist2) => {
  let similarity = 0;
  for (let i = 0; i < hist1.length; i++) {
    const diff = hist1[i] - hist2[i];
    similarity += (diff * diff) / (hist1[i] + hist2[i] + 1e-10);
  }
  
  return 1 - Math.sqrt(similarity);
};

const detectEdgePattern = (area) => {
  const { data, width, height } = area;
  const edges = new Float32Array(width * height);
  
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const idx = (y * width + x) * 4;
      
      const gx = (data[idx - 4] - data[idx + 4]) / 2;
      const gy = (data[idx - width * 4] - data[idx + width * 4]) / 2;
      
      edges[y * width + x] = Math.sqrt(gx * gx + gy * gy);
    }
  }
  
  return edges;
};

const compareEdges = (edges1, edges2) => {
  if (!edges1 || !edges2 || edges1.length !== edges2.length) {
    return 0;
  }

  let similarity = 0;
  const length = edges1.length;

  // Compare edge strengths
  for (let i = 0; i < length; i++) {
    const diff = Math.abs(edges1[i] - edges2[i]);
    similarity += 1 - Math.min(diff / 255, 1);
  }

  // Calculate gradient orientations similarity
  const orientations1 = calculateEdgeOrientations(edges1);
  const orientations2 = calculateEdgeOrientations(edges2);
  const orientationSimilarity = compareOrientations(orientations1, orientations2);

  // Combine magnitude and orientation similarities
  return (similarity / length * 0.6) + (orientationSimilarity * 0.4);
};

const calculateEdgeOrientations = (edges) => {
  const width = Math.sqrt(edges.length); // Assuming square area
  const orientations = new Float32Array(edges.length);

  for (let y = 1; y < width - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const idx = y * width + x;
      const gx = edges[idx + 1] - edges[idx - 1];
      const gy = edges[idx + width] - edges[idx - width];
      orientations[idx] = Math.atan2(gy, gx);
    }
  }

  return orientations;
};

const compareOrientations = (orientations1, orientations2) => {
  let similarity = 0;
  const length = orientations1.length;

  for (let i = 0; i < length; i++) {
    const diff = Math.abs(orientations1[i] - orientations2[i]);
    // Normalize angle difference to [0, 1]
    similarity += 1 - Math.min(diff / Math.PI, 1);
  }

  return similarity / length;
};

const calculateTextureFeatures = (area) => {
  const { data, width, height } = area;
  const features = new Uint8Array(256).fill(0);
  
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const center = getPixelIntensity(data, x, y, width);
      let pattern = 0;
      
      for (let i = 0; i < 8; i++) {
        const nx = x + Math.cos(i * Math.PI / 4);
        const ny = y + Math.sin(i * Math.PI / 4);
        const neighbor = getPixelIntensity(data, Math.round(nx), Math.round(ny), width);
        
        pattern |= (neighbor > center) << i;
      }
      
      features[pattern]++;
    }
  }
  
  return features;
};

const getPixelIntensity = (data, x, y, width) => {
  const idx = (y * width + x) * 4;
  return (data[idx] + data[idx + 1] + data[idx + 2]) / 3;
};

const compareTextureFeatures = (features1, features2) => {
  let similarity = 0;
  for (let i = 0; i < features1.length; i++) {
    const diff = features1[i] - features2[i];
    const sum = features1[i] + features2[i];
    if (sum > 0) {
      similarity += (diff * diff) / sum;
    }
  }
  
  return 1 / (1 + similarity);
};

export const findBestGap = (analyzedGaps) => {
  if (!analyzedGaps || analyzedGaps.length === 0) {
    return null;
  }

  return analyzedGaps.reduce((best, current) => {
    return (current.analysisScore.total > (best?.analysisScore.total || 0)) 
      ? current 
      : best;
  }, analyzedGaps[0]);
};

export const generateSolverHint = (gap, matches) => {
  if (!gap || !matches || matches.length === 0) {
    return {
      message: "No suggestions available",
      context: "Please try analyzing the puzzle again"
    };
  }

  const message = gap.analysisScore.edge > THRESHOLDS.EDGE_PIECE_SCORES.EDGE 
    ? "Focus on edge pieces for this region"
    : matches[0]?.confidence > THRESHOLDS.MATCH_CONFIDENCE.HIGH 
      ? "This piece is a very strong match"
      : "Try matching the patterns on the edges";

  const context = `This gap has an analysis score of ${gap.analysisScore.total.toFixed(2)}. 
                  The best matching piece has a confidence of ${matches[0]?.confidence.toFixed(2)}.`;

  return { message, context };
};

export const calculateSolverStats = (gaps, pieces, bestMatch) => {
  return {
    remainingPieces: pieces?.length || 0,
    completedAreas: Math.round(
      ((gaps?.length || 0) - (pieces?.length || 0)) / (gaps?.length || 1) * 100
    ),
    currentConfidence: Math.round((bestMatch?.confidence || 0) * 100)
  };
};