// src/utils/sudoku/generator/simplePuzzleGenerator.js

const DIFFICULTY_SETTINGS = {
  easy: {
    minClues: 35,
    maxClues: 45,
    symmetrical: true,
    minPerRegion: 3  // Minimum numbers per 3x3 region
  },
  medium: {
    minClues: 27,
    maxClues: 34,
    symmetrical: true,
    minPerRegion: 2
  },
  hard: {
    minClues: 22,
    maxClues: 26,
    symmetrical: true,
    minPerRegion: 2
  },
  expert: {
    minClues: 17,
    maxClues: 21,
    symmetrical: true,
    minPerRegion: 1
  }
};

export const shuffle = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const canPlaceNumber = (grid, row, col, num) => {
  // Check row
  for (let x = 0; x < 9; x++) {
    if (grid[row][x] === num) return false;
  }

  // Check column
  for (let x = 0; x < 9; x++) {
    if (grid[x][col] === num) return false;
  }

  // Check 3x3 box
  const boxRow = Math.floor(row / 3) * 3;
  const boxCol = Math.floor(col / 3) * 3;
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      if (grid[boxRow + i][boxCol + j] === num) return false;
    }
  }

  return true;
};

const generateSolvedGrid = () => {
  const grid = Array(9).fill().map(() => Array(9).fill(0));
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const fillGrid = (row = 0, col = 0) => {
    if (col === 9) {
      row++;
      col = 0;
    }
    if (row === 9) return true;

    if (grid[row][col] !== 0) {
      return fillGrid(row, col + 1);
    }

    const shuffledNumbers = shuffle(numbers);
    for (const num of shuffledNumbers) {
      if (canPlaceNumber(grid, row, col, num)) {
        grid[row][col] = num;
        if (fillGrid(row, col + 1)) return true;
        grid[row][col] = 0;
      }
    }
    return false;
  };

  fillGrid();
  return grid;
};

const getRegionCounts = (puzzle) => {
  const regions = Array(9).fill(0);
  
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      if (puzzle[i][j] !== 0) {
        const regionIndex = Math.floor(i / 3) * 3 + Math.floor(j / 3);
        regions[regionIndex]++;
      }
    }
  }
  
  return regions;
};

const hasMinimumRegionClues = (puzzle, minPerRegion) => {
  const regions = getRegionCounts(puzzle);
  return regions.every(count => count >= minPerRegion);
};

const isUniqueSolution = (grid) => {
  let solutions = 0;
  const MAX_SOLUTIONS = 2;
  
  const solve = (row = 0, col = 0) => {
    if (solutions >= MAX_SOLUTIONS) return;
    
    if (row === 9) {
      solutions++;
      return;
    }
    
    const nextRow = col === 8 ? row + 1 : row;
    const nextCol = col === 8 ? 0 : col + 1;
    
    if (grid[row][col] !== 0) {
      solve(nextRow, nextCol);
      return;
    }
    
    for (let num = 1; num <= 9; num++) {
      if (canPlaceNumber(grid, row, col, num)) {
        grid[row][col] = num;
        solve(nextRow, nextCol);
        grid[row][col] = 0;
      }
    }
  };
  
  solve();
  return solutions === 1;
};

const removeNumbers = (grid, settings) => {
  const puzzle = grid.map(row => [...row]);
  const cells = [];
  
  // Create list of cell positions with region information
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      const region = Math.floor(row / 3) * 3 + Math.floor(col / 3);
      cells.push({ row, col, region });
    }
  }
  
  // Shuffle cells within each region
  const regionCells = Array(9).fill().map(() => []);
  cells.forEach(cell => {
    regionCells[cell.region].push(cell);
  });
  
  regionCells.forEach(region => {
    for (let i = region.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [region[i], region[j]] = [region[j], region[i]];
    }
  });
  
  // Flatten shuffled regions back into single array
  const shuffledCells = regionCells.flat();
  
  // Calculate target number of clues
  const targetClues = Math.floor(
    Math.random() * (settings.maxClues - settings.minClues + 1) + settings.minClues
  );
  
  let currentClues = 81;
  let cellIndex = 0;
  
  while (currentClues > targetClues && cellIndex < shuffledCells.length) {
    const { row, col } = shuffledCells[cellIndex];
    const oppositeRow = 8 - row;
    const oppositeCol = 8 - col;
    
    // Try removing the number
    const temp = puzzle[row][col];
    puzzle[row][col] = 0;
    
    // If symmetrical, remove opposite number too
    let tempOpposite;
    if (settings.symmetrical && !(row === 4 && col === 4)) {
      tempOpposite = puzzle[oppositeRow][oppositeCol];
      puzzle[oppositeRow][oppositeCol] = 0;
      currentClues -= 2;
    } else {
      currentClues--;
    }
    
    // Check if still valid
    const isValid = isUniqueSolution(puzzle.map(row => [...row])) &&
                   hasMinimumRegionClues(puzzle, settings.minPerRegion);
    
    // If not valid, restore numbers
    if (!isValid) {
      puzzle[row][col] = temp;
      if (settings.symmetrical && !(row === 4 && col === 4)) {
        puzzle[oppositeRow][oppositeCol] = tempOpposite;
        currentClues += 2;
      } else {
        currentClues++;
      }
    }
    
    cellIndex++;
  }
  
  return puzzle;
};

export const generatePuzzle = async ({
  difficulty = 'medium',
  symmetry = 'none',
  onProgress = () => {}
}) => {
  console.log('Generator called with:', { difficulty, symmetry });
  
  const settings = DIFFICULTY_SETTINGS[difficulty];
  if (!settings) throw new Error('Invalid difficulty level');
  
  try {
    // Generate solved grid
    onProgress(0.2);
    const solution = generateSolvedGrid();
    
    // Remove numbers based on difficulty
    onProgress(0.4);
    let puzzle = removeNumbers(solution, {
      ...settings,
      symmetrical: symmetry === 'central'
    });
    
    // Validate final puzzle
    onProgress(0.8);
    const givenNumbers = puzzle.flat().filter(x => x !== 0).length;
    if (givenNumbers < settings.minClues) {
      throw new Error(`Not enough clues (${givenNumbers}). Minimum required: ${settings.minClues}`);
    }
    
    onProgress(1);
    return {
      puzzle,
      solution,
      stats: {
        difficulty,
        givenNumbers,
        attempts: 1
      }
    };
  } catch (error) {
    console.error('Generation error:', error);
    throw error;
  }
};