import React from 'react';
import { Link } from 'react-router-dom';

const BlogSidebar = ({ categories, recentPosts }) => {
  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-lg font-semibold mb-4">Categories</h3>
        <ul className="space-y-2">
          {categories.map(category => (
            <li key={category.slug}>
              <Link 
                to={`/blog/category/${category.slug}`}
                className="text-gray-600 hover:text-blue-600"
              >
                {category.name} ({category.count})
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-4">Recent Posts</h3>
        <ul className="space-y-4">
          {recentPosts.map(post => (
            <li key={post.slug}>
              <Link 
                to={`/blog/${post.slug}`}
                className="text-gray-600 hover:text-blue-600"
              >
                {post.title}
              </Link>
              <p className="text-sm text-gray-500">{post.date}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogSidebar;