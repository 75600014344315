import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import { Button } from '../ui/button';

export const SuccessOverlay = ({ onContinue }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="fixed inset-0 bg-black/50 flex items-center justify-center"
  >
    <div className="bg-white p-6 rounded-xl text-center">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring" }}
      >
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
      </motion.div>
      <h3 className="text-xl font-bold mb-2">Puzzle Detected!</h3>
      <p className="text-gray-600 mb-4">
        We've detected your Sudoku puzzle. Ready to start solving?
      </p>
      <Button onClick={onContinue}>
        Start Solving
      </Button>
    </div>
  </motion.div>
);