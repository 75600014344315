// src/App.js
import React, { useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GoogleAnalytics from './components/common/GoogleAnalytics';
import ScrollToTop from './components/common/ScrollToTop';

// Layouts and Error Boundaries
import Layout from './components/common/layout/Layout';
import ErrorBoundary from './components/common/ErrorBoundary';

// Page Components
import HomePage from './pages/common/HomePage';
import AboutPage from './pages/common/AboutPage';
import ChangeLog from './pages/common/ChangeLog';
import ContactPage from './pages/common/ContactPage';
import PrivacyPolicy from './pages/common/PrivacyPolicyPage';

// Sudoku Documentation Pages
import DocumentationPage from './pages/common/DocumentationPage';
import FAQPage from './pages/common/FAQPage';
import TipsPage from './pages/common/TipsPage';
import GettingStartedPage from './pages/common/GettingStartedPage';
import TechniquesPage from './pages/common/TechniquesPage';

// Puzzle Solvers
import SudokuSolver from './pages/sudoku/SudokuSolver';
import SudokuGenerator from './pages/sudoku/SudokuGenerator';
import JigsawSolver from './pages/jigsaw/JigsawSolver';

// Results and Testing Components
import JigsawResults from './pages/jigsaw/Results';

// Blog
import BlogPage from './pages/blog';
import BlogPost from './pages/blog/BlogPost';

// Styles
import './styles/common/App.css';

function App() {
  const handleGlobalError = useCallback((error, errorInfo) => {
    console.error('Uncaught error:', error);
    // You might want to send this to an error tracking service
  }, []);

  return (
    <>
      <GoogleAnalytics />
      <Router>
        <ScrollToTop />
        <ErrorBoundary onError={handleGlobalError}>
          <Layout>
            <Routes>
              {/* Main Routes */}
              <Route path="/" element={<HomePage />} />
              
              {/* Puzzle Solver Routes */}
              <Route path="/sudoku-solver" element={<SudokuSolver />} />
              <Route path="/sudoku-generator" element={<SudokuGenerator />} />
              <Route path="/jigsaw-solver" element={<JigsawSolver />} />
              <Route path="/jigsaw-solver/results" element={<JigsawResults />} />
              
              {/* Blog Routes */}
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              
              {/* Information Pages */}
              <Route path="/about" element={<AboutPage />} />
              <Route path="/changelog" element={<ChangeLog />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* Sudoku Documentation Pages */}
              <Route path="/sudoku/docs" element={<DocumentationPage />} />
              <Route path="/sudoku/docs/faqs" element={<FAQPage />} />
              <Route path="/sudoku/docs/getting-started" element={<GettingStartedPage />} />
              <Route path="/sudoku/docs/tips" element={<TipsPage />} />
              <Route path="/sudoku/docs/techniques" element={<TechniquesPage />} />
              
              {/* Catch-all route for 404 */}
              <Route path="*" element={
                <div className="flex flex-col items-center justify-center min-h-[60vh]">
                  <h1 className="text-3xl font-bold text-gray-800">Page Not Found</h1>
                  <p className="mt-2 text-gray-600">The page you're looking for doesn't exist.</p>
                </div>
              } />
            </Routes>
          </Layout>
        </ErrorBoundary>
      </Router>
    </>
  );
}

export default App;