import React from 'react';
import { Link } from 'react-router-dom';

const ChangeLog = () => {
  const upcomingFeatures = [
    {
      icon: "🧩",
      name: "Jigsaw Solver",
      description: "Complete implementation",
      detail: "Full puzzle-solving capabilities",
      color: "purple",
      estimatedDate: "Q1 2024"
    },
    {
      icon: "📊",
      name: "Advanced Puzzle Analytics",
      description: "Detailed solving time and strategy insights",
      color: "indigo",
      estimatedDate: "Q2 2024"
    },
    {
      icon: "🔐",
      name: "User Accounts",
      description: "Save and track your puzzle-solving progress",
      color: "sky",
      estimatedDate: "Q2 2024"
    },
    {
      icon: "💾",
      name: "Saved Puzzles",
      description: "Resume puzzles across multiple sessions",
      color: "green",
      estimatedDate: "Q2 2024"
    }
  ];

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <h1 className="text-4xl font-bold mb-10 text-gray-800 border-b-2 border-gray-200 pb-4">
        Product Roadmap & Updates
      </h1>
      
      <div className="space-y-10">
        <section>
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            v0.9.0 - Beta Release
          </h2>
          <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-all duration-200">
            <h3 className="text-xl font-medium mb-4 text-gray-600">Current Features</h3>
            <ul className="space-y-3" aria-label="Current features">
              <li className="flex items-center">
                <span className="mr-3 text-green-500">✅</span>
                <span>
                  <strong>Sudoku Solver</strong> - Fully functional puzzle solving tool
                  <span className="text-sm text-gray-500 ml-2">
                    (Supports standard 9x9 Sudoku grids)
                  </span>
                </span>
              </li>
              <li className="flex items-center">
                <span className="mr-3 text-yellow-500">🚧</span>
                <div className="flex-1">
                  <div className="flex items-center">
                    <strong>Jigsaw Solver</strong> - In active development
                    <span className="text-sm text-gray-500 ml-2">
                      (Coming soon)
                    </span>
                  </div>
                  <div className="mt-2 h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div className="h-full bg-yellow-500 rounded-full w-3/4"></div>
                  </div>
                </div>
              </li>
              <li className="flex items-center">
                <span className="mr-3 text-blue-500">✨</span>
                <span>
                  Performance improvements and bug fixes
                </span>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            Upcoming Features
          </h2>
          <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-all duration-200">
            <ul className="space-y-3" aria-label="Upcoming features">
              {upcomingFeatures.map((feature, index) => (
                <li key={index} className="flex items-center justify-between group">
                  <div className="flex items-center">
                    <span className={`mr-3 text-${feature.color}-500`}>{feature.icon}</span>
                    <span>
                      <strong>{feature.name}</strong> - {feature.description}
                      <span className="text-sm text-gray-500 ml-2">
                        {feature.detail}
                      </span>
                    </span>
                  </div>
                  <span className="text-sm text-gray-400 group-hover:text-gray-600 transition-colors">
                    {feature.estimatedDate}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <div className="text-center mt-10 bg-gray-50 p-6 rounded-lg hover:bg-gray-100 transition-colors">
          <p className="text-gray-600 mb-4">
            Want to stay updated or have feature suggestions?
          </p>
          <Link 
            to="/contact" 
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-colors"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChangeLog;