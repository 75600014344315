import React from 'react';
import { Link } from 'react-router-dom';
import { Camera, Grid3X3, ArrowRight, ArrowLeft, CheckCircle, Image, Smartphone } from 'lucide-react';

const GettingStarted = () => {
  const steps = [
    {
      id: 1,
      title: "Choose Your Input Method",
      description: "There are two ways to input your Sudoku puzzle:",
      subSteps: [
        {
          icon: Camera,
          title: "OCR Scanner",
          description: "Take a photo of your puzzle using your device's camera. Works with puzzles from newspapers, magazines, or your screen.",
          tips: [
            "Use good lighting",
            "Keep the camera steady",
            "Align the grid properly"
          ]
        },
        {
          icon: Grid3X3,
          title: "Manual Input",
          description: "Use our digital grid to enter numbers manually. Perfect for when you want to be precise or don't have a photo.",
          tips: [
            "Use number keys 1-9",
            "Space or 0 for empty cells",
            "Arrow keys to navigate"
          ]
        }
      ]
    },
    {
      id: 2,
      title: "Getting Solutions",
      description: "Once your puzzle is input, you can:",
      features: [
        {
          title: "Instant Solution",
          description: "Get the complete solution immediately with all numbers filled in"
        },
        {
          title: "Step-by-Step Guide",
          description: "Watch the puzzle being solved one step at a time with explanations"
        },
        {
          title: "Hints",
          description: "Get targeted hints for specific cells when you're stuck"
        }
      ]
    },
    {
      id: 3,
      title: "Best Practices",
      tips: [
        {
          icon: Image,
          title: "For OCR Scanning",
          items: [
            "Ensure the puzzle takes up most of the frame",
            "Avoid shadows and glare",
            "Keep the grid lines visible"
          ]
        },
        {
          icon: Smartphone,
          title: "For Manual Input",
          items: [
            "Double-check your numbers",
            "Use the validation feature",
            "Save your progress regularly"
          ]
        }
      ]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      {/* Header */}
      <div className="mb-12">
        <div className="flex items-center gap-2 text-blue-600 mb-4">
          <Link to="/sudoku/docs" className="flex items-center gap-1 hover:underline">
            <ArrowLeft className="w-4 h-4" />
            Back to Docs
          </Link>
        </div>
        <h1 className="text-4xl font-bold mb-4">Getting Started</h1>
        <p className="text-xl text-gray-600">
          Learn how to use our Sudoku solver in a few simple steps.
        </p>
      </div>

      {/* Main Content */}
      <div className="space-y-12">
        {steps.map((step) => (
          <div key={step.id} className="bg-white rounded-xl p-8 border border-gray-100 shadow-sm">
            <h2 className="text-2xl font-bold mb-4">{step.title}</h2>
            <p className="text-gray-600 mb-6">{step.description}</p>

            {step.subSteps && (
              <div className="grid md:grid-cols-2 gap-6 mb-6">
                {step.subSteps.map((subStep, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-6">
                    <div className="flex items-center gap-3 mb-4">
                      <div className="p-2 bg-blue-100 rounded-lg">
                        <subStep.icon className="w-5 h-5 text-blue-600" />
                      </div>
                      <h3 className="text-lg font-semibold">{subStep.title}</h3>
                    </div>
                    <p className="text-gray-600 mb-4">{subStep.description}</p>
                    <ul className="space-y-2">
                      {subStep.tips.map((tip, tipIndex) => (
                        <li key={tipIndex} className="flex items-start gap-2 text-gray-600">
                          <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                          <span>{tip}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {step.features && (
              <div className="grid gap-4">
                {step.features.map((feature, index) => (
                  <div key={index} className="bg-blue-50 rounded-lg p-6">
                    <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                ))}
              </div>
            )}

            {step.tips && (
              <div className="grid md:grid-cols-2 gap-6">
                {step.tips.map((tip, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-6">
                    <div className="flex items-center gap-3 mb-4">
                      <tip.icon className="w-5 h-5 text-blue-600" />
                      <h3 className="text-lg font-semibold">{tip.title}</h3>
                    </div>
                    <ul className="space-y-2">
                      {tip.items.map((item, itemIndex) => (
                        <li key={itemIndex} className="flex items-start gap-2 text-gray-600">
                          <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Next Steps */}
      <div className="mt-12 bg-blue-50 rounded-xl p-8 text-center">
        <h3 className="text-xl font-semibold mb-4">Ready to Learn More?</h3>
        <p className="text-gray-600 mb-6">
          Now that you know the basics, learn about advanced Sudoku solving techniques.
        </p>
        <Link 
          to="/sudoku/docs/techniques"
          className="inline-flex items-center gap-2 bg-blue-600 text-white px-6 py-3 
            rounded-lg hover:bg-blue-700 transition-colors"
        >
          <span>Solving Techniques</span>
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  );
};

export default GettingStarted;