// src/utils/sudoku/logicSolver.js
import { canPlaceNumber, getPossibleNumbers } from './helpers';
import { findXWingPattern } from './techniques/xWing';
import { findXYWingPattern } from './techniques/xyWing';
import { findSwordfishPattern } from './techniques/swordfish';

const findSingleCandidate = (grid) => {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (grid[row][col] === 0) {
        const candidates = getPossibleNumbers(grid, row, col);
        if (candidates.length === 1) {
          return {
            technique: 'single_candidate',
            row,
            col,
            number: candidates[0],
            details: [
              `Only possible number for cell (${row + 1},${col + 1}) is ${candidates[0]}`,
              'All other numbers are blocked by existing values in the same row, column, or box'
            ],
            highlightCells: [
              { row, col, type: 'target' }
            ]
          };
        }
      }
    }
  }
  return null;
};

const findSinglePosition = (grid) => {
  // Check each box
  for (let boxRow = 0; boxRow < 9; boxRow += 3) {
    for (let boxCol = 0; boxCol < 9; boxCol += 3) {
      // Try each number 1-9
      for (let num = 1; num <= 9; num++) {
        let possibleCells = [];
        
        // Check if box already contains the number
        let hasNumber = false;
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            if (grid[boxRow + i][boxCol + j] === num) {
              hasNumber = true;
              break;
            }
          }
        }
        
        if (!hasNumber) {
          // Find all possible positions for this number
          for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
              const row = boxRow + i;
              const col = boxCol + j;
              if (grid[row][col] === 0 && canPlaceNumber(grid, row, col, num)) {
                possibleCells.push({ row, col });
              }
            }
          }
          
          if (possibleCells.length === 1) {
            return {
              technique: 'single_position',
              row: possibleCells[0].row,
              col: possibleCells[0].col,
              number: num,
              details: [
                `${num} can only go in cell (${possibleCells[0].row + 1},${possibleCells[0].col + 1}) in this box`,
                'All other positions are blocked by existing numbers'
              ],
              highlightCells: [
                { ...possibleCells[0], type: 'target' }
              ]
            };
          }
        }
      }
    }
  }
  return null;
};

export const findNextMove = (grid) => {
  if (!grid || !Array.isArray(grid)) {
    console.error('Invalid grid provided to findNextMove:', grid);
    return null;
  }

  try {
    // Try techniques in order of increasing difficulty
    console.log('Checking for single candidate...');
    const singleCandidate = findSingleCandidate(grid);
    if (singleCandidate) return singleCandidate;

    console.log('Checking for single position...');
    const singlePosition = findSinglePosition(grid);
    if (singlePosition) return singlePosition;
    
    // Try X-Wing for each number
    console.log('Checking for X-Wing patterns...');
    for (let num = 1; num <= 9; num++) {
      const xWing = findXWingPattern(grid, num);
      if (xWing) return xWing;
    }
    
    // Check for XY-Wing
    console.log('Checking for XY-Wing pattern...');
    const xyWing = findXYWingPattern(grid);
    if (xyWing) return xyWing;

    // Check for Swordfish
    console.log('Checking for Swordfish patterns...');
    for (let num = 1; num <= 9; num++) {
      const swordfish = findSwordfishPattern(grid, num);
      if (swordfish) return swordfish;
    }
    
    console.log('No moves found');
    return null;
  } catch (error) {
    console.error('Error in findNextMove:', error);
    return null;
  }
};

export const getFullAnalysis = (grid) => {
  console.log('Starting full analysis of grid:', grid);
  const moves = [];
  let currentMove;
  
  try {
    while ((currentMove = findNextMove(grid))) {
      console.log('Found move:', currentMove);
      if (!currentMove || !currentMove.row || !currentMove.col || !currentMove.number) {
        console.log('Invalid move structure:', currentMove);
        break;
      }

      moves.push(currentMove);
      // Create a copy of the grid and apply the move
      const newGrid = grid.map(row => [...row]);
      console.log('Applying move:', {
        row: currentMove.row,
        col: currentMove.col,
        number: currentMove.number
      });
      newGrid[currentMove.row][currentMove.col] = currentMove.number;
      grid = newGrid;
    }
    
    console.log('Analysis complete, found moves:', moves);
    return moves;
  } catch (error) {
    console.error('Error in getFullAnalysis:', error);
    console.log('Current state:', { grid, moves, currentMove });
    return []; // Return empty array instead of failing
  }
};