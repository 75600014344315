// src/pages/common/TipsPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../components/ui/card';
import { Lightbulb, Target, Camera, AlertCircle, ArrowLeft, ArrowRight } from 'lucide-react';

const TipsPage = () => {
  const tips = [
    {
      icon: Camera,
      title: "Taking Better Puzzle Photos",
      description: "Ensure good lighting and a straight angle when photographing your puzzle. Avoid shadows and glare for best recognition results.",
      tips: [
        "Use natural lighting when possible",
        "Keep the camera parallel to the puzzle",
        "Ensure the entire grid is visible",
        "Avoid casting shadows on the puzzle"
      ]
    },
    {
      icon: Target,
      title: "Solving Strategies",
      description: "Learn these basic strategies to improve your Sudoku solving skills:",
      tips: [
        "Start with the most filled rows, columns, or boxes",
        "Look for single candidates in empty cells",
        "Use the scanning technique to find hidden singles",
        "Learn to recognize patterns like X-Wing"
      ]
    },
    {
      icon: AlertCircle,
      title: "Common Mistakes to Avoid",
      description: "Watch out for these common pitfalls when solving Sudoku puzzles:",
      tips: [
        "Don't guess numbers - use logic",
        "Regularly validate your progress",
        "Don't forget to check all three rules (row, column, box)",
        "Use pencil marks for candidates"
      ]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="mb-12">
        <div className="flex items-center gap-2 text-blue-600 mb-4">
          <Link to="/sudoku/docs" className="flex items-center gap-1 hover:underline">
            <ArrowLeft className="w-4 h-4" />
            Back to Docs
          </Link>
        </div>
        <h1 className="text-4xl font-bold mb-4">Tips & Best Practices</h1>
        <p className="text-xl text-gray-600">
          Maximize your puzzle solving efficiency with these helpful tips.
        </p>
      </div>

      <div className="space-y-6">
        {tips.map((tip, index) => (
          <Card key={index} className="p-6">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <tip.icon className="h-6 w-6 text-blue-500" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {tip.title}
                </h3>
                <p className="text-gray-600 mb-4">{tip.description}</p>
                <ul className="space-y-2">
                  {tip.tips.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="flex-shrink-0 text-blue-500 mr-2">•</span>
                      <span className="text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <div className="mt-12 flex justify-between items-center">
        <Link 
          to="/sudoku/docs/techniques"
          className="flex items-center gap-2 text-blue-600 hover:underline"
        >
          <ArrowLeft className="w-5 h-5" />
          Solving Techniques
        </Link>
        <Link 
          to="/sudoku/docs/faqs"
          className="flex items-center gap-2 text-blue-600 hover:underline"
        >
          FAQs
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  );
};

export default TipsPage;