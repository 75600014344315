// src/utils/sudoku/helpers.js
export const canPlaceNumber = (grid, row, col, num) => {
    // Check row
    for (let x = 0; x < 9; x++) {
      if (grid[row][x] === num) return false;
    }
    
    // Check column
    for (let y = 0; y < 9; y++) {
      if (grid[y][col] === num) return false;
    }
    
    // Check 3x3 box
    const boxStartRow = Math.floor(row / 3) * 3;
    const boxStartCol = Math.floor(col / 3) * 3;
    for (let y = 0; y < 3; y++) {
      for (let x = 0; x < 3; x++) {
        if (grid[boxStartRow + y][boxStartCol + x] === num) {
          return false;
        }
      }
    }
    
    return true;
  };
  
  export const getPossibleNumbers = (grid, row, col) => {
    if (grid[row][col] !== 0) return [];
    
    const possible = [];
    for (let num = 1; num <= 9; num++) {
      if (canPlaceNumber(grid, row, col, num)) {
        possible.push(num);
      }
    }
    return possible;
  };

  export const getRelatedNumbers = (grid, row, col) => {
    const related = [];
    
    // Add numbers in same row
    for (let x = 0; x < 9; x++) {
      if (grid[row][x] !== 0) {
        related.push(grid[row][x]);
      }
    }

    // Add numbers in same column 
    for (let y = 0; y < 9; y++) {
      if (grid[y][col] !== 0) {
        related.push(grid[y][col]);
      }
    }

    // Add numbers in same 3x3 box
    const boxRow = Math.floor(row / 3) * 3;
    const boxCol = Math.floor(col / 3) * 3;
    for (let y = 0; y < 3; y++) {
      for (let x = 0; x < 3; x++) {
        if (grid[boxRow + y][boxCol + x] !== 0) {
          related.push(grid[boxRow + y][boxCol + x]);
        }
      }
    }

    // Remove duplicates
    return [...new Set(related)];
  };
  
  export const arraysEqual = (a, b) => 
    Array.isArray(a) && 
    Array.isArray(b) && 
    a.length === b.length && 
    a.every((val, index) => val === b[index]);
