import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Github, Home, Info, PuzzleIcon, Menu, X, Grid, BookOpen, } from 'lucide-react';
import DevBanner from '../DevBanner';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const location = useLocation();

  // Add scroll listener
  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle smooth scroll to sections
  const handleNavClick = (e, href) => {
    if (href === location.pathname) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  const navigation = [
    { 
      name: 'Home', 
      href: '/', 
      icon: Home,
      description: 'Dashboard with all puzzle solvers'
    },
    { 
      name: 'Sudoku Solver', 
      href: '/sudoku-solver', 
      icon: Grid,
      description: 'Solve Sudoku puzzles with smart hints'
    },
    { 
      name: 'Jigsaw Solver', 
      href: '/jigsaw-solver', 
      icon: PuzzleIcon,
      description: 'Solve jigsaw puzzles with AI assistance'
    },
    {
      name: 'Sudoku Generator',
      href: '/sudoku-generator',
      icon: Grid,
      description: 'Generate new Sudoku puzzles'
    },
    {
      name: 'Blog',
      href: '/blog',
      icon: BookOpen,
      description: 'Read puzzle solving tips and tutorials'
    },
    { 
      name: 'About', 
      href: '/about', 
      icon: Info,
      description: 'Learn more about our puzzle solvers'
    },
  ];

  return (
    <header className={`bg-white fixed w-full top-0 z-50 transition-all duration-200
      ${isScrolled ? 'shadow-md' : 'border-b border-gray-100'}`}>
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between px-4 py-3">
          {/* Logo and Brand */}
          <Link 
            to="/" 
            className="flex items-center space-x-3 group"
            onClick={(e) => handleNavClick(e, '/')}
            aria-label="Solve A Puzzle Home"
          >
            <div className="p-2 rounded-xl bg-blue-50 group-hover:bg-blue-100 transition-colors">
              <PuzzleIcon className="h-6 w-6 text-blue-600" />
            </div>
            <span className="text-xl font-bold bg-gradient-to-r from-blue-600 to-blue-500 bg-clip-text text-transparent">
              Solve A Puzzle
            </span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-2">
            {navigation.map(({ name, href, icon: Icon, description }) => (
              <Link
                key={name}
                to={href}
                onClick={(e) => handleNavClick(e, href)}
                className={`px-4 py-2 rounded-lg flex items-center space-x-2 transition-all duration-200 ${
                  location.pathname === href
                    ? 'bg-blue-50 text-blue-600 scale-105'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 hover:scale-105'
                }`}
                aria-label={description}
              >
                <Icon className="h-4 w-4" />
                <span className="font-medium">{name}</span>
              </Link>
            ))}
            <a
              href="https://github.com/yourusername/solve-a-puzzle"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-lg text-gray-500 hover:bg-gray-50 hover:text-gray-900 
                transition-all duration-200 hover:scale-105"
              aria-label="View source code on GitHub"
            >
              <Github className="h-5 w-5" />
            </a>
          </nav>

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-lg text-gray-400 hover:text-gray-500 hover:bg-gray-50 transition-colors"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <nav className="lg:hidden border-t border-gray-100 py-2 px-4">
            {navigation.map(({ name, href, icon: Icon, description }) => (
              <Link
                key={name}
                to={href}
                onClick={() => setIsMenuOpen(false)}
                className={`flex items-center space-x-2 px-4 py-3 rounded-lg mb-1 transition-colors ${
                  location.pathname === href
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                }`}
                aria-label={description}
              >
                <Icon className="h-5 w-5" />
                <span className="font-medium">{name}</span>
              </Link>
            ))}
            <a
              href="https://github.com/yourusername/puzzle-solver"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-4 py-3 rounded-lg text-gray-600 hover:bg-gray-50 hover:text-gray-900"
            >
              <Github className="h-5 h-5" />
              <span className="font-medium">GitHub</span>
            </a>
          </nav>
        )}
      </div>
    </header>
  );
};

const Footer = () => {
  const version = 'v0.9.0';
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const footerLinks = {
    quickLinks: [
      { name: 'Home', href: '/' },
      { name: 'Sudoku Solver', href: '/sudoku-solver' },
      { name: 'Jigsaw Solver', href: '/jigsaw-solver' },
      { name: 'About', href: '/about' },
      { name: 'Contact', href: '/contact' },
    ],
    resources: [
      { name: 'Documentation', href: '/sudoku/docs' },
      { name: 'Tips & Tricks', href: '/sudoku/docs/tips' },
      { name: 'Changelog', href: '/changelog' },
      { name: 'FAQs', href: '/sudoku/docs/faqs' },
      { name: 'Privacy Policy', href: '/privacy-policy' },
    ],
  };

  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Quick Links
            </h3>
            <div className="mt-4 space-y-4">
              {footerLinks.quickLinks.map(({ name, href }) => (
                <Link 
                  key={name}
                  to={href} 
                  className="text-base text-gray-500 hover:text-gray-900 block"
                  onClick={scrollToTop}
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Resources
            </h3>
            <div className="mt-4 space-y-4">
              {footerLinks.resources.map(({ name, href }) => (
                <Link
                  key={name}
                  to={href}
                  className="text-base text-gray-500 hover:text-gray-900 block"
                  onClick={scrollToTop}
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Connect
            </h3>
            <div className="mt-4 space-y-4">
              <a
                href="https://github.com/yourusername/puzzle-solver"
                className="text-base text-gray-500 hover:text-gray-900 flex items-center space-x-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github className="h-5 w-5" />
                <span>GitHub Repository</span>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 text-center">
          <p className="text-base text-gray-400">
            Solve A Puzzle: AI-Powered Sudoku & Jigsaw Puzzle Assistant &copy; {new Date().getFullYear()}. All rights reserved.
            <span className="ml-2 text-sm">
              <Link 
                to="/changelog" 
                className="text-blue-500 hover:text-blue-600"
                onClick={scrollToTop}
              >
                {version}
              </Link>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

const Layout = ({ children }) => {
  const location = useLocation();
  const canonicalUrl = `https://solveapuzzle.com${location.pathname}`;

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      {/* Add padding-top to account for fixed header */}
      <div className="pt-16">
        <DevBanner />
        <main className="flex-grow bg-gray-50 pt-8">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
