import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AlertTriangle, X } from 'lucide-react';

const DevBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const bannerDismissed = localStorage.getItem('devBannerDismissed');
    if (bannerDismissed) {
      setIsVisible(false);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem('devBannerDismissed', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div 
      className="bg-blue-700 py-3"
      role="alert"
      aria-live="polite"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <AlertTriangle className="h-4 w-4 text-white" />
            <p className="text-base text-white">
              <span className="font-semibold">Beta Version:</span>
              {' '}Currently only the Sudoku Solver is fully functional.
              {' '}
              <Link 
                to="/changelog" 
                className="text-white hover:text-blue-100 underline decoration-white/80 underline-offset-2"
              >
                View Updates
              </Link>
            </p>
          </div>
          <button 
            onClick={handleDismiss}
            className="ml-4 p-1.5 rounded-lg bg-white/10 hover:bg-white/20 transition-colors"
            aria-label="Dismiss beta notice"
          >
            <X className="h-4 w-4 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevBanner;