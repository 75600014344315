// src/pages/common/FAQPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../components/ui/card';
import { ArrowLeft, ArrowRight, HelpCircle, Grid3X3 } from 'lucide-react';

const FAQPage = () => {
  const faqs = [
    {
      question: "How does the Sudoku solver work?",
      answer: "Our Sudoku solver uses advanced algorithms and techniques like single candidate, hidden singles, and X-wing patterns to solve puzzles. You can either input numbers manually or upload a photo of your puzzle."
    },
    {
      question: "Can I upload a photo of my puzzle?",
      answer: "Yes! You can upload a photo of your Sudoku puzzle, and our OCR technology will recognize the numbers automatically. Make sure the image is clear and well-lit for best results."
    },
    {
      question: "What should I do if the solver gets stuck?",
      answer: "If the solver seems stuck, first check if all numbers are entered correctly. You can use the 'Check Progress' button to validate your current solution. If needed, you can also use the 'Get Hint' feature for assistance."
    },
    {
      question: "Is there a limit to how many puzzles I can solve?",
      answer: "No, there's no limit! You can solve as many puzzles as you want. The solver is free to use and available 24/7."
    },
    {
      question: "How accurate is the OCR recognition?",
      answer: "Our OCR technology is highly accurate when provided with clear images. For best results, ensure good lighting, minimal glare, and that the puzzle takes up most of the frame. You can always manually correct any misread numbers if needed."
    },
    {
      question: "Can I get explanations for each solving step?",
      answer: "Yes! Our solver provides detailed explanations for each step, helping you understand the logic behind the solution. This is especially useful if you want to improve your own solving skills."
    },
    {
      question: "What solving techniques does the solver use?",
      answer: "The solver employs a wide range of techniques from basic to advanced, including Single Candidates, Hidden Singles, Pairs, X-Wing patterns, and more. You can learn about these techniques in our documentation."
    },
    {
      question: "Can I save my progress?",
      answer: (
        <>
          This feature is coming soon! Progress saving will be added in an upcoming update - {" "}
          <Link 
            to="/changelog" 
            className="text-blue-600 hover:text-blue-700 hover:underline"
          >
            check our changelog
          </Link>
          {" "}for details. For now, we recommend completing puzzles in one session.
        </>
      )
    },
    {
      question: "Does the solver work on mobile devices?",
      answer: "Yes, our solver is fully responsive and works on all devices. The OCR scanner can use your mobile device's camera for easy puzzle input."
    },
    {
      question: "What if I make a mistake while entering numbers?",
      answer: "Don't worry! You can use the 'Undo' button to reverse your last action, or the 'Clear' button to start over. The solver also validates your input to prevent invalid configurations."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      {/* Header with Back Link */}
      <div className="mb-12">
        <div className="flex items-center gap-2 text-blue-600 mb-4">
          <Link to="/sudoku/docs" className="flex items-center gap-1 hover:underline">
            <ArrowLeft className="w-4 h-4" />
            Back to Docs
          </Link>
        </div>
        <h1 className="text-4xl font-bold mb-4">Frequently Asked Questions</h1>
        <p className="text-xl text-gray-600">
          Find answers to common questions about our Sudoku solver.
        </p>
      </div>

      {/* FAQs Grid */}
      <div className="space-y-6">
        {faqs.map((faq, index) => (
          <Card key={index} className="p-6 hover:shadow-md transition-shadow duration-300">
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <HelpCircle className="w-6 h-6 text-blue-500" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {faq.question}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {faq.answer}
                </p>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Sudoku Solver CTA */}
      <div className="mt-12 bg-gradient-to-br from-blue-600 to-blue-700 rounded-xl p-8 text-center text-white">
        <h3 className="text-2xl font-bold mb-4">Ready to Solve Your Puzzle?</h3>
        <p className="text-blue-100 mb-6 max-w-2xl mx-auto">
          Try our AI-powered Sudoku solver now - it's free, fast, and easy to use!
        </p>
        <Link 
          to="/sudoku-solver"
          className="inline-flex items-center gap-2 bg-white text-blue-600 px-8 py-4 
            rounded-lg hover:bg-blue-50 transition-colors font-semibold text-lg
            shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all"
        >
          <Grid3X3 className="w-6 h-6" />
          <span>Start Solving Now</span>
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>

      {/* Contact Section */}
      <div className="mt-12 bg-blue-50 rounded-xl p-8 text-center">
        <h3 className="text-xl font-semibold mb-4">Still Have Questions?</h3>
        <p className="text-gray-600 mb-6">
          Can't find what you're looking for? We're here to help.
        </p>
        <Link 
          to="/contact"
          className="inline-flex items-center gap-2 bg-blue-600 text-white px-6 py-3 
            rounded-lg hover:bg-blue-700 transition-colors"
        >
          <span>Contact Support</span>
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>

      {/* Navigation Footer */}
      <div className="mt-12 flex justify-between items-center">
        <Link 
          to="/sudoku/docs/tips"
          className="flex items-center gap-2 text-blue-600 hover:underline"
        >
          <ArrowLeft className="w-5 h-5" />
          Tips & Best Practices
        </Link>
      </div>
    </div>
  );
};

export default FAQPage;