// src/utils/sudoku/techniques/swordfish.js
import { canPlaceNumber } from '../helpers';

// First, let's add a helper to get pencil marks for a cell
const getPencilMarks = (grid, row, col) => {
  if (grid[row][col] !== 0) return [];
  const marks = [];
  for (let num = 1; num <= 9; num++) {
    if (canPlaceNumber(grid, row, col, num)) {
      marks.push(num);
    }
  }
  return marks;
};

// Modified candidate finder to use pencil marks
const getCandidatePositions = (grid, number, isRow = true) => {
  const candidates = [];
  
  for (let i = 0; i < 9; i++) {
    const positions = [];
    for (let j = 0; j < 9; j++) {
      const row = isRow ? i : j;
      const col = isRow ? j : i;
      
      // A position is a candidate if:
      // 1. The cell is empty (0) AND we can place our number there
      // OR
      // 2. The cell already contains our number
      if ((grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) || 
          grid[row][col] === number) {
        positions.push(isRow ? col : row);
      }
    }
    
    // For Swordfish, we want lines with 2-3 possible positions
    if (positions.length >= 2 && positions.length <= 3) {
      candidates.push({ line: i, positions });
    }
  }
  
  return candidates;
};

// Modified pattern finder
export const findSwordfishPattern = (grid, number) => {
  // Check rows first
  const rowCandidates = getCandidatePositions(grid, number, true);
  
  if (rowCandidates.length >= 3) {
    // Try all combinations of 3 rows
    for (let i = 0; i < rowCandidates.length - 2; i++) {
      for (let j = i + 1; j < rowCandidates.length - 1; j++) {
        for (let k = j + 1; k < rowCandidates.length; k++) {
          // Get common column positions
          const cols = new Set([
            ...rowCandidates[i].positions,
            ...rowCandidates[j].positions,
            ...rowCandidates[k].positions
          ]);
          
          // Swordfish needs exactly 3 columns
          if (cols.size === 3) {
            const rows = [rowCandidates[i].line, rowCandidates[j].line, rowCandidates[k].line];
            const colsArray = Array.from(cols).sort((a, b) => a - b);
            
            // Build pattern cells
            const patternCells = [];
            for (const row of rows) {
              for (const col of colsArray) {
                if ((grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) || 
                    grid[row][col] === number) {
                  patternCells.push({ row, col });
                }
              }
            }
            
            // A valid Swordfish needs at least 6 cells (2 per line)
            if (patternCells.length >= 6) {
              // Find eliminations - cells that:
              // 1. Share a column with the pattern
              // 2. Are not in the pattern rows
              // 3. Can currently hold our number
              const eliminations = [];
              for (let r = 0; r < 9; r++) {
                if (!rows.includes(r)) {
                  for (const c of colsArray) {
                    if (grid[r][c] === 0 && canPlaceNumber(grid, r, c, number)) {
                      eliminations.push({ row: r, col: c });
                    }
                  }
                }
              }
              
              if (eliminations.length > 0) {
                return {
                  technique: 'swordfish',
                  number,
                  positions: patternCells,
                  eliminations,
                  explanation: `Swordfish pattern found for ${number} in rows ${rows.map(r => r + 1).join(', ')} at columns ${colsArray.map(c => c + 1).join(', ')}`
                };
              }
            }
          }
        }
      }
    }
  }
  
  // Check columns with the same logic
  const colCandidates = getCandidatePositions(grid, number, false);
  
  if (colCandidates.length >= 3) {
    for (let i = 0; i < colCandidates.length - 2; i++) {
      for (let j = i + 1; j < colCandidates.length - 1; j++) {
        for (let k = j + 1; k < colCandidates.length; k++) {
          const rows = new Set([
            ...colCandidates[i].positions,
            ...colCandidates[j].positions,
            ...colCandidates[k].positions
          ]);
          
          if (rows.size === 3) {
            const cols = [colCandidates[i].line, colCandidates[j].line, colCandidates[k].line];
            const rowsArray = Array.from(rows).sort((a, b) => a - b);
            
            const patternCells = [];
            for (const col of cols) {
              for (const row of rowsArray) {
                if ((grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) || 
                    grid[row][col] === number) {
                  patternCells.push({ row, col });
                }
              }
            }
            
            if (patternCells.length >= 6) {
              const eliminations = [];
              for (const r of rowsArray) {
                for (let c = 0; c < 9; c++) {
                  if (!cols.includes(c) && grid[r][c] === 0 && canPlaceNumber(grid, r, c, number)) {
                    eliminations.push({ row: r, col: c });
                  }
                }
              }
              
              if (eliminations.length > 0) {
                return {
                  technique: 'swordfish',
                  number,
                  positions: patternCells,
                  eliminations,
                  explanation: `Swordfish pattern found for ${number} in columns ${cols.map(c => c + 1).join(', ')} at rows ${rowsArray.map(r => r + 1).join(', ')}`
                };
              }
            }
          }
        }
      }
    }
  }
  
  return null;
};