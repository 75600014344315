// src/data/blog/posts.js
const advancedSudokuTechniques = require('./advancedSudokuTechniques');
const approachLargePuzzles = require('./HowToApproachLargePuzzles');

const posts = [
  advancedSudokuTechniques,
  approachLargePuzzles,
  // Add more posts here...
];

// Helper function to get post by slug
const getPostBySlug = (slug) => {
  return posts.find(post => post.slug === slug);
};

// Helper function to get recent posts 
const getRecentPosts = (count = 5, excludeSlug = null) => {
  return posts
    .filter(post => post.slug !== excludeSlug)
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, count);
};

// Helper function to get posts by category
const getPostsByCategory = (category) => {
  return posts.filter(post => 
    post.categories.includes(category)
  );
};

module.exports = {
  posts,
  getPostBySlug,
  getRecentPosts,
  getPostsByCategory
};
