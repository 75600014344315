// src/pages/jigsaw/JigsawSolver.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PuzzleInterface from '../../components/jigsaw/PuzzleInterface';
import PieceUploader from '../../components/jigsaw/PieceUploader';
import ImageProcessor from '../../components/common/ImageProcessor';
import SolverStatus from '../../components/jigsaw/SolverStatus';
import { PlayCircle, RotateCcw, Save, Share2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';
import { usePuzzleSolver } from '../../hooks/jigsaw/usePuzzleSolver';

const PuzzleSolver = () => {
  const navigate = useNavigate();
  const {
    imageData,
    processedData,
    error,
    isComplete,
    showInterface,
    highlightedGap,
    highlightedPieces,
    solverHint,
    solverStats,
    handleImagesUpload,
    handleProcessingComplete,
    handleProcessingError,
    handleReset,
    handleSaveProgress,
    handleSolve
  } = usePuzzleSolver();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header Controls */}
      <div className="bg-white rounded-lg shadow-md mb-8 p-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold">Puzzle Solver</h2>
          <div className="flex space-x-4">
            <button
              onClick={handleSolve}
              disabled={!processedData}
              className="flex items-center px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50"
            >
              <PlayCircle className="w-5 h-5 mr-2" />
              Start Solving
            </button>
            
            <button
              onClick={handleReset}
              className="flex items-center px-4 py-2 rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-600"
            >
              <RotateCcw className="w-5 h-5 mr-2" />
              Reset
            </button>

            {processedData && (
              <>
                <button
                  onClick={handleSaveProgress}
                  className="flex items-center px-4 py-2 rounded-lg bg-green-500 text-white hover:bg-green-600"
                >
                  <Save className="w-5 h-5 mr-2" />
                  Save Progress
                </button>

                <button
                  className="flex items-center px-4 py-2 rounded-lg bg-purple-500 text-white hover:bg-purple-600"
                >
                  <Share2 className="w-5 h-5 mr-2" />
                  Share
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Upload/Processing View */}
      <AnimatePresence>
        {!showInterface && (
          <motion.div
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <PieceUploader onImagesUpload={handleImagesUpload} />
            {imageData && (
              <ImageProcessor 
                imageData={imageData} 
                onProcessingComplete={handleProcessingComplete}
                onProcessingError={handleProcessingError}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Solver Interface */}
      <AnimatePresence>
        {showInterface && processedData && (
          <>
            <SolverStatus 
              solverHint={solverHint}
              solverStats={solverStats}
            />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <PuzzleInterface 
                processedData={processedData}
                highlightedGap={highlightedGap}
                highlightedPieces={highlightedPieces}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Error Display */}
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
          {error}
        </div>
      )}

      {/* Processing Complete Animation */}
      {isComplete && (
        <motion.div
          className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="text-center">
            <motion.div
              className="w-16 h-16 mx-auto mb-4 text-green-500"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            >
              <CheckCircle2 className="w-full h-full" />
            </motion.div>
            <h3 className="text-xl font-bold text-gray-900 mb-2">Processing Complete!</h3>
            <p className="text-gray-600">Preparing puzzle interface...</p>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default PuzzleSolver;