// src/utils/sudoku/techniques/xWing.js
import { canPlaceNumber, arraysEqual } from '../helpers';

const getCandidatePositions = (grid, number, isRow = true) => {
  const candidates = [];
  
  for (let i = 0; i < 9; i++) {
    const positions = [];
    let hasNumber = false;
    
    // First check if line already contains the number
    for (let j = 0; j < 9; j++) {
      const row = isRow ? i : j;
      const col = isRow ? j : i;
      if (grid[row][col] === number) {
        hasNumber = true;
        break;
      }
    }
    
    // If line doesn't contain the number, find possible positions
    if (!hasNumber) {
      for (let j = 0; j < 9; j++) {
        const row = isRow ? i : j;
        const col = isRow ? j : i;
        if (grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) {
          positions.push(isRow ? col : row);
        }
      }
      
      if (positions.length >= 2) {
        candidates.push({ line: i, positions });
      }
    }
  }
  
  return candidates;
};

const hasXWingPattern = (candidates, grid, number, isRowBased) => {
  // Look for pairs of lines with matching positions
  for (let i = 0; i < candidates.length - 1; i++) {
    for (let j = i + 1; j < candidates.length; j++) {
      // Find matching positions
      let matchingPositions = [];
      for (const pos1 of candidates[i].positions) {
        for (const pos2 of candidates[j].positions) {
          if (pos1 === pos2) {
            matchingPositions.push(pos1);
          }
        }
      }
      
      // Check if we found exactly two matching positions
      if (matchingPositions.length === 2) {
        const line1 = candidates[i].line;
        const line2 = candidates[j].line;
        const pos1 = matchingPositions[0];
        const pos2 = matchingPositions[1];
        
        // Verify that all four corners can contain the number
        if (isRowBased) {
          if (!canPlaceNumber(grid, line1, pos1, number) ||
              !canPlaceNumber(grid, line1, pos2, number) ||
              !canPlaceNumber(grid, line2, pos1, number) ||
              !canPlaceNumber(grid, line2, pos2, number)) {
            continue;
          }
        } else {
          if (!canPlaceNumber(grid, pos1, line1, number) ||
              !canPlaceNumber(grid, pos2, line1, number) ||
              !canPlaceNumber(grid, pos1, line2, number) ||
              !canPlaceNumber(grid, pos2, line2, number)) {
            continue;
          }
        }
        
        // Look for cells that can be eliminated
        const eliminations = findEliminations(grid, number, [line1, line2], matchingPositions, isRowBased);
        if (eliminations.length > 0) {
          return {
            lines: [line1, line2],
            positions: matchingPositions,
            eliminations
          };
        }
      }
    }
  }
  return null;
};

const findEliminations = (grid, number, lines, positions, isRowBased) => {
  const eliminations = [];
  
  if (isRowBased) {
    // Check each row that's not part of the pattern
    for (let row = 0; row < 9; row++) {
      if (!lines.includes(row)) {
        for (const col of positions) {
          if (grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) {
            eliminations.push({ row, col });
          }
        }
      }
    }
  } else {
    // Check each column that's not part of the pattern
    for (let col = 0; col < 9; col++) {
      if (!lines.includes(col)) {
        for (const row of positions) {
          if (grid[row][col] === 0 && canPlaceNumber(grid, row, col, number)) {
            eliminations.push({ row, col });
          }
        }
      }
    }
  }
  
  return eliminations;
};

export const findXWingPattern = (grid, number) => {
  // Check for row-based X-Wing pattern
  const rowCandidates = getCandidatePositions(grid, number, true);
  const rowPattern = hasXWingPattern(rowCandidates, grid, number, true);
  
  if (rowPattern) {
    return {
      technique: 'x_wing',
      number,
      positions: [
        { row: rowPattern.lines[0], col: rowPattern.positions[0] },
        { row: rowPattern.lines[0], col: rowPattern.positions[1] },
        { row: rowPattern.lines[1], col: rowPattern.positions[0] },
        { row: rowPattern.lines[1], col: rowPattern.positions[1] }
      ],
      eliminations: rowPattern.eliminations,
      explanation: `X-Wing pattern found for ${number} in rows ${rowPattern.lines[0] + 1} and ${rowPattern.lines[1] + 1} at columns ${rowPattern.positions[0] + 1} and ${rowPattern.positions[1] + 1}`,
      highlightCells: [
        { row: rowPattern.lines[0], col: rowPattern.positions[0], type: 'pattern' },
        { row: rowPattern.lines[0], col: rowPattern.positions[1], type: 'pattern' },
        { row: rowPattern.lines[1], col: rowPattern.positions[0], type: 'pattern' },
        { row: rowPattern.lines[1], col: rowPattern.positions[1], type: 'pattern' },
        ...rowPattern.eliminations.map(cell => ({ ...cell, type: 'elimination' }))
      ]
    };
  }
  
  // Check for column-based X-Wing pattern
  const colCandidates = getCandidatePositions(grid, number, false);
  const colPattern = hasXWingPattern(colCandidates, grid, number, false);
  
  if (colPattern) {
    return {
      technique: 'x_wing',
      number,
      positions: [
        { row: colPattern.positions[0], col: colPattern.lines[0] },
        { row: colPattern.positions[0], col: colPattern.lines[1] },
        { row: colPattern.positions[1], col: colPattern.lines[0] },
        { row: colPattern.positions[1], col: colPattern.lines[1] }
      ],
      eliminations: colPattern.eliminations,
      explanation: `X-Wing pattern found for ${number} in columns ${colPattern.lines[0] + 1} and ${colPattern.lines[1] + 1} at rows ${colPattern.positions[0] + 1} and ${colPattern.positions[1] + 1}`,
      highlightCells: [
        { row: colPattern.positions[0], col: colPattern.lines[0], type: 'pattern' },
        { row: colPattern.positions[0], col: colPattern.lines[1], type: 'pattern' },
        { row: colPattern.positions[1], col: colPattern.lines[0], type: 'pattern' },
        { row: colPattern.positions[1], col: colPattern.lines[1], type: 'pattern' },
        ...colPattern.eliminations.map(cell => ({ ...cell, type: 'elimination' }))
      ]
    };
  }
  
  return null;
};