import React from 'react';
import { Loader2 } from 'lucide-react';
import { Card } from '../../components/ui/card';

export const GenerationProgress = ({ progress }) => {
  const getStatusMessage = (progress) => {
    if (progress < 30) return "Initializing puzzle grid...";
    if (progress < 60) return "Applying solving techniques...";
    if (progress < 90) return "Validating difficulty level...";
    return "Finalizing generation...";
  };

  return (
    <Card className="p-4 space-y-3">
      {/* Progress header */}
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2 text-blue-600">
          <Loader2 className="w-4 h-4 animate-spin" />
          <span className="font-medium">Generating Puzzle</span>
        </div>
        <span className="font-medium">{Math.round(progress)}%</span>
      </div>

      {/* Progress bar */}
      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
        <div 
          className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>

      {/* Status message */}
      <div className="text-sm text-gray-600">
        {getStatusMessage(progress)}
      </div>
    </Card>
  );
};

export default GenerationProgress;