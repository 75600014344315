// client/src/components/common/GoogleAnalytics.js

import { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-287GJQ0QG8';
    script1.async = true;
    document.head.appendChild(script1);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-287GJQ0QG8');

    // Cleanup
    return () => {
      document.head.removeChild(script1);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;