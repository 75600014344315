// src/components/sudoku/SudokuInterface.js
import React, { useCallback, useState, useEffect } from 'react';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { motion } from 'framer-motion';
import { 
  Undo, 
  Redo, 
  RotateCcw, 
  Upload, 
  Camera, 
  Lightbulb,
  CheckCircle,
  AlertCircle,
  Grid3X3,
  BookOpen,
  Sparkles
} from 'lucide-react';
import GridInput from './GridInput';
import NumberPad from './NumberPad';
import HintDisplay from './HintDisplay';
import GameStats from './GameStats';
import SudokuCamera from './SudokuCamera';
import ProcessingOverlay from './ProcessingOverlay';
import { ConfidenceIndicator } from './ConfidenceIndicator';
import { SuccessOverlay } from './SuccessOverlay';
import { ErrorNotification } from './ErrorNotification';
import { generatePuzzle } from '../../utils/sudoku/puzzleGenerator';
import { useSudokuSolver } from '../../hooks/sudoku/useSudokuSolver';
import { sudokuApi } from '../../services/sudoku/sudokuApi';

const SudokuInterface = () => {
  // Local state
  const [validationMessage, setValidationMessage] = useState(null);
  const [difficulty, setDifficulty] = useState('medium');
  const [userEnteredNumbers, setUserEnteredNumbers] = useState(new Set());
  const [hintDifficulty, setHintDifficulty] = useState('easy');
  const [availableHints, setAvailableHints] = useState(3);
  const [initialPuzzle, setInitialPuzzle] = useState(null);
  const [solution, setSolution] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingState, setProcessingState] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [lastFile, setLastFile] = useState(null);

  // Get state and methods from the solver hook
  const {
    grid,
    selectedCell,
    highlightedCells,
    currentHint,
    validationErrors,
    updateCell,
    selectCell,
    getHint,
    applyHint,
    resetGrid,
    validateGrid: validateGridState,
    setFullGrid,
    setCurrentHint,
    setHighlightedCells,
    setValidationErrors,
    canUndo,
    canRedo,
    handleUndo,
    handleRedo
  } = useSudokuSolver();

  useEffect(() => {
    sudokuApi.setProcessingStateCallback(setProcessingState);
  }, []);

  const handleCapture = async (file) => {
    try {
      setIsLoading(true);
      setError(null);
      setLastFile(file);
      
      setProcessingState('UPLOADING');
      
      const formData = new FormData();
      formData.append('file', file);

      await new Promise(resolve => setTimeout(resolve, 1000));
      setProcessingState('ANALYZING');
      
      const result = await sudokuApi.processImage(formData);
      
      if (result.success && result.grid) {
        setProcessingState('COMPLETING');
        await new Promise(resolve => setTimeout(resolve, 500));
        handleGridDetected(result.grid);
        setProcessingState(null);
      } else if (result.error) {
        throw new Error(result.error);
      } else if (result.validation_errors) {
        console.warn('Debug image validation errors:', result.validation_errors);
        if (result.grid) {
          setProcessingState('COMPLETING');
          await new Promise(resolve => setTimeout(resolve, 500));
          handleGridDetected(result.grid);
          setProcessingState(null);
        } else {
          throw new Error('Failed to process image: Invalid response format');
        }
      }
    } catch (error) {
      if (error.message.includes('413')) {
        setError({
          title: 'File Too Large',
          message: 'The image file is too large. Please try a smaller image.',
          action: 'Choose Another'
        });
      } else if (error.message.includes('connect')) {
        setError({
          title: 'Connection Error',
          message: 'Please check your internet connection and try again.',
          action: 'Retry'
        });
      } else {
        setError({
          title: 'Processing Error',
          message: 'Could not process the image. Please ensure it contains a clear Sudoku puzzle.',
          action: 'Try Again'
        });
      }
      setProcessingState(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRetry = async () => {
    setIsRetrying(true);
    try {
      await handleCapture(lastFile);
    } finally {
      setIsRetrying(false);
    }
  };

  const handleFileUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files?.[0];
      if (file) {
        try {
          // Add loading state if needed
          const formData = new FormData();
          formData.append('file', file);
          
          // Process the image (implement your image processing logic here)
          await handleCapture(file);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
    };
    input.click();
  };

  const handleCameraCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Handle camera stream (implement your camera capture logic here)
      // You might want to open a modal or create a camera view component
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const validateGrid = (grid) => {
    if (!grid || !Array.isArray(grid) || grid.length !== 9) {
      throw new Error('Invalid grid structure');
    }
    
    for (let row = 0; row < 9; row++) {
      if (!Array.isArray(grid[row]) || grid[row].length !== 9) {
        throw new Error(`Invalid row structure at row ${row}`);
      }
      for (let col = 0; col < 9; col++) {
        const value = grid[row][col];
        if (typeof value !== 'number' || value < 0 || value > 9) {
          throw new Error(`Invalid value at row ${row}, col ${col}: ${value}`);
        }
      }
    }
    return true;
  };

  useEffect(() => {
    if (initialPuzzle) {
      try {
        validateGrid(initialPuzzle);
      } catch (error) {
        console.error('Grid validation error:', error);
        setInitialPuzzle(null);
      }
    }
  }, [initialPuzzle]);

  useEffect(() => {
    if (grid && solution) {
      const isComplete = grid.every((row, i) => 
        row.every((cell, j) => cell === solution[i][j])
      );
      if (isComplete) {
        setIsActive(false);
      }
    }
  }, [grid, solution]);

  const handleNumberSelect = useCallback((number) => {
    if (selectedCell) {
      updateCell(selectedCell.row, selectedCell.col, number);
      if (number !== 0) {
        setUserEnteredNumbers(prev => new Set(prev).add(`${selectedCell.row}-${selectedCell.col}`));
      }
    }
  }, [selectedCell, updateCell]);

  const handleGridDetected = useCallback((detectedGrid) => {
    console.log('SudokuInterface received grid:', detectedGrid);
    
    if (!detectedGrid || !Array.isArray(detectedGrid) || 
        detectedGrid.length !== 9 || 
        !detectedGrid.every(row => Array.isArray(row) && row.length === 9)) {
      console.error('Invalid grid format received:', detectedGrid);
      return;
    }
    
    const sanitizedGrid = detectedGrid.map(row =>
      row.map(cell => {
        const num = parseInt(cell);
        return isNaN(num) ? 0 : num;
      })
    );
    
    setFullGrid(sanitizedGrid);
    setInitialPuzzle(sanitizedGrid);
    setIsActive(true);
    setShowSuccess(true);
    
    console.log('Grid set successfully:', sanitizedGrid);
  }, [setFullGrid]);

  const handleApplyHint = useCallback(() => {
    if (currentHint) {
      applyHint(currentHint);
      if (availableHints > 0) {
        setAvailableHints(prev => prev - 1);
      }
    }
  }, [currentHint, applyHint, availableHints]);

  const calculateProgress = () => {
    const filledCells = grid.flat().filter(cell => cell !== 0).length;
    return Math.round((filledCells / 81) * 100);
  };

  const handleCheckProgress = () => {
    const isValid = validateGridState();
    setValidationMessage({
      type: isValid ? 'success' : 'error',
      text: isValid ? `Progress: ${calculateProgress()}% - Looking good!` : 'There are some errors to fix'
    });
    setTimeout(() => setValidationMessage(null), 3000);
  };

  const onRequestHint = () => {
    getHint();
  };

  return (
    <>
      <head>
        <meta name="description" content="Free online Sudoku solver with smart image recognition, intelligent hints, and step-by-step guidance. Upload your puzzle or input numbers manually to get instant help." />
        <meta name="keywords" content="sudoku solver, puzzle solver, sudoku helper, sudoku OCR, sudoku techniques, online sudoku" />
      </head>
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-6xl mx-auto p-4">
          <Card className="bg-white shadow-lg rounded-xl overflow-hidden">
            {/* Header */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-8">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="text-3xl font-bold text-white mb-3">Sudoku Solver</h1>
                <p className="text-blue-100 text-lg">
                  Solve any Sudoku puzzle with AI-powered assistance
                </p>
              </div>
            </div>
            {/* How to Use Section */}
            <div className="bg-white border-b border-gray-100">
              <div className="max-w-6xl mx-auto p-6">
                <div className="grid md:grid-cols-3 gap-8">
                  {[
                    {
                      title: "How to Use",
                      icon: BookOpen,
                      items: [
                        "Upload a photo of your puzzle",
                        "Or manually input numbers",
                        "Get smart hints when stuck", 
                        "Check progress anytime"
                      ]
                    },
                    {
                      title: "Features",
                      icon: Sparkles,
                      items: [
                        "Advanced OCR recognition",
                        "Step-by-step guidance",
                        "Multiple difficulty levels",
                        "Real-time validation"
                      ]
                    },
                    {
                      title: "Tips",
                      icon: Lightbulb,
                      items: [
                        "Use undo/redo for corrections",
                        "Request hints for help",
                        "Clear function removes numbers",
                        "Validate to prevent mistakes"
                      ]
                    }
                  ].map(section => (
                    <div key={section.title} className="group">
                      <div className="flex items-center gap-3 mb-4">
                        <div className="p-2 rounded-lg bg-blue-50 text-blue-600 group-hover:scale-110 transition-transform">
                          <section.icon className="w-5 h-5" />
                        </div>
                        <h2 className="text-lg font-semibold text-gray-800">
                          {section.title}
                        </h2>
                      </div>
                      <ul className="space-y-2">
                        {section.items.map((item, i) => (
                          <li key={i} className="flex items-start gap-2 text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mt-2" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Main Grid Area */}
            <div className="flex flex-col lg:flex-row gap-8 p-6">
              <div className="flex-1">
                {/* Control Buttons */}
                <div className="flex gap-2">
                  {[
                    { icon: Undo, label: 'Undo', onClick: handleUndo, disabled: !canUndo },
                    { icon: Redo, label: 'Redo', onClick: handleRedo, disabled: !canRedo }
                  ].map(btn => (
                    <button
                      key={btn.label}
                      onClick={btn.onClick}
                      disabled={btn.disabled}
                      className="px-4 py-2 rounded-lg bg-white border border-gray-200 
                        text-gray-600 hover:bg-gray-50 flex items-center gap-2 transition-all
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <btn.icon className="w-4 h-4" />
                      {btn.label}
                    </button>
                  ))}
                  <button 
                    onClick={resetGrid}
                    className="px-4 py-2 rounded-lg bg-red-50 text-red-600 
                      hover:bg-red-100 flex items-center gap-2 ml-auto transition-all"
                  >
                    <RotateCcw className="w-4 h-4" />
                    Reset
                  </button>
                </div>

                {/* Grid */}
                <div className="bg-white rounded-xl p-6 shadow-lg">
                  <GridInput
                    grid={grid}
                    onCellChange={updateCell}
                    selectedCell={selectedCell}
                    onCellSelect={selectCell}
                    highlightedCells={highlightedCells}
                    validationErrors={validationErrors}
                    userEnteredNumbers={userEnteredNumbers}
                    className="w-full"
                  />
                </div>
              </div>

              {/* Right Controls */}
              <div className="lg:w-96">
                {/* Import Section */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Import Puzzle</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <Button 
                      variant="outline" 
                      className="w-full flex items-center justify-center gap-2 hover:bg-blue-50"
                      onClick={handleFileUpload}
                    >
                      <Upload className="w-4 h-4" />
                      Upload
                    </Button>
                    <Button 
                      variant="outline"
                      className="w-full flex items-center justify-center gap-2 hover:bg-blue-50"
                      onClick={handleCameraCapture}
                    >
                      <Camera className="w-4 h-4" />
                      Camera
                    </Button>
                  </div>
                </div>

                {/* Number Input */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4">Number Input</h3>
                  <NumberPad
                    onNumberSelect={handleNumberSelect}
                    onClear={() => selectedCell && handleNumberSelect(0)}
                    selectedNumber={selectedCell ? grid[selectedCell.row][selectedCell.col] : null}
                  />
                </div>

                {/* Hint Section - Always visible */}
                <div className="p-4 bg-blue-50 rounded-lg">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Lightbulb className="w-5 h-5 text-blue-600" />
                      <span>Need help? Get a hint!</span>
                    </div>
                    <Button onClick={onRequestHint} variant="default">
                      Show Hint
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <ProcessingOverlay state={processingState} />
      {showSuccess && (
        <SuccessOverlay onContinue={() => setShowSuccess(false)} />
      )}
      <ErrorNotification 
        error={error}
        onRetry={handleRetry}
        onDismiss={() => setError(null)}
      />
    </>
  );
};

export default SudokuInterface;