// src/components/sudoku/ProcessingOverlay.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, Scan, Brain, CheckCircle } from 'lucide-react';

const ProcessingOverlay = ({ state }) => {
  const ProcessingStages = {
    UPLOADING: {
      icon: Upload,
      title: "Uploading image...",
      description: "Sending your puzzle for analysis"
    },
    ANALYZING: {
      icon: Scan,
      title: "Scanning puzzle...", 
      description: "Detecting grid and numbers"
    },
    PROCESSING: {
      icon: Brain,
      title: "Processing numbers...",
      description: "Validating detected digits"
    },
    COMPLETING: {
      icon: CheckCircle,
      title: "Almost done!",
      description: "Preparing your puzzle"
    }
  };

  const content = ProcessingStages[state];
  if (!content) return null;

  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      >
        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="bg-white rounded-xl p-8 max-w-sm w-full mx-4 text-center"
        >
          <motion.div
            animate={{ rotate: state === 'PROCESSING' ? 360 : 0 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="mx-auto w-16 h-16 mb-4"
          >
            <content.icon className="w-full h-full text-blue-500" />
          </motion.div>
          
          <h3 className="text-xl font-semibold mb-2">{content.title}</h3>
          <p className="text-gray-600">{content.description}</p>
          
          {state === 'UPLOADING' && (
            <div className="mt-4">
              <div className="h-1 w-full bg-gray-100 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: "0%" }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 3 }}
                  className="h-full bg-blue-500"
                />
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProcessingOverlay;