// src/utils/constants/sudoku/constants.js

export const HIGHLIGHT_TYPES = {
  HINT: 'hint',               // The cell where number should go
  BLOCKING_ROW: 'blocking_row',     // Numbers in same row blocking other values
  BLOCKING_COLUMN: 'blocking_column', // Numbers in same column blocking other values
  BLOCKING_BOX: 'blocking_box',     // Numbers in same box blocking other values
  BLOCKING_NUMBER: 'blocking_number', // Same number blocking other positions
  CANDIDATE: 'candidate',      // Possible positions for a number
  RELATED: 'related',         // Related cells for current hint
  PATTERN: 'pattern',         // For X-Wing and Swordfish patterns
  ELIMINATION: 'elimination', // Cells where candidates can be eliminated
  PIVOT: 'pivot',           // For XY-Wing pivot cell
  WING: 'wing'             // For XY-Wing wing cells
};

export const HIGHLIGHT_COLORS = {
  hint: 'bg-green-100 text-green-700',
  pattern: 'bg-blue-50 text-blue-600',
  related: 'bg-gray-100',
  conflict: 'bg-red-50 text-red-600',
  elimination: 'bg-yellow-50 text-yellow-600',
  target: 'bg-green-100 text-green-700 ring-2 ring-green-400',
  wing: 'bg-purple-50 text-purple-600',
  pivot: 'bg-indigo-50 text-indigo-600'
};

export const SOLVING_TECHNIQUES = {
  SINGLE_CANDIDATE: {
    id: 'single_candidate',
    name: 'Single Candidate',
    description: 'Only one possible number can go in this cell'
  },
  SINGLE_POSITION: {
    id: 'single_position',
    name: 'Single Position',
    description: 'This number can only go in one position in this region'
  },
  X_WING: {
    id: 'x_wing',
    name: 'X-Wing',
    description: 'When a number appears exactly twice in two different rows/columns, forming a rectangle pattern'
  },
  SWORDFISH: {
    id: 'swordfish',
    name: 'Swordfish',
    description: 'Similar to X-Wing but involves three rows/columns and forms a more complex pattern'
  },
  XY_WING: {
    id: 'xy_wing',
    name: 'XY-Wing',
    description: 'A three-cell pattern where a pivot cell sees two wing cells that share a common candidate'
  }
};