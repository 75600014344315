// src/pages/common/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Camera, 
  PuzzleIcon, 
  Share2, 
  Zap, 
  Grid3X3, 
  Brain,
  Clock,
  Award,
  ArrowRight,
  Gift,
  CheckCircle,
  Users,
  Star,
  Eye,
  Crosshair,
  Scan,
  Palette,
  LayoutGrid,
  UserX
} from 'lucide-react';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';

const HeroSection = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-blue-50 to-white">
      {/* Background Pattern (keep existing) */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0" 
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l7.9-7.9h-.828zm5.656 0L19.515 8.485 17.343 10.657l7.9-7.9h2.757zm5.656 0L24.172 8.485 26.343 10.657l7.9-7.9h-.828zm5.656 0L29.828 8.485 32 10.657l7.9-7.9h-2.83zm5.657 0L35.485 8.485 40.657 13.657 48.558 5.757 44.9 2.1l-1.414 1.415L41.8 0h2.83zM0 5.373l.828.828-1.415 1.415L0 5.373zm0 5.656l.828.828-1.415 1.415L0 11.029zm0 5.657l.828.828-1.415 1.415L0 16.686zm0 5.657l.828.828-1.415 1.415L0 22.343zm0 5.656l.828.828-1.415 1.415L0 27.999zm0 5.657l.828.828-1.415 1.415L0 33.656zm0 5.657l.828.828-1.415 1.415L0 39.313zm0 5.657l.828.828-1.415 1.415L0 44.97zm0 5.657l.828.828-1.415 1.415L0 50.627zm0 5.657l.828.828-1.415 1.415L0 56.284zM54.627 60L60 54.627l-.828-.828L57.757 55.213 54.627 60zM5.373 60L0 54.627l.828-.828 1.415 1.415L5.373 60zm43.597 0L60 48.97l-.828-.828-1.415 1.414L48.97 60h-2.83zm-37.94 0L0 48.97l.828-.828 1.415 1.414L11.03 60h2.83zm26.284 0L49.8 53.515l-1.414 1.414-7.9-7.9h2.83zm-20.656 0L10.2 53.515l1.414 1.414 7.9-7.9h-2.83zm11.314 0L35.485 51.343 32 54.627l-7.9-7.9h2.757zm5.656 0L41.142 51.343 37.657 54.627l-7.9-7.9h.828zm5.656 0L46.798 51.343 44.627 54.627l-7.9-7.9h2.83z' fill='%234299e1' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
            backgroundSize: '60px 60px',
            animation: 'floatingBackground 30s linear infinite'
          }} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative pt-20 pb-16 sm:pb-24">
          {/* Feature Badge */}
          <div className="text-center mb-6">
            <div className="inline-flex items-center gap-2 bg-blue-50 text-blue-600 px-4 py-2 rounded-full text-sm font-medium">
              <Zap className="w-4 h-4" />
              <span>Powered by Advanced AI Technology</span>
            </div>
          </div>

          {/* Main Hero Content */}
          <div className="text-center mb-12">
            <h1 className="text-5xl sm:text-6xl font-extrabold text-gray-900 tracking-tight mb-8 
              opacity-0 animate-fadeIn">
              Smart Puzzle{' '}
              <span className="text-blue-600 relative">
                Solving Assistant
                <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-200 transform scale-x-0 
                  animate-expandUnderline"></div>
              </span>
            </h1>
            
            <p className="max-w-2xl mx-auto text-xl text-gray-500 mb-10 
              opacity-0 animate-fadeInDelay">
              Let AI help you solve any puzzle - from complex Sudoku grids to challenging 
              jigsaw pieces. Get instant analysis and step-by-step guidance.
            </p>

            {/* Key Features Instead of Stats */}
            <div className="flex justify-center gap-8 mb-10 opacity-0 animate-fadeInDelay" 
              style={{ animationDelay: '0.4s' }}>
              {[
                { icon: Brain, label: 'Smart Analysis' },
                { icon: Clock, label: 'Instant Solutions' },
                { icon: CheckCircle, label: 'Step-by-Step Guide' }
              ].map((feature, idx) => (
                <div key={idx} className="flex flex-col items-center gap-2">
                  <div className="p-3 rounded-full bg-blue-50">
                    <feature.icon className="w-6 h-6 text-blue-600" />
                  </div>
                  <div className="text-gray-600 font-medium">{feature.label}</div>
                </div>
              ))}
            </div>

            {/* CTA Buttons */}
            <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6 mb-12">
              <Link to="/sudoku-solver">
                <button className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white 
                  px-8 sm:px-10 py-4 sm:py-5 rounded-xl
                  flex items-center gap-3 text-lg font-semibold 
                  transform hover:translate-y-[-2px] hover:shadow-lg transition-all duration-300
                  group relative overflow-hidden">
                  <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform 
                    -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-20 
                    group-hover:animate-shine" />
                  <Grid3X3 className="w-6 h-6" />
                  <span>Start Solving Sudoku</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </Link>
              <Link to="/jigsaw-solver">
                <button className="w-full sm:w-auto bg-white hover:bg-gray-50 text-gray-800 
                  px-8 sm:px-10 py-4 sm:py-5 rounded-xl
                  flex items-center gap-3 text-lg font-semibold shadow-md border border-gray-200
                  transform hover:translate-y-[-2px] hover:shadow-lg transition-all duration-300
                  group">
                  <PuzzleIcon className="w-6 h-6" />
                  <span>Try Jigsaw Solver</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </Link>
            </div>

            {/* Feature Tags */}
            <div className="flex flex-wrap justify-center gap-4">
              {[
                { icon: Gift, text: 'Free to Use' },
                { icon: UserX, text: 'No Sign-up Required' },
                { icon: Brain, text: 'Advanced AI' },
                { icon: Grid3X3, text: 'Multiple Puzzles' }
              ].map((item) => (
                <div 
                  key={item.text} 
                  className="flex items-center gap-2 px-4 py-2.5 bg-white/80 
                    rounded-lg border border-gray-100 hover:border-blue-200 
                    hover:bg-blue-50/50 transition-all duration-300 group"
                >
                  <item.icon className="w-4 h-4 text-blue-600 group-hover:scale-110 transition-transform" />
                  <span className="text-gray-600 font-medium">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const puzzleTypes = [
    {
      icon: Grid3X3,
      title: "Sudoku Solver",
      description: "Upload or input any Sudoku puzzle and get step-by-step solving guidance",
      link: "/sudoku-solver",
      features: [
        "Advanced pattern recognition for X-Wing and Singles",
        "Smart hints and validations", 
        "OCR technology for puzzle scanning",
        "Progress tracking and statistics"
      ]
    },
    {
      icon: PuzzleIcon,
      title: "Jigsaw Solver",
      description: "Solve complex jigsaw puzzles with AI-powered piece recognition and matching",
      link: "/jigsaw-solver", 
      features: [
        "Edge pattern matching algorithm",
        "Color and texture analysis",
        "Piece orientation assistance",
        "Multiple puzzle support"
      ]
    }
  ];

  const features = [
    {
      icon: Camera,
      title: "Instant Puzzle Scanning",
      description: "Take a photo of your puzzle and let our advanced OCR technology do the work"
    },
    {
      icon: Brain,
      title: "AI-Powered Analysis", 
      description: "Utilize cutting-edge computer vision and pattern recognition algorithms"
    },
    {
      icon: Clock,
      title: "Real-Time Solving",
      description: "Get immediate feedback and step-by-step guidance as you solve"
    },
    {
      icon: Award,
      title: "Learning Assistant",
      description: "Improve your solving skills with detailed explanations of techniques"
    }
  ];

  return (
    <>
      <head>
        <title>Solve A Puzzle - AI-Powered Sudoku & Jigsaw Puzzle Solver</title>
        <meta name="description" content="Free online puzzle solver using AI technology. Solve Sudoku puzzles and complex jigsaw puzzles with our advanced OCR and pattern recognition tools." />
        <meta name="keywords" content="puzzle solver, sudoku solver, jigsaw solver, OCR puzzle recognition, AI puzzle solver, OCR sudoku solver, sudoku solution, sudoku helper, solve sudoku online, sudoku scanner, advanced sudoku techniques" />
        <meta property="og:title" content="Solve A Puzzle - Smart Puzzle Solving Assistant" />
        <meta property="og:description" content="Use AI to solve Sudoku and Jigsaw puzzles instantly. Free online tool with step-by-step guidance." />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Solve A Puzzle" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solve A Puzzle" />
        <meta property="og:image" content="/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Solve A Puzzle - AI-Powered Puzzle Solver" />
        <meta name="twitter:description" content="Free online puzzle solver with AI technology. Solve Sudoku and Jigsaw puzzles instantly with our smart assistant." />
        <link rel="canonical" href="https://yourwebsite.com" />
        <link rel="alternate" href="/sudoku-solver" title="Sudoku Solver" />
        <link rel="alternate" href="/jigsaw-solver" title="Jigsaw Solver" />
        <link rel="alternate" href="/sudoku-generator" title="Sudoku Generator" />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Solve A Puzzle",
            "applicationCategory": "UtilityApplication",
            "description": "AI-powered puzzle solver for Sudoku and Jigsaw puzzles",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "featureList": [
              "Sudoku Solver with OCR",
              "Jigsaw Puzzle Assistant",
              "Step-by-step Guidance",
              "AI Pattern Recognition"
            ]
          })}
        </script>
      </head>

      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        {/* Hero Section */}
        <HeroSection />

        {/* Enhanced Puzzle Types Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h2 className="text-3xl font-bold text-center mb-4">Choose Your Puzzle</h2>
          <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Select from our range of AI-powered solvers, each designed to handle specific types of puzzles with maximum accuracy.
          </p>
          <div className="grid md:grid-cols-2 gap-8">
            {puzzleTypes.map((type) => (
              <Card key={type.title} 
                className="p-8 hover:shadow-xl transition-all duration-300 relative overflow-hidden group
                  border-2 border-transparent hover:border-blue-100">
                {/* Decorative gradient background */}
                <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent 
                  opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                
                <div className="relative">
                  <div className="flex items-center gap-4 mb-6">
                    <div className="p-4 rounded-xl bg-blue-100 group-hover:bg-blue-200 transition-colors">
                      <type.icon className="w-8 h-8 text-blue-600" />
                    </div>
                    <h3 className="text-2xl font-bold">{type.title}</h3>
                  </div>
                  
                  <p className="text-gray-600 mb-6 text-lg">{type.description}</p>
                  
                  <ul className="space-y-4 mb-8">
                    {type.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-3 text-gray-600">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  
                  <Link to={type.link}>
                    <button className="w-full bg-white group-hover:bg-blue-600 text-blue-600 group-hover:text-white 
                      py-4 px-6 rounded-xl border-2 border-blue-200 group-hover:border-blue-600
                      flex items-center justify-center gap-2 transition-all duration-300
                      font-semibold text-lg shadow-sm hover:shadow-md">
                      <div className="flex items-center gap-2">
                        {type.title === "Sudoku Solver" ? (
                          <Grid3X3 className="w-5 h-5" />
                        ) : (
                          <PuzzleIcon className="w-5 h-5" />
                        )}
                        <span>Start Solving</span>
                        <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                      </div>
                    </button>
                  </Link>
                </div>
              </Card>
            ))}
          </div>
        </div>

        {/* Enhanced Features Section */}
        <div className="bg-gradient-to-b from-white to-blue-50 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-4">How It Works</h2>
            <p className="text-gray-600 text-center mb-16 max-w-2xl mx-auto">
              Our AI-powered system makes puzzle solving easier and more enjoyable than ever before.
            </p>
            <div className="grid md:grid-cols-4 gap-12">
              {features.map((feature, index) => (
                <div key={feature.title} 
                  className="relative group"
                  style={{ animationDelay: `${index * 150}ms` }}>
                  <div className="text-center">
                    <div className="w-20 h-20 bg-blue-100 rounded-2xl flex items-center justify-center 
                      mx-auto mb-6 group-hover:scale-110 transition-transform duration-300
                      relative">
                      <feature.icon className="w-10 h-10 text-blue-600" />
                      {/* Decorative number */}
                      <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-blue-600 
                        text-white text-sm flex items-center justify-center font-medium">
                        {index + 1}
                      </div>
                    </div>
                    <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                    <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Enhanced FAQ Section */}
        <div className="bg-gradient-to-b from-white to-blue-50 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-4">Frequently Asked Questions</h2>
            <p className="text-gray-600 text-center mb-16 max-w-2xl mx-auto">
              Everything you need to know about our puzzle solving tools and features.
            </p>
            <div className="grid md:grid-cols-2 gap-x-12 gap-y-10 max-w-6xl mx-auto">
              {[
                {
                  q: "How does the Sudoku solver work?",
                  a: "Our Sudoku solver uses advanced OCR technology to recognize numbers from your puzzle image, then applies multiple solving techniques including Single Candidate, Hidden Singles, and X-Wing patterns to provide step-by-step solutions.",
                  icon: Grid3X3
                },
                {
                  q: "Can I solve jigsaw puzzles with missing pieces?",
                  a: "Yes! Our AI can analyze partial puzzles and help identify matching pieces based on edge patterns, colors, and texture analysis. It adapts to different puzzle sizes and complexities.",
                  icon: PuzzleIcon
                },
                {
                  q: "Is the puzzle solver free to use?",
                  a: "Yes, our puzzle solver is completely free to use. You can solve unlimited Sudoku puzzles and get help with jigsaw puzzles without any cost or subscription.",
                  icon: Gift
                },
                {
                  q: "What types of puzzles are supported?",
                  a: "Currently, we support 9x9 Sudoku puzzles of any difficulty level and jigsaw puzzles. Our system can handle both digital images and physical puzzle photos.",
                  icon: Grid3X3
                },
                {
                  q: "How accurate is the image recognition?",
                  a: "Our OCR technology has a high accuracy rate for clear images. For best results, ensure good lighting and a straight-on angle when photographing your puzzle.",
                  icon: Camera
                },
                {
                  q: "Can I get hints without solving the whole puzzle?",
                  a: "Absolutely! Our solver provides targeted hints and explanations for specific steps, helping you learn advanced solving techniques while progressing at your own pace.",
                  icon: Brain
                }
              ].map((item, index) => (
                <div key={index} 
                  className="p-6 rounded-xl bg-white shadow-sm hover:shadow-md transition-all duration-300
                    border border-gray-100 hover:border-blue-100 group">
                  <div className="flex items-start gap-4">
                    <div className="p-3 rounded-lg bg-blue-50 text-blue-600 group-hover:scale-110 transition-transform">
                      <item.icon className="w-6 h-6" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-3 group-hover:text-blue-600 transition-colors">
                        {item.q}
                      </h3>
                      <p className="text-gray-600 leading-relaxed">{item.a}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Enhanced Puzzle Solving Techniques Section */}
        <div className="bg-white py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-4">Puzzle Solving Techniques</h2>
            <p className="text-gray-600 text-center mb-16 max-w-2xl mx-auto">
              Learn about the advanced strategies our AI uses to solve complex puzzles.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16">
              {/* Sudoku Strategies */}
              <div className="space-y-8">
                <div className="inline-flex items-center gap-3 bg-blue-50 px-4 py-2 rounded-full">
                  <Grid3X3 className="w-5 h-5 text-blue-600" />
                  <h3 className="text-xl font-bold text-blue-600">Sudoku Strategies</h3>
                </div>
                
                <div className="space-y-6">
                  {[
                    {
                      title: "Single Candidate",
                      description: "Find cells where only one number can legally be placed based on the puzzle's current state and Sudoku rules.",
                      icon: CheckCircle
                    },
                    {
                      title: "Hidden Singles",
                      description: "Identify numbers that can only appear in one position within a row, column, or 3x3 box due to existing placements.",
                      icon: Eye
                    },
                    {
                      title: "X-Wing Pattern",
                      description: "Advanced technique where a number appears in exactly two cells in two different rows or columns, creating elimination opportunities.",
                      icon: Crosshair
                    }
                  ].map((technique, index) => (
                    <div key={index} className="group cursor-pointer">
                      <div className="relative">
                        <div className="flex items-start gap-4 p-6 rounded-xl bg-gradient-to-br from-blue-50/50 to-transparent
                          border border-blue-100/50 hover:border-blue-200 transition-all duration-300">
                          <div className="p-2 rounded-lg bg-white shadow-sm group-hover:scale-110 transition-transform">
                            <technique.icon className="w-5 h-5 text-blue-600" />
                          </div>
                          <div>
                            <h4 className="text-lg font-semibold text-blue-900 mb-2">{technique.title}</h4>
                            <p className="text-gray-600">{technique.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Jigsaw Solving Tips */}
              <div className="space-y-8">
                <div className="inline-flex items-center gap-3 bg-blue-50 px-4 py-2 rounded-full">
                  <PuzzleIcon className="w-5 h-5 text-blue-600" />
                  <h3 className="text-xl font-bold text-blue-600">Jigsaw Solving Tips</h3>
                </div>
                
                <div className="space-y-6">
                  {[
                    {
                      title: "Edge Analysis",
                      description: "Our AI examines piece edges for shape patterns and connection points, identifying potential matches with high accuracy.",
                      icon: Scan
                    },
                    {
                      title: "Color Matching",
                      description: "Advanced color analysis helps identify pieces that are likely to connect based on color patterns and gradients.",
                      icon: Palette
                    },
                    {
                      title: "Section Building",
                      description: "Build the puzzle efficiently by starting with edges and working inward, using our AI suggestions for the most likely matches.",
                      icon: LayoutGrid
                    }
                  ].map((technique, index) => (
                    <div key={index} className="group cursor-pointer">
                      <div className="relative">
                        <div className="flex items-start gap-4 p-6 rounded-xl bg-gradient-to-br from-blue-50/50 to-transparent
                          border border-blue-100/50 hover:border-blue-200 transition-all duration-300">
                          <div className="p-2 rounded-lg bg-white shadow-sm group-hover:scale-110 transition-transform">
                            <technique.icon className="w-5 h-5 text-blue-600" />
                          </div>
                          <div>
                            <h4 className="text-lg font-semibold text-blue-900 mb-2">{technique.title}</h4>
                            <p className="text-gray-600">{technique.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx>{`
          @keyframes floatingBackground {
            0% { background-position: 0 0; }
            100% { background-position: 60px 60px; }
          }
          
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }

          @keyframes expandUnderline {
            from { transform: scaleX(0); }
            to { transform: scaleX(1); }
          }

          .animate-fadeIn {
            animation: fadeIn 0.6s ease-out forwards;
          }

          .animate-fadeInDelay {
            animation: fadeIn 0.6s ease-out 0.2s forwards;
          }

          .animate-fadeInStagger {
            animation: fadeIn 0.6s ease-out forwards;
          }

          .animate-expandUnderline {
            animation: expandUnderline 0.8s ease-out 0.6s forwards;
          }

          @keyframes shine {
            to {
              left: 200%;
            }
          }

          .animate-shine {
            animation: shine 1s ease-in-out;
          }
        `}</style>
      </div>
    </>
  );
};
export default HomePage;
