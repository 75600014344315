// src/pages/blog/BlogPost.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import BlogSidebar from '../../components/blog/BlogSidebar';
import { posts } from '../../data/blog/posts';
import { categories } from '../../data/blog/categories';
import { Tag, Share2 } from 'lucide-react';
import TechniqueDemo from '../../components/blog/TechniqueDemo';
import AuthorCard from '../../components/blog/AuthorCard';
import ArticleMeta from '../../components/blog/ArticleMeta';

const BlogPost = () => {
  const { slug } = useParams();
  const post = posts.find(p => p.slug === slug);
  const recentPosts = posts.filter(p => p.slug !== slug).slice(0, 5);
  
  if (!post) return <div>Post not found</div>;

  // Get related posts based on categories
  const relatedPosts = posts
    .filter(p => 
      p.slug !== slug && 
      p.categories.some(cat => post.categories.includes(cat))
    )
    .slice(0, 3);

  return (
    <>
      <Helmet>
        <title>{`${post.title} | Puzzle Solver Blog`}</title>
        <meta name="description" content={post.excerpt} />
        <link rel="canonical" href={`${window.location.origin}/blog/${slug}`} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${window.location.origin}/blog/${slug}`} />
        {post.coverImage && <meta property="og:image" content={post.coverImage} />}
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        
        {/* Schema.org markup for the blog post */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "description": post.excerpt,
            "datePublished": post.date,
            "timeRequired": post.readingTime,
            "skillLevel": post.difficulty,
            "author": {
              "@type": "Person",
              "name": post.author,
              "description": post.authorBio,
              "sameAs": [
                `https://twitter.com/${post.authorSocial?.twitter?.replace('@', '')}`,
                `https://${post.authorSocial?.website}`
              ].filter(Boolean)
            },
            "publisher": {
              "@type": "Organization",
              "name": "Puzzle Solver",
              "logo": {
                "@type": "ImageObject",
                "url": `${window.location.origin}/logo.png`
              }
            }
          })}
        </script>
      </Helmet>

      <article className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-12">
          <div className="lg:col-span-3">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {post.coverImage && (
                <img 
                  src={post.coverImage} 
                  alt={post.title}
                  className="w-full h-64 object-cover"
                />
              )}
              
              <div className="p-8">
                {/* Categories */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {post.categories.map(category => (
                    <span 
                      key={category}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                    >
                      <Tag className="w-4 h-4 mr-1" />
                      {category}
                    </span>
                  ))}
                </div>

                <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
                
                <ArticleMeta 
                  readingTime={post.readingTime}
                  difficulty={post.difficulty}
                  date={post.date}
                  author={post.author}
                />

                {/* Content */}
                <div className="prose prose-lg max-w-none mb-8">
                  <section id="hidden-singles">
                    <h2>Hidden Singles: Finding the Concealed Numbers</h2>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </section>
                  
                  <section id="pointing-pairs">
                    <h2>Pointing Pairs: Using Box-Line Reduction</h2>
                    {/* Pointing pairs content */}
                  </section>
                  
                  <section id="faq">
                    <h2>Frequently Asked Questions</h2>
                    {/* FAQ content */}
                  </section>
                  
                  <AuthorCard 
                    author={post.author}
                    bio={post.authorBio}
                    social={post.authorSocial}
                  />
                  
                  <div className="flex items-center gap-4 text-sm text-gray-500 mt-4">
                    <span>{post.readingTime}</span>
                    {post.difficulty && (
                      <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                        {post.difficulty} Level
                      </span>
                    )}
                  </div>
                </div>

                {/* Insert the demo after the placeholder div */}
                {post.hasInteractiveDemo && (
                  <div id="technique-demo">
                    <TechniqueDemo technique="hidden-single" />
                  </div>
                )}
                {post.hasPracticeDemo && (
                  <div id="practice-demo">
                    <TechniqueDemo technique="hidden-single" isPractice={true} />
                  </div>
                )}

                {/* Social Sharing */}
                <div className="border-t pt-6">
                  <div className="flex items-center gap-4">
                    <span className="font-medium">Share this article:</span>
                    <button className="text-blue-600 hover:text-blue-800">
                      <Share2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Related Posts */}
            {relatedPosts.length > 0 && (
              <div className="mt-12">
                <h2 className="text-2xl font-bold mb-6">Related Articles</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {relatedPosts.map(relatedPost => (
                    <div key={relatedPost.slug} className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden">
                      {relatedPost.coverImage && (
                        <img 
                          src={relatedPost.coverImage} 
                          alt={relatedPost.title}
                          className="h-48 w-full object-cover" 
                        />
                      )}
                      <div className="p-4">
                        <h3 className="font-bold text-lg mb-2">{relatedPost.title}</h3>
                        <p className="text-gray-600 text-sm mb-4">{relatedPost.excerpt}</p>
                        <Link 
                          to={`/blog/${relatedPost.slug}`}
                          className="text-blue-600 hover:text-blue-800 font-medium"
                        >
                          Read more →
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <aside>
            <BlogSidebar 
              categories={categories}
              recentPosts={recentPosts}
            />
          </aside>
        </div>
      </article>
    </>
  );
};

export default BlogPost;