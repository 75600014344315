// src/components/blog/AuthorCard.jsx
import React from 'react';
import { User, Twitter, Globe } from 'lucide-react';

const AuthorCard = ({ author, bio, social }) => {
  return (
    <div className="bg-gray-50 rounded-lg p-6 mt-8 border border-gray-100">
      <div className="flex items-start space-x-4">
        <div className="bg-blue-100 p-3 rounded-full">
          <User className="w-6 h-6 text-blue-600" />
        </div>
        <div className="flex-1">
          <h3 className="font-bold text-gray-900">{author}</h3>
          <p className="text-gray-600 mt-1">{bio}</p>
          
          {social && (
            <div className="flex items-center space-x-4 mt-4">
              {social.twitter && (
                <a 
                  href={`https://twitter.com/${social.twitter.replace('@', '')}`}
                  className="flex items-center text-gray-600 hover:text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter className="w-4 h-4 mr-1" />
                  <span className="text-sm">{social.twitter}</span>
                </a>
              )}
              {social.website && (
                <a 
                  href={`https://${social.website}`}
                  className="flex items-center text-gray-600 hover:text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Globe className="w-4 h-4 mr-1" />
                  <span className="text-sm">{social.website}</span>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;