// src/components/jigsaw/SolverStatus.js
import React from 'react';

export const SolverStatus = ({ solverHint, solverStats }) => (
  <div className="bg-white rounded-lg shadow-md p-4 mb-4">
    {solverHint && (
      <div className="mb-4">
        <h3 className="font-bold text-lg text-blue-600">{solverHint.message}</h3>
        <p className="text-gray-600">{solverHint.context}</p>
      </div>
    )}
    <div className="grid grid-cols-3 gap-4 text-center">
      <div>
        <div className="text-2xl font-bold">{solverStats.remainingPieces}</div>
        <div className="text-sm text-gray-500">Remaining Pieces</div>
      </div>
      <div>
        <div className="text-2xl font-bold">{solverStats.completedAreas}%</div>
        <div className="text-sm text-gray-500">Completed</div>
      </div>
      <div>
        <div className="text-2xl font-bold">{solverStats.currentConfidence}%</div>
        <div className="text-sm text-gray-500">Confidence</div>
      </div>
    </div>
  </div>
);

export default SolverStatus;