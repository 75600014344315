import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertCircle } from 'lucide-react';

export const ErrorNotification = ({ error, onRetry, onDismiss }) => (
  <AnimatePresence>
    {error && (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="fixed top-4 left-1/2 -translate-x-1/2 bg-white p-4 rounded-lg shadow-lg border border-red-100 max-w-md w-full mx-4"
      >
        <div className="flex items-start gap-3">
          <div className="p-2 bg-red-50 rounded-lg flex-shrink-0">
            <AlertCircle className="w-5 h-5 text-red-500" />
          </div>
          <div className="flex-grow">
            <h3 className="font-medium mb-1">
              {typeof error === 'object' ? error.title : 'Error'}
            </h3>
            <p className="text-gray-600 text-sm mb-3">
              {typeof error === 'object' ? error.message : error}
            </p>
            <div className="flex gap-2">
              <button
                onClick={onRetry}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600 transition-colors"
              >
                {typeof error === 'object' ? error.action : 'Try Again'}
              </button>
              <button
                onClick={onDismiss}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg text-sm hover:bg-gray-200 transition-colors"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);