// src/components/sudoku/GridInput.js

import React from 'react';
import { Card } from '../../components/ui/card';
import { HIGHLIGHT_COLORS } from '../../utils/constants/sudoku/constants';
import { Check, AlertTriangle, Edit2 } from 'lucide-react';
import { Button } from '../ui/basic';
import { motion } from 'framer-motion';

const GridInput = ({ 
  grid, 
  onCellChange, 
  selectedCell, 
  onCellSelect, 
  highlightedCells = [],
  validationErrors = [],
  userEnteredNumbers = new Set(),
  recognizedDigits = null,
  onConfirmGrid,
  onRetryRecognition,
  isRecognitionMode = false,
  className = ""
}) => {
  const getHighlightType = (row, col) => {
    const highlight = highlightedCells.find(cell => cell.row === row && cell.col === col);
    return highlight?.type || null;
  };

  const isInError = (row, col) => {
    return validationErrors.some(error => 
      error.cells.some(cell => cell.row === row && cell.col === col)
    );
  };

  const getConfidenceStyle = (row, col) => {
    if (!recognizedDigits?.[row]?.[col] || userEnteredNumbers.has(`${row}-${col}`)) {
      return "";
    }

    const confidence = recognizedDigits[row][col].confidence;
    if (confidence > 0.8) return "bg-green-50 hover:bg-green-100";
    if (confidence > 0.5) return "bg-yellow-50 hover:bg-yellow-100";
    return "bg-red-50 hover:bg-red-100";
  };

  const populateCell = (value, row, col) => {
    return (
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ 
          delay: (row * 9 + col) * 0.02,  // Cascade effect
          type: "spring",
          stiffness: 300
        }}
        className="w-full h-full flex items-center justify-center"
      >
        {value}
      </motion.div>
    );
  };

  const getCellClassName = (row, col) => {
    const baseClasses = [
      "w-12 h-12 border text-center text-xl font-medium",
      "focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10",
      "transition-all duration-200",
      isRecognitionMode ? getConfidenceStyle(row, col) : "hover:bg-blue-50"
    ];

    // Border styles for 3x3 boxes
    if (row % 3 === 0 && row !== 0) baseClasses.push("border-t-2 border-t-gray-300");
    if (col % 3 === 0 && col !== 0) baseClasses.push("border-l-2 border-l-gray-300");

    // Number styles
    if (userEnteredNumbers.has(`${row}-${col}`)) {
      baseClasses.push("text-blue-600");
    } else if (grid[row][col] !== 0) {
      baseClasses.push(isRecognitionMode ? "text-gray-700" : "text-gray-900");
    }

    // Selected cell
    if (selectedCell?.row === row && selectedCell?.col === col) {
      baseClasses.push("bg-blue-100 ring-2 ring-blue-400");
    }

    // Error states
    if (isInError(row, col)) {
      baseClasses.push("bg-red-50 text-red-600 ring-2 ring-red-200");
      return baseClasses.join(" ");
    }

    // Highlights
    const highlightType = getHighlightType(row, col);
    if (highlightType) {
      baseClasses.push(HIGHLIGHT_COLORS[highlightType] || "");
    }

    return baseClasses.join(" ");
  };

  const handleCellInput = (e, row, col) => {
    const value = e.target.value;
    if (value === '' || (value.length === 1 && /^[1-9]$/.test(value))) {
      onCellChange(row, col, value === '' ? 0 : parseInt(value, 10));
    }
  };

  // Calculate recognition statistics
  const stats = isRecognitionMode && recognizedDigits ? {
    total: recognizedDigits.flat().filter(cell => cell?.digit !== 0).length,
    highConfidence: recognizedDigits.flat().filter(cell => cell?.confidence > 0.8).length,
    mediumConfidence: recognizedDigits.flat().filter(cell => cell?.confidence > 0.5 && cell?.confidence <= 0.8).length,
    lowConfidence: recognizedDigits.flat().filter(cell => cell?.confidence <= 0.5 && cell?.digit !== 0).length
  } : null;

  return (
    <Card className={`p-6 bg-white shadow-lg ${className}`}>
      {isRecognitionMode && (
        <div className="mb-6 space-y-4">
          <div className="flex justify-between items-start">
            <div className="space-y-2">
              <h3 className="font-semibold text-gray-900">Recognition Results</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2 text-sm">
                  <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                  <span>Detected: {stats.total}/81</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-green-600">
                  <div className="w-2 h-2 rounded-full bg-green-400"></div>
                  <span>High: {stats.highConfidence}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-yellow-600">
                  <div className="w-2 h-2 rounded-full bg-yellow-400"></div>
                  <span>Medium: {stats.mediumConfidence}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-red-600">
                  <div className="w-2 h-2 rounded-full bg-red-400"></div>
                  <span>Low: {stats.lowConfidence}</span>
                </div>
              </div>
            </div>
            <div className="flex gap-3">
              <Button 
                variant="outline" 
                onClick={onRetryRecognition}
                size="sm"
                className="text-gray-600 hover:text-gray-900"
              >
                Try Again
              </Button>
              <Button
                onClick={onConfirmGrid}
                size="sm"
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                <Check className="w-4 h-4 mr-2" />
                Confirm Grid
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-9 gap-0 border-2 border-gray-300 bg-white rounded-xl overflow-hidden">
        {grid.map((row, rowIndex) => (
          row.map((cell, colIndex) => (
            <div key={`${rowIndex}-${colIndex}`} className="relative">
              <input
                type="text"
                inputMode="numeric"
                value={cell === 0 ? '' : cell}
                onChange={(e) => handleCellInput(e, rowIndex, colIndex)}
                onClick={() => onCellSelect(rowIndex, colIndex)}
                className={getCellClassName(rowIndex, colIndex)}
                maxLength={1}
                aria-label={`Cell row ${rowIndex + 1}, column ${colIndex + 1}`}
                aria-invalid={isInError(rowIndex, colIndex)}
                tabIndex={0}
              />
              {isRecognitionMode && recognizedDigits?.[rowIndex]?.[colIndex]?.digit !== 0 && (
                <div className="absolute top-1.5 right-1.5 flex items-center justify-center">
                  {recognizedDigits[rowIndex][colIndex].confidence > 0.8 ? (
                    <div className="w-4 h-4 rounded-full bg-green-100 flex items-center justify-center">
                      <Check className="w-3 h-3 text-green-600" />
                    </div>
                  ) : (
                    <div className="w-4 h-4 rounded-full bg-yellow-100 flex items-center justify-center">
                      <AlertTriangle className="w-3 h-3 text-yellow-600" />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        ))}
      </div>
    </Card>
  );
};

export default GridInput;