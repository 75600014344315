import React, { useState } from 'react';
import { 
  Book, 
  Brain, 
  HelpCircle, 
  Lightbulb,
  ArrowRight
} from 'lucide-react';
import { Link } from 'react-router-dom';

const DocumentationPage = () => {
  const [hoveredSection, setHoveredSection] = useState(null);

  const sections = [
    {
      id: 'getting-started',
      icon: Book,
      title: "Getting Started",
      description: "Learn how to use our AI-powered Sudoku solver",
      color: "bg-blue-500",
      link: "/sudoku/docs/getting-started",
      items: [
        "Using OCR Scanner",
        "Manual Input",
        "Getting Solutions"
      ]
    },
    {
      id: 'techniques',
      icon: Brain,
      title: "Solving Techniques",
      description: "Understanding advanced Sudoku solving strategies",
      color: "bg-indigo-500",
      link: "/sudoku/docs/techniques",
      items: [
        "Single Candidates",
        "Hidden Singles",
        "X-Wing Pattern"
      ]
    },
    {
      id: 'tips',
      icon: Lightbulb,
      title: "Tips & Best Practices",
      description: "Maximize your puzzle solving efficiency",
      color: "bg-purple-500",
      link: "/sudoku/docs/tips",
      items: [
        "Photo Quality Tips",
        "Learning Mode",
        "Keyboard Shortcuts"
      ]
    },
    {
      id: 'faqs',
      icon: HelpCircle,
      title: "FAQs",
      description: "Common questions and troubleshooting",
      color: "bg-violet-500",
      link: "/sudoku/docs/faqs",
      items: [
        "OCR Troubleshooting",
        "Solution Accuracy",
        "Supported Formats"
      ]
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      {/* Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Documentation</h1>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Learn how to use our AI-powered Sudoku solver and master advanced solving techniques.
        </p>
      </div>

      {/* Documentation Grid */}
      <div className="grid md:grid-cols-2 gap-6">
        {sections.map((section) => (
          <Link
            to={section.link}
            key={section.id}
            className="group relative"
            onMouseEnter={() => setHoveredSection(section.id)}
            onMouseLeave={() => setHoveredSection(null)}
          >
            <div className="bg-white rounded-xl p-6 h-full border border-gray-100 
              hover:border-blue-200 transition-all duration-300
              hover:shadow-lg relative overflow-hidden">
              
              {/* Decorative Gradient Background */}
              <div className={`absolute top-0 right-0 w-32 h-32 opacity-5 
                rounded-full blur-2xl transform translate-x-16 -translate-y-8
                group-hover:opacity-10 transition-opacity ${section.color}`} />

              {/* Header */}
              <div className="flex items-start gap-4 mb-6">
                <div className={`p-3 rounded-xl ${section.color} bg-opacity-10 
                  group-hover:scale-110 transition-transform`}>
                  <section.icon className={`w-6 h-6 ${section.color} text-opacity-90`} />
                </div>
                <div>
                  <h2 className="text-xl font-bold mb-1 group-hover:text-blue-600 
                    transition-colors">
                    {section.title}
                  </h2>
                  <p className="text-gray-600">{section.description}</p>
                </div>
              </div>

              {/* Items */}
              <ul className="space-y-3 mb-6">
                {section.items.map((item, index) => (
                  <li key={index} className="flex items-center gap-2 text-gray-600">
                    <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                    {item}
                  </li>
                ))}
              </ul>

              {/* Footer */}
              <div className="flex items-center text-blue-600 font-medium
                group-hover:translate-x-2 transition-transform">
                Learn More
                <ArrowRight className="w-4 h-4 ml-1" />
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Help Section */}
      <div className="mt-12 bg-gradient-to-br from-blue-50 to-white rounded-xl p-8 text-center
        border border-blue-100">
        <h3 className="text-xl font-semibold mb-4">Need More Help?</h3>
        <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
          Can't find what you're looking for? Our support team is here to help you with any questions.
        </p>
        <Link 
          to="/contact"
          className="inline-flex items-center gap-2 bg-white text-blue-600 px-6 py-3 
            rounded-lg hover:bg-blue-50 transition-colors border border-blue-200
            hover:border-blue-300"
        >
          <HelpCircle className="w-5 h-5" />
          <span>Contact Support</span>
        </Link>
      </div>
    </div>
  );
};

export default DocumentationPage;
