// src/hooks/usePuzzleSolver.js

import { useState, useCallback } from 'react';
import { 
  analyzePuzzleStructure,
  findBestGap,
  generateSolverHint,
  calculateSolverStats
} from '../../utils/jigsaw/solverUtils';
import { findBestMatches } from '../../utils/jigsaw/analysisUtils';

export const usePuzzleSolver = () => {
  const [imageData, setImageData] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [showInterface, setShowInterface] = useState(false);
  const [highlightedGap, setHighlightedGap] = useState(null);
  const [highlightedPieces, setHighlightedPieces] = useState([]);
  const [solverHint, setSolverHint] = useState(null);
  const [solverStats, setSolverStats] = useState({
    remainingPieces: 0,
    completedAreas: 0,
    currentConfidence: 0
  });

  const handleImagesUpload = useCallback((data) => {
    console.log('Images uploaded:', data);
    setImageData({
      referenceImage: data.completeImage,
      currentProgress: data.progressImage,
      remainingPieces: data.remainingPieces
    });
    setProcessedData(null);
    setError(null);
  }, []);

  const handleProcessingComplete = useCallback((data) => {
    console.log('Processing complete:', data);
    setProcessedData(data);
    setTimeout(() => setShowInterface(true), 500);
    setTimeout(() => {
      setIsComplete(true);
      setTimeout(() => {
        setIsComplete(false);
      }, 1500);
    }, 500);
  }, []);

  const handleProcessingError = useCallback((errorMessage) => {
    console.error('Processing error:', errorMessage);
    setError(errorMessage);
    setProcessedData(null);
  }, []);

  const handleReset = useCallback(() => {
    setImageData(null);
    setProcessedData(null);
    setError(null);
    setShowInterface(false);
    setHighlightedGap(null);
    setHighlightedPieces([]);
    setSolverHint(null);
    setSolverStats({
      remainingPieces: 0,
      completedAreas: 0,
      currentConfidence: 0
    });
  }, []);

  const handleSaveProgress = useCallback(() => {
    if (!processedData) return;
    
    try {
      localStorage.setItem('puzzleProgress', JSON.stringify({
        timestamp: new Date().toISOString(),
        data: processedData
      }));
    } catch (err) {
      console.error('Failed to save progress:', err);
      setError('Failed to save progress');
    }
  }, [processedData]);

  const handleSolve = useCallback(() => {
    if (!processedData) {
      setError('No puzzle data available');
      return;
    }
    
    try {
      const { gaps, pieces, referenceData } = processedData;
      
      // Analyze puzzle structure
      const analyzedGaps = analyzePuzzleStructure(gaps, pieces, referenceData);
      const bestGap = findBestGap(analyzedGaps);
      
      if (!bestGap) {
        setError('Unable to find suitable gaps');
        return;
      }

      // Find matching pieces
      const bestMatches = findBestMatches(bestGap, pieces, processedData.suggestedPlacements);
      
      if (!bestMatches || bestMatches.length === 0) {
        setError('No matching pieces found');
        return;
      }

      // Update state with results
      setHighlightedGap(bestGap);
      setHighlightedPieces(bestMatches);
      setSolverHint(generateSolverHint(bestGap, bestMatches));
      setSolverStats(calculateSolverStats(gaps, pieces, bestMatches[0]));

    } catch (err) {
      console.error('Solver error:', err);
      setError(err.message || 'Error during puzzle analysis');
    }
  }, [processedData]);

  return {
    imageData,
    processedData,
    error,
    isComplete,
    showInterface,
    highlightedGap,
    highlightedPieces,
    solverHint,
    solverStats,
    handleImagesUpload,
    handleProcessingComplete,
    handleProcessingError,
    handleReset,
    handleSaveProgress,
    handleSolve
  };
};