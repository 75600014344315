// src/pages/blog/index.js
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogList from '../../components/blog/BlogList';
import BlogSidebar from '../../components/blog/BlogSidebar';
import { posts } from '../../data/blog/posts';
import { categories } from '../../data/blog/categories';

const BlogPage = () => {
  const recentPosts = posts.slice(0, 5);
  const featuredPost = posts.find(post => post.featured);

  return (
    <>
      <Helmet>
        <title>Puzzle Solving Tips & Techniques | Puzzle Solver Blog</title>
        <meta name="description" content="Discover expert puzzle solving techniques, strategies, and tips. Learn how to solve Sudoku, improve your puzzle-solving skills, and more." />
        <meta name="keywords" content="puzzle solving, sudoku techniques, puzzle tips, sudoku solver" />
        <link rel="canonical" href={`${window.location.origin}/blog`} />
        
        {/* Schema.org markup for blog */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "Puzzle Solver Blog",
            "description": "Tips, tricks, and insights about puzzle solving",
            "url": `${window.location.origin}/blog`,
            "author": {
              "@type": "Organization",
              "name": "Puzzle Solver"
            },
            "blogPost": posts.slice(0, 10).map(post => ({
              "@type": "BlogPosting",
              "headline": post.title,
              "description": post.excerpt,
              "datePublished": post.date,
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `${window.location.origin}/blog/${post.slug}`
              }
            }))
          })}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Puzzle Solving Blog</h1>
          <p className="text-xl text-gray-500">
            Expert tips, techniques, and strategies for puzzle solving mastery
          </p>
        </div>

        {/* Featured Post Section */}
        {featuredPost && (
          <div className="mb-12">
            <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-4">
              <p className="text-blue-700">Featured Article</p>
            </div>
            <BlogList posts={[featuredPost]} featured={true} />
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-12">
          <main className="lg:col-span-3">
            {/* Category Filter */}
            <div className="mb-8">
              <nav className="flex flex-wrap gap-2" aria-label="Categories">
                {categories.map(category => (
                  <button
                    key={category.slug}
                    className="px-4 py-2 rounded-full text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                  >
                    {category.name}
                  </button>
                ))}
              </nav>
            </div>

            <BlogList posts={posts.filter(post => !post.featured)} />

            {/* Pagination if needed */}
            {posts.length > 10 && (
              <div className="mt-8 flex justify-center">
                {/* Add pagination component here */}
              </div>
            )}
          </main>

          <aside>
            <BlogSidebar 
              categories={categories}
              recentPosts={recentPosts}
            />
          </aside>
        </div>
      </div>
    </>
  );
};

export default BlogPage;