import React from 'react';
import { 
  CheckCircle, 
  Clock,
} from 'lucide-react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Solve A Puzzle</title>
        <meta 
          name="description" 
          content="Learn how Solve A Puzzle protects your privacy and handles data when using our Sudoku solver." 
        />
      </Helmet>
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="prose prose-headings:text-gray-900 prose-p:text-gray-600 prose-a:text-blue-600 prose-a:no-underline hover:prose-a:underline prose-li:text-gray-600">
          <h1>Privacy Policy</h1>

          <div className="inline-flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full text-sm text-blue-600 mb-8">
            <Clock className="w-4 h-4" />
            Last updated: January 23, 2024
          </div>

          <div className="bg-white sticky top-0 z-10 mb-8 py-4 border-b">
            <div className="flex gap-4 overflow-x-auto pb-2">
              {[
                "Information", "Usage", "Security", "Rights", "Contact"
              ].map(section => (
                <a 
                  key={section}
                  href={`#${section.toLowerCase()}`}
                  className="text-sm text-gray-600 hover:text-blue-600 whitespace-nowrap"
                >
                  {section}
                </a>
              ))}
            </div>
          </div>

          <div className="bg-blue-50 p-6 rounded-xl mb-8">
            <h2 className="text-xl font-bold mb-4">Key Points Summary</h2>
            <div className="grid gap-4 md:grid-cols-2">
              {[
                { title: "No Personal Data", text: "We don't collect or store any personal information" },
                { title: "Image Processing", text: "Images are processed in real-time and immediately deleted" },
                { title: "No Tracking", text: "No advertising or tracking cookies" },
                { title: "Secure Service", text: "All connections are encrypted with HTTPS" }
              ].map(point => (
                <div key={point.title} className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-semibold">{point.title}</h3>
                    <p className="text-sm text-gray-600">{point.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <h2 id="information">Introduction</h2>
          <p>Welcome to Solve A Puzzle. We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we handle any information when you use our Sudoku solver.</p>

          <h2>Information We Collect</h2>

          <h3>Images You Upload</h3>
          <p>When you use our OCR feature to scan Sudoku puzzles, the images you upload are:</p>
          <ul>
            <li>Processed temporarily to recognize numbers</li>
            <li>Not stored permanently on our servers</li>
            <li>Deleted immediately after processing</li>
          </ul>

          <h3>Usage Data</h3>
          <p>We automatically collect:</p>
          <ul>
            <li>Basic technical information (browser type, device type)</li>
            <li>Anonymous usage statistics (features used, puzzles solved)</li>
            <li>No personal identification information is collected</li>
          </ul>

          <h3>We Do Not Collect</h3>
          <ul>
            <li>Personal identification information</li>
            <li>Email addresses</li>
            <li>Names</li>
            <li>Payment information</li>
            <li>Location data</li>
          </ul>

          <h2 id="usage">How We Use The Data</h2>

          <h3>Image Processing</h3>
          <ul>
            <li>Images are used solely for OCR (Optical Character Recognition)</li>
            <li>Processing happens in real-time</li>
            <li>Images are never stored or shared</li>
          </ul>

          <h3>Usage Statistics</h3>
          <p>We use anonymous usage data to:</p>
          <ul>
            <li>Improve our puzzle recognition accuracy</li>
            <li>Enhance user experience</li>
            <li>Fix technical issues</li>
            <li>Understand which features are most helpful</li>
          </ul>

          <h2 id="security">Data Storage and Security</h2>

          <h3>Image Data</h3>
          <ul>
            <li>Processed temporarily in memory</li>
            <li>Not stored permanently</li>
            <li>Automatically deleted after processing</li>
          </ul>

          <h3>Technical Data</h3>
          <ul>
            <li>Stored securely</li>
            <li>Anonymized</li>
            <li>Used only for service improvement</li>
            <li>Not shared with third parties</li>
          </ul>

          <h2>Third-Party Services</h2>
          <p>We use:</p>
          <ul>
            <li>Heroku for hosting</li>
            <li>Basic analytics for service improvement</li>
            <li>No third-party advertising services</li>
            <li>No social media trackers</li>
          </ul>

          <h2 id="rights">Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Know what data we collect</li>
            <li>Request deletion of any data (though we don't store personal data)</li>
            <li>Opt-out of analytics</li>
            <li>Contact us with privacy concerns</li>
          </ul>

          <h2>Children's Privacy</h2>
          <p>Our service is available to users of all ages. We:</p>
          <ul>
            <li>Don't knowingly collect data from children</li>
            <li>Don't require any personal information</li>
            <li>Focus solely on puzzle-solving functionality</li>
          </ul>

          <h2>Changes to This Policy</h2>
          <p>We may update this privacy policy occasionally. Changes will be:</p>
          <ul>
            <li>Posted on this page</li>
            <li>Effective immediately upon posting</li>
            <li>Notified through visible website notice for significant changes</li>
          </ul>

          <h2 id="contact">Contact Us</h2>
          <p>If you have questions about this privacy policy, you can contact us through:</p>
          <ul>
            <li>Our contact form at <a href="https://solveapuzzle.com/contact">solveapuzzle.com/contact</a></li>
            <li>Raising an issue on our GitHub repository</li>
          </ul>

          <h2>Cookies</h2>
          <p>We use only essential cookies for:</p>
          <ul>
            <li>Basic website functionality</li>
            <li>No tracking cookies</li>
            <li>No advertising cookies</li>
            <li>No third-party cookies</li>
          </ul>

          <h2>Data Protection</h2>
          <p>We protect your data through:</p>
          <ul>
            <li>Secure HTTPS connections</li>
            <li>Real-time processing only</li>
            <li>No permanent storage of uploaded images</li>
            <li>Regular security updates</li>
          </ul>

          <p>This privacy policy is designed to be clear and transparent about our data practices while maintaining the simplicity of our service.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;