// src/pages/jigsaw/Results.js
import React from 'react';
import { Card } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Download, Share2 } from 'lucide-react';

const Results = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Button
        variant="ghost"
        className="mb-6 flex items-center gap-2"
        onClick={() => navigate('/jigsaw-solver')}
      >
        <ArrowLeft className="w-4 h-4" />
        Back to Solver
      </Button>

      <Card className="p-6">
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-gray-900">Puzzle Results</h1>
            <div className="flex gap-2">
              <Button variant="outline" className="flex items-center gap-2">
                <Share2 className="w-4 h-4" />
                Share
              </Button>
              <Button variant="outline" className="flex items-center gap-2">
                <Download className="w-4 h-4" />
                Download
              </Button>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4">
            <p className="text-gray-600 text-center">
              Results will appear here after processing your puzzle.
            </p>
          </div>

          {/* Add actual results display logic here based on your needs */}
        </div>
      </Card>
    </div>
  );
};

export default Results;