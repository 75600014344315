// src/components/jigsaw/PieceUploader.js

import React, { useState, useCallback } from 'react';
import { ArrowRight, Upload, Image as ImageIcon, PuzzleIcon, AlertCircle, X } from 'lucide-react';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const PuzzleUploader = ({ onImagesUpload }) => {
  const [step, setStep] = useState(1);
  const [images, setImages] = useState({
    complete: null,
    progress: null,
    remaining: null
  });
  const [previews, setPreviews] = useState({
    complete: null,
    progress: null,
    remaining: null
  });
  const [errors, setErrors] = useState({
    complete: null,
    progress: null,
    remaining: null
  });
  const [isDragging, setIsDragging] = useState(false);

  const steps = [
    {
      id: 1,
      title: "Upload Complete Puzzle Image",
      description: "First, upload an image of the completed puzzle (box cover or reference)",
      key: "complete",
      icon: ImageIcon,
      example: "/examples/complete-puzzle.jpg",
      tip: "Take a clear photo of the puzzle box or a reference image"
    },
    {
      id: 2,
      title: "Upload Current Progress",
      description: "Now, show us how far you've gotten with the puzzle",
      key: "progress",
      icon: PuzzleIcon,
      example: "/examples/puzzle-progress.jpg",
      tip: "Ensure good lighting and capture the entire puzzle area"
    },
    {
      id: 3,
      title: "Upload Remaining Pieces",
      description: "Finally, upload an image of the pieces you still need to place",
      key: "remaining",
      icon: Upload,
      example: "/examples/remaining-pieces.jpg",
      tip: "Spread pieces with some space between them"
    }
  ];

  const validateFile = (file, type) => {
    if (!file) return "Please select a file";
    if (!ACCEPTED_TYPES.includes(file.type)) 
      return "Please upload a JPEG, PNG, or WebP image";
    if (file.size > MAX_FILE_SIZE) 
      return "File size should be less than 5MB";
    return null;
  };

  const handleImageUpload = useCallback(async (file, type) => {
    const error = validateFile(file, type);
    if (error) {
      setErrors(prev => ({ ...prev, [type]: error }));
      return;
    }

    try {
      const dataUrl = await readFileAsDataURL(file);
      await validateImageDimensions(dataUrl);
      
      setPreviews(prev => ({ ...prev, [type]: dataUrl }));
      setImages(prev => ({ ...prev, [type]: file }));
      setErrors(prev => ({ ...prev, [type]: null }));
    } catch (err) {
      setErrors(prev => ({ ...prev, [type]: err.message }));
    }
  }, []);

  const handleDrop = useCallback((e, type) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) handleImageUpload(file, type);
  }, [handleImageUpload]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const removeImage = useCallback((type) => {
    setPreviews(prev => ({ ...prev, [type]: null }));
    setImages(prev => ({ ...prev, [type]: null }));
    setErrors(prev => ({ ...prev, [type]: null }));
  }, []);

  const currentStep = steps[step - 1];
  const currentError = errors[currentStep.key];
  const currentPreview = previews[currentStep.key];

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* Progress indicator */}
      <div className="mb-8">
        <div className="flex justify-between">
          {steps.map(({ id, title }) => (
            <div key={id} className="flex items-center">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                step >= id ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}>
                {id}
              </div>
              <div className="ml-2 text-sm">{title}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Current step content */}
      <div className="mb-8">
        <div className="text-xl font-bold mb-2">{currentStep.title}</div>
        <p className="text-gray-600 mb-4">{currentStep.description}</p>
        
        <div className="flex flex-col items-center">
          <div 
            className={`w-full max-w-md p-4 border-2 border-dashed rounded-lg text-center transition-colors ${
              isDragging 
                ? 'border-blue-500 bg-blue-50' 
                : currentError 
                  ? 'border-red-300 bg-red-50' 
                  : 'border-gray-300 hover:border-blue-500'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, currentStep.key)}
          >
            {currentPreview ? (
              <div className="relative">
                <img
                  src={currentPreview}
                  alt={`Preview for ${currentStep.title}`}
                  className="max-w-full max-h-48 object-contain rounded"
                />
                <button
                  onClick={() => removeImage(currentStep.key)}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <label className="cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e.target.files[0], currentStep.key)}
                />
                <div className="flex flex-col items-center">
                  <currentStep.icon className="w-8 h-8 mb-2 text-gray-400" />
                  <span className="text-sm text-gray-500">
                    Drag and drop or click to upload
                  </span>
                </div>
              </label>
            )}
          </div>

          {/* Error message */}
          {currentError && (
            <div className="mt-2 text-red-500 flex items-center">
              <AlertCircle className="w-4 h-4 mr-1" />
              {currentError}
            </div>
          )}

          {/* Tip */}
          <div className="mt-4 bg-blue-50 p-3 rounded-lg text-sm text-blue-700 flex items-start">
            <div className="mr-2">💡</div>
            <div>{currentStep.tip}</div>
          </div>
        </div>
      </div>

      {/* Navigation buttons */}
      <div className="flex justify-between">
        <button
          onClick={() => setStep(prev => prev - 1)}
          disabled={step === 1}
          className={`px-4 py-2 rounded ${
            step === 1 
              ? 'bg-gray-200 cursor-not-allowed' 
              : 'bg-gray-500 text-white hover:bg-gray-600'
          }`}
        >
          Back
        </button>
        <button
          onClick={() => {
            if (step < 3) {
              setStep(prev => prev + 1);
            } else if (images.complete && images.progress && images.remaining) {
              onImagesUpload({
                completeImage: images.complete,
                progressImage: images.progress,
                remainingPieces: images.remaining
              });
            }
          }}
          disabled={!images[currentStep.key] || currentError}
          className={`px-4 py-2 rounded flex items-center ${
            images[currentStep.key] && !currentError
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-200 cursor-not-allowed'
          }`}
        >
          {step === 3 ? 'Start Analysis' : 'Next'}
          <ArrowRight className="ml-2 w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

// Helper functions
const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(new Error("Failed to read file"));
    reader.readAsDataURL(file);
  });
};

const validateImageDimensions = (dataUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width < 100 || img.height < 100) {
        reject(new Error("Image dimensions too small"));
      } else if (img.width > 4096 || img.height > 4096) {
        reject(new Error("Image dimensions too large"));
      } else {
        resolve();
      }
    };
    img.onerror = () => reject(new Error("Failed to load image"));
    img.src = dataUrl;
  });
};

const validateImageType = (file) => {
  return new Promise((resolve, reject) => {
    // Check if the file is actually an image
    if (!file.type.startsWith('image/')) {
      reject(new Error('File must be an image'));
      return;
    }
    
    // Create a temporary URL for the file
    const url = URL.createObjectURL(file);
    const img = new Image();
    
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve({
        width: img.width,
        height: img.height,
        aspectRatio: img.width / img.height
      });
    };
    
    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Invalid image file'));
    };
    
    img.src = url;
  });
};

export default PuzzleUploader;