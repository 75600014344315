// src/pages/sudoku/SudokuSolver.js
import React, { useState, useEffect } from 'react';
import { loadModel, trainOnUserData } from '../../utils/sudoku/modelLoader';
import SudokuInterface from '../../components/sudoku/SudokuInterface';
import { Card } from '../../components/ui/basic';

const SudokuSolver = () => {
  const [modelStatus, setModelStatus] = useState('loading');
  const [error, setError] = useState(null);

  useEffect(() => {
    loadModel()
      .then(() => setModelStatus('ready'))
      .catch(err => {
        console.error('Failed to load model:', err);
        setError(err.message);
        setModelStatus('error');
      });
  }, []);

  useEffect(() => {
    // Check for training data periodically
    const trainInterval = setInterval(async () => {
      await trainOnUserData();
    }, 24 * 60 * 60 * 1000); // Once per day

    return () => clearInterval(trainInterval);
  }, []);

  if (modelStatus === 'loading') {
    return (
      <Card className="p-4">
        <div className="text-center">
          <p>Loading recognition model...</p>
        </div>
      </Card>
    );
  }

  if (modelStatus === 'error') {
    return (
      <Card className="p-4">
        <div className="text-center text-red-600">
          <p>Failed to load recognition model</p>
          <p className="text-sm mt-2">{error}</p>
        </div>
      </Card>
    );
  }

  return (
    <div className="container mx-auto py-8 px-4">
      <SudokuInterface />
    </div>
  );
};

export default SudokuSolver;