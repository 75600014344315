// src/pages/common/AboutPage.js

import React from 'react';
import { Wrench, Brain, Code, Heart, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About Puzzle Solver</h1>
        <p className="text-xl text-gray-500">
          Using technology to make puzzle solving more enjoyable
        </p>
      </div>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-12">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col items-center mb-4">
            <Wrench className="h-12 w-12 text-blue-500 mb-2" />
            <h2 className="text-xl font-semibold">How It Works</h2>
          </div>
          <p className="text-gray-600 text-center">
            Our puzzle solver uses advanced image processing and pattern matching
            algorithms to analyze puzzle pieces and suggest possible matches.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col items-center mb-4">
            <Brain className="h-12 w-12 text-blue-500 mb-2" />
            <h2 className="text-xl font-semibold">Technology</h2>
          </div>
          <p className="text-gray-600 text-center">
            Built with React, Tailwind CSS, and modern image processing techniques.
            We're constantly improving our algorithms to provide better suggestions.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col items-center mb-4">
            <Heart className="h-12 w-12 text-blue-500 mb-2" />
            <h2 className="text-xl font-semibold">Community</h2>
          </div>
          <p className="text-gray-600 text-center">
            Join our growing community of puzzle enthusiasts. Share tips, discuss strategies, and connect with fellow solvers. Together, we make puzzle-solving more fun and rewarding.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col items-center mb-4">
            <Mail className="h-12 w-12 text-blue-500 mb-2" />
            <h2 className="text-xl font-semibold">Support</h2>
          </div>
          <p className="text-gray-600 text-center">
            Found a bug or have a suggestion? We'd love to hear from you! 
            <Link to="/contact" className="text-blue-500 hover:text-blue-600 font-medium ml-1">
              Contact us
            </Link>
            {' '}with your ideas and feedback.
          </p>
        </div>
      </div>

      <div className="mt-12 text-center">
        <p className="text-gray-600 font-semibold">
          Crafted with dedication and expertise for puzzle enthusiasts worldwide.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;