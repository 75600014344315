// src/utils/analysisUtils.js

export const calculateEdgeCompatibility = (piece, surroundingPiece, direction) => {
  const edgeMapping = {
    top: { source: 'top', target: 'bottom' },
    right: { source: 'right', target: 'left' },
    bottom: { source: 'bottom', target: 'top' },
    left: { source: 'left', target: 'right' }
  };

  if (!edgeMapping[direction]) return 0;

  const { source, target } = edgeMapping[direction];
  const sourceEdge = piece.edgeProfile?.[source];
  const targetEdge = surroundingPiece.edgeProfile?.[target];

  if (!sourceEdge || !targetEdge) return 0.5;

  const hasKnob = piece.knobs?.[source];
  const hasHole = surroundingPiece.holes?.[target];
  
  if (hasKnob !== undefined && hasHole !== undefined) {
    if (hasKnob === hasHole) return 0.2;
    if (hasKnob !== hasHole) return 0.8;
  }

  const colorSimilarity = compareEdgeColors(sourceEdge, targetEdge);
  return colorSimilarity;
};

export const findMatchingEdges = (piece, gap) => {
  if (!piece || !gap) return 0;

  const scores = {
    top: compareEdges(piece, gap, 'top'),
    right: compareEdges(piece, gap, 'right'),
    bottom: compareEdges(piece, gap, 'bottom'),
    left: compareEdges(piece, gap, 'left')
  };

  return Object.values(scores).reduce((sum, score) => sum + score, 0) / 4;
};

export const compareEdges = (piece, gap, edge) => {
  const pieceEdge = piece.edgeProfile?.[edge];
  const gapEdge = gap.edgeProfile?.[edge];

  if (!pieceEdge || !gapEdge) return 0.5;

  const shapeSimilarity = compareEdgeShapes(pieceEdge, gapEdge);
  const colorSimilarity = compareEdgeColors(pieceEdge, gapEdge);

  return (shapeSimilarity * 0.6 + colorSimilarity * 0.4);
};

export const compareEdgeShapes = (edge1, edge2) => {
  if (!edge1 || !edge2 || !edge1.shape || !edge2.shape) return 0.5;

  let similarity = 0;
  const points = Math.min(edge1.shape.length, edge2.shape.length);

  for (let i = 0; i < points; i++) {
    const diff = Math.abs(edge1.shape[i] - edge2.shape[i]);
    similarity += 1 - Math.min(diff / 10, 1);
  }

  return similarity / points || 0; // Added fallback for empty array
};

export const compareEdgeColors = (edge1, edge2) => {
  if (!edge1 || !edge2 || !edge1.colors || !edge2.colors) return 0.5;

  let similarity = 0;
  const points = Math.min(edge1.colors.length, edge2.colors.length);

  for (let i = 0; i < points; i++) {
    const color1 = edge1.colors[i];
    const color2 = edge2.colors[i];
    
    const colorDiff = Math.sqrt(
      Math.pow(color1.r - color2.r, 2) +
      Math.pow(color1.g - color2.g, 2) +
      Math.pow(color1.b - color2.b, 2)
    ) / 441.67;

    similarity += 1 - colorDiff;
  }

  return similarity / points || 0; // Added fallback for empty array
};

export const calculateColorSimilarity = (piece, gap) => {
  if (!piece.imageData || !gap.imageData) return 0;

  let similarity = 0;
  const totalPixels = piece.imageData.width * piece.imageData.height;

  for (let i = 0; i < totalPixels * 4; i += 4) {
    const p1r = piece.imageData.data[i];
    const p1g = piece.imageData.data[i + 1];
    const p1b = piece.imageData.data[i + 2];
    
    const p2r = gap.imageData.data[i];
    const p2g = gap.imageData.data[i + 1];
    const p2b = gap.imageData.data[i + 2];

    const pixelSimilarity = 1 - (
      Math.abs(p1r - p2r) + 
      Math.abs(p1g - p2g) + 
      Math.abs(p1b - p2b)
    ) / (3 * 255);

    similarity += pixelSimilarity;
  }

  return similarity / totalPixels;
};

export const findBestMatches = (gap, pieces, suggestedPlacements) => {
  if (!gap || !pieces || !suggestedPlacements) {
    return [];
  }

  const analyzedPlacements = suggestedPlacements
    .filter(p => p.puzzleX === gap.x && p.puzzleY === gap.y)
    .map(match => {
      const piece = pieces[match.pieceIndex];
      if (!piece) return null;

      const edgeMatches = findMatchingEdges(piece, gap);
      const colorSimilarity = calculateColorSimilarity(piece, gap);
      const contextScore = calculateContextScore(gap, piece);
      
      return {
        ...match,
        ...piece,
        confidence: calculateOverallConfidence({
          edge: edgeMatches,
          color: colorSimilarity,
          context: contextScore
        }),
        matchDetails: {
          edgeMatches,
          colorSimilarity,
          contextScore
        }
      };
    })
    .filter(Boolean) // Remove null values
    .sort((a, b) => b.confidence - a.confidence)
    .slice(0, 3);

  return analyzedPlacements;
};

export const calculateContextScore = (gap, piece) => {
  if (!gap || !piece) return 0;
  
  const surroundingPieces = gap.surroundingPieces || {};
  let score = 0;
  let count = 0;

  for (const [direction, surroundingPiece] of Object.entries(surroundingPieces)) {
    if (surroundingPiece) {
      score += calculateEdgeCompatibility(piece, surroundingPiece, direction);
      count++;
    }
  }

  return count > 0 ? score / count : 0.5;
};

export const calculateOverallConfidence = (scores) => {
  if (!scores) return 0;
  
  return (
    (scores.edge || 0) * 0.4 +
    (scores.color || 0) * 0.4 +
    (scores.context || 0) * 0.2
  );
};