// src/data/blog/categories.js
const categories = [
  {
    name: "Sudoku",
    slug: "sudoku", 
    count: 1
  },
  {
    name: "Advanced Techniques",
    slug: "advanced-techniques",
    count: 1
  },
  {
    name: "Tutorials",
    slug: "tutorials",
    count: 2
  },
  {
    name: "Puzzle Solving Tips",
    slug: "puzzle-solving-tips",
    count: 1
  },
  {
    name: "Jigsaw Strategies",
    slug: "jigsaw-strategies",
    count: 1
  }
];

// Helper function to get category by slug
const getCategoryBySlug = (slug) => {
  return categories.find(category => category.slug === slug);
};

module.exports = { categories, getCategoryBySlug };