import React, { useState, useEffect } from 'react';
import { Mail, Copy, ExternalLink } from 'lucide-react';

const ContactPage = () => {
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [attempts, setAttempts] = useState(0);
  
  // Enhanced email encoding with rotation and splitting
  const encodedEmail = {
    p1: btoa('hello').split('').map(c => 
      String.fromCharCode(c.charCodeAt(0) + 1)).join(''),
    p2: btoa('solveapuzzle.com').split('').map(c => 
      String.fromCharCode(c.charCodeAt(0) + 2)).join(''),
    delimiter: Math.random().toString(36).substring(7)
  };

  // Reset attempts after cooling period
  useEffect(() => {
    const timer = setTimeout(() => {
      if (attempts > 0) setAttempts(0);
    }, 300000); // 5 minutes
    return () => clearTimeout(timer);
  }, [attempts]);

  const getDecodedEmail = () => {
    const p1 = atob(
      encodedEmail.p1.split('').map(c => 
        String.fromCharCode(c.charCodeAt(0) - 1)
      ).join('')
    );
    
    const p2 = atob(
      encodedEmail.p2.split('').map(c => 
        String.fromCharCode(c.charCodeAt(0) - 2)
      ).join('')
    );
    
    return `${p1}@${p2}`;
  };

  const handleShowEmail = () => {
    const now = Date.now();
    
    // Rate limiting
    if (now - lastClickTime < 2000) { // 2 second cooldown
      setAttempts(prev => prev + 1);
      if (attempts >= 3) {
        // Show CAPTCHA or temporary block after 3 rapid attempts
        return;
      }
      return;
    }
    
    setLastClickTime(now);
    setIsEmailVisible(true);
  };

  const handleCopyEmail = async () => {
    try {
      // Add random delay before copy (100-300ms)
      await new Promise(resolve => 
        setTimeout(resolve, 100 + Math.random() * 200)
      );
      await navigator.clipboard.writeText(getDecodedEmail());
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Honeypot field - hidden from real users
  const honeypotStyle = {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    zIndex: -1,
  };

  return (
    <div className="max-w-2xl mx-auto py-12 px-4">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Contact Us</h1>
      
      {/* Honeypot field */}
      <input 
        type="text"
        name="website"
        style={honeypotStyle}
        tabIndex="-1"
        aria-hidden="true"
      />
      
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <p className="text-gray-600 mb-6">
          Have a feature suggestion or found a bug? We'd love to hear from you!
        </p>

        <div className="space-y-6 text-center">
          {!isEmailVisible ? (
            <button
              onClick={handleShowEmail}
              className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors inline-flex items-center gap-2"
              onMouseEnter={(e) => {
                e.target.style.transitionDelay = `${Math.random() * 0.2}s`;
              }}
              // Disable button during cooldown
              disabled={Date.now() - lastClickTime < 2000}
            >
              <Mail className="w-5 h-5" />
              Show Email Address
            </button>
          ) : (
            <div 
              className="space-y-4"
              onContextMenu={(e) => e.preventDefault()}
              // Prevent drag
              onDragStart={(e) => e.preventDefault()}
              // Prevent copy except through button
              onCopy={(e) => e.preventDefault()}
            >
              <div className="flex items-center justify-center gap-2 text-lg">
                <Mail className="w-5 h-5 text-blue-500" />
                <span 
                  className="text-gray-700 select-none"
                  onSelectCapture={(e) => e.preventDefault()}
                >
                  {getDecodedEmail()}
                </span>
                <button
                  onClick={handleCopyEmail}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="Copy email address"
                >
                  <Copy className="w-5 h-5 text-gray-500" />
                </button>
              </div>
              {isCopied && (
                <p className="text-green-600 text-sm animate-fade-in-out">
                  ✓ Email copied to clipboard!
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50 rounded-lg p-6">
        <div className="text-center space-y-4">
          <p className="text-gray-600">
            We typically respond within 24-48 hours during business days.
          </p>
          <div className="flex items-center justify-center gap-4">
            <a
              href="https://uk.pinterest.com/solveapuzzle/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0a12 12 0 0 0-4.373 23.178c-.064-.572-.122-1.448.026-2.073.134-.569.87-3.622.87-3.622s-.222-.446-.222-1.104c0-1.034.6-1.806 1.348-1.806.635 0 .942.477.942 1.05 0 .64-.408 1.597-.62 2.484-.176.744.374 1.352 1.11 1.352 1.332 0 2.355-1.404 2.355-3.43 0-1.793-1.291-3.048-3.13-3.048-2.137 0-3.392 1.602-3.392 3.258 0 .644.247 1.337.557 1.716.061.074.07.139.052.213-.057.24-.187.758-.213.864-.033.138-.112.168-.258.101-.96-.447-1.564-1.86-1.564-3.004 0-2.44 1.773-4.68 5.112-4.68 2.684 0 4.773 1.916 4.773 4.474 0 2.669-1.682 4.82-4.013 4.82-.782 0-1.517-.406-1.768-.889 0 0-.387 1.476-.48 1.838-.176.676-.63 1.518-.941 2.034A12 12 0 1 0 12 0z"/>
              </svg>
              Follow us on Pinterest
              <ExternalLink className="w-4 h-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;