// src/components/sudoku/NumberPad.js
import React from 'react';
import { X } from 'lucide-react';

const NumberPad = ({ onNumberSelect, onClear, selectedNumber }) => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-3 gap-3">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <button
            key={number}
            onClick={() => onNumberSelect(number)}
            className={`
              w-14 h-14 rounded-lg bg-white border border-gray-100
              text-blue-600 hover:bg-blue-50 active:bg-blue-100
              text-lg font-medium transition-all
              ${selectedNumber === number && 'bg-blue-500 text-white'}
            `}
          >
            {number}
          </button>
        ))}
      </div>

      <button
        onClick={onClear}
        className="w-full py-3 px-4 rounded-lg bg-red-100 text-red-600 
          hover:bg-red-200 transition-colors flex items-center justify-center gap-2"
      >
        <X className="w-4 h-4" />
        Clear
      </button>
    </div>
  );
};

export default NumberPad;