// src/hooks/sudoku/useSudokuSolver.js
import { useState, useCallback } from 'react';
import { findNextMove } from '../../utils/sudoku/logicSolver';
import { 
  isValidGrid, 
  isValidPlacement 
} from '../../utils/sudoku/gridAnalysis';
import { 
  canPlaceNumber, 
  getPossibleNumbers,
  getRelatedNumbers 
} from '../../utils/sudoku/helpers';
import { SOLVING_TECHNIQUES } from '../../utils/constants/sudoku/constants';

const createEmptyGrid = () => Array(9).fill().map(() => Array(9).fill(0));

export const useSudokuSolver = () => {
  const [grid, setGrid] = useState(createEmptyGrid());
  const [selectedCell, setSelectedCell] = useState(null);
  const [highlightedCells, setHighlightedCells] = useState([]);
  const [currentHint, setCurrentHint] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const getRelatedFilledCells = useCallback((row, col, num) => {
    const related = [];
    
    if (row === undefined || col === undefined) return related;
    
    // Add cells in same row/column/box that block other numbers
    for (let i = 0; i < 9; i++) {
      if (grid[row]?.[i] !== 0) {
        related.push({ row, col: i, type: 'conflict' });
      }
      if (grid[i]?.[col] !== 0) {
        related.push({ row: i, col, type: 'conflict' });
      }
    }
    
    // Add cells in same 3x3 box
    const boxRow = Math.floor(row / 3) * 3;
    const boxCol = Math.floor(col / 3) * 3;
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (grid[boxRow + i]?.[boxCol + j] !== 0) {
          related.push({ 
            row: boxRow + i, 
            col: boxCol + j, 
            type: 'conflict' 
          });
        }
      }
    }
    
    return related;
  }, [grid]);

  const findSingleCandidate = useCallback(() => {
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (grid[row]?.[col] === 0) {
          const possibilities = getPossibleNumbers(grid, row, col);
          if (possibilities.length === 1) {
            return {
              technique: SOLVING_TECHNIQUES.SINGLE_CANDIDATE.id,
              row,
              col,
              number: possibilities[0],
              explanation: `This cell can only be ${possibilities[0]} because all other numbers (1-9) are already present in its row, column, or 3x3 box.`,
              relatedCells: getRelatedFilledCells(row, col, possibilities[0])
            };
          }
        }
      }
    }
    return null;
  }, [grid, getRelatedFilledCells]);

  const findSinglePosition = useCallback(() => {
    // Check each 3x3 box
    for (let boxRow = 0; boxRow < 9; boxRow += 3) {
      for (let boxCol = 0; boxCol < 9; boxCol += 3) {
        // Try each number 1-9
        for (let num = 1; num <= 9; num++) {
          let possiblePositions = [];
          
          // Check if number already exists in box
          let exists = false;
          for (let i = 0; i < 3 && !exists; i++) {
            for (let j = 0; j < 3 && !exists; j++) {
              if (grid[boxRow + i]?.[boxCol + j] === num) {
                exists = true;
              }
            }
          }
          
          if (!exists) {
            // Find all possible positions for this number in the box
            for (let i = 0; i < 3; i++) {
              for (let j = 0; j < 3; j++) {
                const row = boxRow + i;
                const col = boxCol + j;
                if (grid[row]?.[col] === 0 && canPlaceNumber(grid, row, col, num)) {
                  possiblePositions.push({ row, col });
                }
              }
            }
            
            // If only one position is possible
            if (possiblePositions.length === 1) {
              const { row, col } = possiblePositions[0];
              return {
                technique: SOLVING_TECHNIQUES.SINGLE_POSITION.id,
                row,
                col,
                number: num,
                explanation: `${num} can only go in this cell within this 3x3 box because it's blocked by existing numbers in other positions.`,
                relatedCells: getRelatedFilledCells(row, col, num)
              };
            }
          }
        }
      }
    }
    return null;
  }, [grid, getRelatedFilledCells]);

  const handleUndo = useCallback(() => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setGrid(history[historyIndex - 1]);
    }
  }, [history, historyIndex]);

  const handleRedo = useCallback(() => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setGrid(history[historyIndex + 1]);
    }
  }, [history, historyIndex]);

  const updateCell = useCallback((row, col, value) => {
    if (row === undefined || col === undefined || value === undefined) return;

    setGrid(prevGrid => {
      const newGrid = prevGrid.map(r => [...r]);
      if (newGrid[row] && typeof value === 'number') {
        newGrid[row][col] = value;
      }
      
      // Add to history
      const newHistory = history.slice(0, historyIndex + 1);
      newHistory.push(newGrid);
      setHistory(newHistory);
      setHistoryIndex(historyIndex + 1);
      
      // Clear validation errors when cell is updated
      setValidationErrors([]);
      
      // Check for immediate conflicts
      if (value !== 0) {
        const conflicts = [];
        
        // Check row
        for (let x = 0; x < 9; x++) {
          if (x !== col && newGrid[row][x] === value) {
            conflicts.push({
              type: 'row',
              cells: [
                { row, col },
                { row, col: x }
              ],
              message: `${value} appears multiple times in row ${row + 1}`
            });
          }
        }
        
        // Check column
        for (let y = 0; y < 9; y++) {
          if (y !== row && newGrid[y][col] === value) {
            conflicts.push({
              type: 'column',
              cells: [
                { row, col },
                { row: y, col }
              ],
              message: `${value} appears multiple times in column ${col + 1}`
            });
          }
        }
        
        // Check box
        const boxRow = Math.floor(row / 3) * 3;
        const boxCol = Math.floor(col / 3) * 3;
        for (let y = 0; y < 3; y++) {
          for (let x = 0; x < 3; x++) {
            const r = boxRow + y;
            const c = boxCol + x;
            if ((r !== row || c !== col) && newGrid[r][c] === value) {
              conflicts.push({
                type: 'box',
                cells: [
                  { row, col },
                  { row: r, col: c }
                ],
                message: `${value} appears multiple times in this 3x3 box`
              });
            }
          }
        }
        
        if (conflicts.length > 0) {
          setValidationErrors(conflicts);
          setHighlightedCells(conflicts.flatMap(conflict => 
            conflict.cells.map(cell => ({ ...cell, type: 'error' }))
          ));
        }
      }
      
      return newGrid;
    });
    setCurrentHint(null);
  }, [history, historyIndex]);

  const selectCell = useCallback((row, col) => {
    if (row === undefined || col === undefined) return;
    setSelectedCell({ row, col });
    setHighlightedCells(getRelatedFilledCells(row, col));
  }, [getRelatedFilledCells]);

  const getHint = useCallback(() => {
    const hint = findNextMove(grid);
    if (hint) {
      const formattedHint = {
        ...hint,
        row: hint.row ?? undefined,
        col: hint.col ?? undefined,
        number: hint.number ?? undefined,
        technique: hint.technique ?? 'basic',
        explanation: hint.explanation ?? `Place ${hint.number} in this cell`
      };
      
      setCurrentHint(formattedHint);
      setHighlightedCells(
        hint.highlightCells || 
        (hint.row !== undefined ? [{ row: hint.row, col: hint.col, type: 'hint' }] : [])
      );
      return formattedHint;
    }
    return null;
  }, [grid]);

  const applyHint = useCallback((hint) => {
    // Handle standard hints with direct row/col/number
    if (hint && typeof hint.row === 'number' && 
        typeof hint.col === 'number' && 
        typeof hint.number === 'number' &&
        grid[hint.row]?.[hint.col] !== undefined) {
      updateCell(hint.row, hint.col, hint.number);
      setSelectedCell({ row: hint.row, col: hint.col });
      return;
    }

    // Handle technique-specific hints like X-Wing
    if (hint && hint.technique && typeof hint.number === 'number') {
      // For X-Wing, we get the highlighted cells and apply the number to the appropriate cell
      const cellToUpdate = hint.highlightCells?.find(cell => cell.type === 'target') || 
                          (selectedCell && grid[selectedCell.row]?.[selectedCell.col] === 0 ? selectedCell : null);
      
      if (cellToUpdate) {
        updateCell(cellToUpdate.row, cellToUpdate.col, hint.number);
        setSelectedCell(cellToUpdate);
      }
    }
  }, [grid, updateCell, selectedCell]);

  const resetGrid = useCallback(() => {
    const emptyGrid = createEmptyGrid();
    setGrid(emptyGrid);
    setSelectedCell(null);
    setHighlightedCells([]);
    setCurrentHint(null);
    setValidationErrors([]);
    setHistory([emptyGrid]);
    setHistoryIndex(0);
  }, []);

  const validateGrid = useCallback(() => {
    return isValidGrid(grid);
  }, [grid]);

  const setFullGrid = useCallback((newGrid) => {
    if (!newGrid || !Array.isArray(newGrid) || newGrid.length !== 9) return;
    
    const validatedGrid = newGrid.map(row => 
      Array.isArray(row) ? row.map(cell => 
        typeof cell === 'number' && cell >= 0 && cell <= 9 ? cell : 0
      ) : Array(9).fill(0)
    );
    
    setGrid(validatedGrid);
    setSelectedCell(null);
    setHighlightedCells([]);
    setCurrentHint(null);
    setValidationErrors([]);
    setHistory([validatedGrid]);
    setHistoryIndex(0);
  }, []);

  return {
    grid,
    selectedCell,
    highlightedCells,
    currentHint,
    validationErrors,
    updateCell,
    selectCell,
    getHint,
    applyHint,
    resetGrid,
    validateGrid,
    setFullGrid,
    setCurrentHint,
    setHighlightedCells,
    setValidationErrors,
    canUndo: historyIndex > 0,
    canRedo: historyIndex < history.length - 1,
    handleUndo,
    handleRedo
  };
};