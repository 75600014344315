// src/components/jigsaw/PuzzleInterface.js

import React, { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ZoomIn, 
  ZoomOut, 
  RotateCw, 
  Lock, 
  Maximize2, 
  Layers, 
  PuzzleIcon, 
  Move 
} from 'lucide-react';

import { findBestMatches, analyzePuzzleStructure } from '../../utils/jigsaw/solverUtils';
import { THRESHOLDS } from '../../utils/constants/jigsaw/puzzleConstants';

const detectKnobs = (piece) => {
  const knobs = {
    top: false,
    right: false,
    bottom: false,
    left: false
  };

  const threshold = 5;
  
  for (let edge of ['top', 'right', 'bottom', 'left']) {
    const edgePoints = piece.edges?.[edge] || [];
    const avgHeight = edgePoints.reduce((sum, p) => sum + p.y, 0) / edgePoints.length;
    knobs[edge] = edgePoints.some(p => Math.abs(p.y - avgHeight) > threshold);
  }

  return knobs;
};

const detectHoles = (piece) => {
  const holes = {
    top: false,
    right: false,
    bottom: false,
    left: false
  };

  const threshold = -5;
  
  for (let edge of ['top', 'right', 'bottom', 'left']) {
    const edgePoints = piece.edges?.[edge] || [];
    const avgHeight = edgePoints.reduce((sum, p) => sum + p.y, 0) / edgePoints.length;
    holes[edge] = edgePoints.some(p => Math.abs(p.y - avgHeight) < threshold);
  }

  return holes;
};

const getSamplePoint = (piece, edge, position, length) => {
  const { minX, minY, maxX, maxY } = piece;
  
  switch (edge) {
    case 'top':
      return {
        x: minX + position * length,
        y: minY
      };
    case 'right':
      return {
        x: maxX,
        y: minY + position * length
      };
    case 'bottom':
      return {
        x: minX + position * length,
        y: maxY
      };
    case 'left':
      return {
        x: minX,
        y: minY + position * length
      };
    default:
      return null;
  }
};

const compareEdges = (edge1, edge2) => {
  if (!edge1 || !edge2 || edge1.length !== edge2.length) return 0;

  let similarity = 0;
  const points = edge1.length;

  for (let i = 0; i < points; i++) {
    const point1 = edge1[i];
    const point2 = edge2[i];
    
    const distance = Math.sqrt(
      Math.pow(point1.x - point2.x, 2) + 
      Math.pow(point1.y - point2.y, 2)
    );
    
    similarity += 1 - Math.min(distance / 10, 1);
  }

  return similarity / points;
};

const PuzzleInterface = ({ processedData, highlightedGap, highlightedPieces }) => {
  const [selectedGap, setSelectedGap] = useState(null);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [previewRotation, setPreviewRotation] = useState(0);
  const [activeTab, setActiveTab] = useState('workspace');
  const [isDragging, setIsDragging] = useState(false);
  const [tool, setTool] = useState('select'); // 'select' | 'pan'
  const containerRef = useRef(null);

  const calculateEdgeFeatures = (piece) => {
    const { minX, maxX, minY, maxY } = piece;
    const width = maxX - minX;
    const height = maxY - minY;
    
    return {
      edges: {
        top: getEdgePattern(piece, 'top', width),
        right: getEdgePattern(piece, 'right', height),
        bottom: getEdgePattern(piece, 'bottom', width),
        left: getEdgePattern(piece, 'left', height)
      },
      knobs: detectKnobs(piece),
      holes: detectHoles(piece)
    };
  };

  const getEdgePattern = (piece, edge, length) => {
    const samples = [];
    const sampleCount = 10;
    
    for (let i = 0; i < sampleCount; i++) {
      const position = i / (sampleCount - 1);
      const sample = getSamplePoint(piece, edge, position, length);
      samples.push(sample);
    }
    
    return samples;
  };

  const findMatchingEdges = (piece1, piece2) => {
    const features1 = calculateEdgeFeatures(piece1);
    const features2 = calculateEdgeFeatures(piece2);
    
    return {
      topBottom: compareEdges(features1.edges.bottom, features2.edges.top),
      rightLeft: compareEdges(features1.edges.right, features2.edges.left),
      bottomTop: compareEdges(features1.edges.top, features2.edges.bottom),
      leftRight: compareEdges(features1.edges.left, features2.edges.right)
    };
  };

  const findBestMatch = (piece, gap, referenceAnalysis) => {
    const match = processedData.suggestedPlacements.find(
      placement => placement.pieceIndex === piece.index &&
      placement.puzzleX === gap.x &&
      placement.puzzleY === gap.y
    );
    return match || { rotation: 0, confidence: 0 };
  };

  const confirmPlacement = (piece, gap, rotation) => {
    console.log('Confirming placement:', { piece, gap, rotation });
  };

  const handleGapClick = useCallback((gap) => {
    if (tool === 'select') {
      setSelectedGap(gap);
      const suggestions = processedData.suggestedPlacements.filter(
        placement => placement.puzzleX === gap.x && placement.puzzleY === gap.y
      );
      setSelectedPiece(null);
      setPreviewRotation(0);
    }
  }, [processedData, tool]);

  const handlePieceClick = useCallback((piece) => {
    if (tool === 'select') {
      setSelectedPiece(piece);
      if (selectedGap) {
        const match = findBestMatch(piece, selectedGap, processedData.referenceAnalysis);
        setPreviewRotation(match.rotation);
      }
    }
  }, [selectedGap, processedData, tool]);

  const handleZoomIn = useCallback(() => {
    setZoomLevel(prev => Math.min(prev + 0.1, 2));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
  }, []);

  const handleZoomReset = useCallback(() => {
    setZoomLevel(1);
  }, []);

  const renderGapOverlay = () => {
    if (!processedData?.gaps) return null;

    return processedData.gaps.map((gap, index) => (
      <motion.div
        key={`gap-${index}`}
        className={`absolute cursor-pointer ${
          highlightedGap?.x === gap.x && highlightedGap?.y === gap.y
            ? 'border-4 border-green-500 bg-green-100 bg-opacity-30'
            : selectedGap === gap 
              ? 'border-4 border-blue-500' 
              : 'border-2 border-yellow-300'
        }`}
        style={{
          left: gap.x * zoomLevel,
          top: gap.y * zoomLevel,
          width: gap.width * zoomLevel,
          height: gap.height * zoomLevel,
        }}
        onClick={() => handleGapClick(gap)}
        whileHover={{ scale: 1.02 }}
      >
        {highlightedGap?.x === gap.x && highlightedGap?.y === gap.y && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-2 py-1 rounded text-sm">
            Best match: {Math.round(highlightedGap.confidence * 100)}% confidence
          </div>
        )}
      </motion.div>
    ));
  };

  const renderPiecePreviews = () => {
    if (!processedData?.pieceAnalysis) return null;

    return processedData.pieceAnalysis.map((piece, index) => (
      <motion.div
        key={`piece-${index}`}
        className={`relative cursor-pointer ${
          highlightedPieces.find(p => p.index === index)
            ? 'ring-4 ring-green-500'
            : selectedPiece === piece 
              ? 'ring-4 ring-blue-500' 
              : 'hover:ring-2 ring-gray-300'
        }`}
        onClick={() => handlePieceClick(piece)}
        whileHover={{ scale: 1.05 }}
      >
        <img
          src={piece.imageUrl}
          alt={`Piece ${index + 1}`}
          className="w-24 h-24 object-contain"
          style={{
            transform: highlightedPieces.find(p => p.index === index)?.suggestedRotation
              ? `rotate(${highlightedPieces.find(p => p.index === index).suggestedRotation}deg)`
              : undefined
          }}
        />
        {highlightedPieces.find(p => p.index === index) && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-2 py-1 rounded text-sm">
            Suggested match: {Math.round(highlightedPieces.find(p => p.index === index).confidence * 100)}%
          </div>
        )}
      </motion.div>
    ));
  };

  return (
    <div className="h-[calc(100vh-12rem)] flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="flex justify-between border-b p-2">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setTool('select')}
            className={`p-2 rounded-lg ${
              tool === 'select' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
            }`}
          >
            <PuzzleIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => setTool('pan')}
            className={`p-2 rounded-lg ${
              tool === 'pan' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
            }`}
          >
            <Move className="w-5 h-5" />
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={handleZoomOut}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            <ZoomOut className="w-5 h-5" />
          </button>
          <span className="px-3 py-1 bg-gray-100 rounded-md">
            {Math.round(zoomLevel * 100)}%
          </span>
          <button
            onClick={handleZoomIn}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            <ZoomIn className="w-5 h-5" />
          </button>
          <button
            onClick={handleZoomReset}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            <Maximize2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="flex-1 relative overflow-hidden" ref={containerRef}>
        <motion.div
          className="relative"
          drag={tool === 'pan'}
          dragMomentum={false}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
        >
          <div style={{ transform: `scale(${zoomLevel})` }}>
            <img
              src={processedData?.progressDataUrl}
              alt="Current Progress"
              className="max-w-full"
              draggable={false}
            />
            {renderGapOverlay()}
          </div>
        </motion.div>

        <AnimatePresence>
          {selectedGap && tool === 'select' && (
            <motion.div
              className="fixed right-4 top-1/2 transform -translate-y-1/2 bg-white rounded-lg shadow-lg p-4 w-64"
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 100, opacity: 0 }}
            >
              <h3 className="text-lg font-semibold mb-2">Select a Piece</h3>
              <div className="grid grid-cols-2 gap-4">
                {renderPiecePreviews()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {selectedGap && selectedPiece && tool === 'select' && (
          <motion.div
            className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 w-80"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <h3 className="text-lg font-semibold mb-2">Match Preview</h3>
            <div className="relative aspect-square">
              <img
                src={processedData.referenceDataUrl}
                alt="Reference section"
                className="absolute top-0 left-0 w-full h-full object-cover opacity-50"
              />
              <motion.img
                src={selectedPiece.imageUrl}
                alt="Selected piece"
                className="absolute top-0 left-0 w-full h-full object-contain"
                animate={{ rotate: previewRotation }}
              />
            </div>
            <div className="mt-2 flex justify-between items-center">
              <button
                onClick={() => setPreviewRotation(prev => (prev + 90) % 360)}
                className="flex items-center text-sm text-gray-600 hover:text-blue-500"
              >
                <RotateCw className="w-4 h-4 mr-1" />
                Rotate
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                onClick={() => confirmPlacement(selectedPiece, selectedGap, previewRotation)}
              >
                Confirm Placement
              </button>
            </div>
          </motion.div>
        )}
      </div>

      <div className="bg-white border-t p-2 text-sm text-gray-600">
        {isDragging ? 'Moving...' : `Zoom: ${Math.round(zoomLevel * 100)}% | Tool: ${tool}`}
      </div>
    </div>
  );
};

export default PuzzleInterface;