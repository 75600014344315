import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { 
  Wand2, Save, RotateCcw, Sliders, Share2, Download, 
  Info, Lock, Unlock, CheckCircle2, Timer, Settings2,
  FolderOpen
} from 'lucide-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import GridInput from '../../components/sudoku/GridInput';
import NumberPad from '../../components/sudoku/NumberPad';
import { useSudokuSolver } from '../../hooks/sudoku/useSudokuSolver';
import { GenerationProgress } from '../../components/sudoku/GenerationProgress';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { generatePuzzle as generateSudokuPuzzle } from '../../utils/sudoku/generator/simplePuzzleGenerator';

const LoadingOverlay = ({ isVisible }) => {
  if (!isVisible) return null;
  
  return (
    <div className="absolute inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="flex flex-col items-center gap-4">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
        <p className="text-blue-600 font-medium">Generating Puzzle...</p>
      </div>
    </div>
  );
};

const DifficultyBadge = ({ difficulty }) => {
  const colors = {
    easy: 'bg-green-100 text-green-800',
    medium: 'bg-blue-100 text-blue-800',
    hard: 'bg-orange-100 text-orange-800',
    expert: 'bg-red-100 text-red-800'
  };

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-medium ${colors[difficulty]}`}>
      {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}
    </span>
  );
};

const InfoTooltip = ({ children }) => (
  <div className="group relative inline-block">
    <Info className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
    <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 hidden group-hover:block bg-gray-900 text-white text-sm rounded px-2 py-1 w-48">
      {children}
    </div>
  </div>
);

const SudokuGeneratorMeta = () => (
  <HelmetProvider>
    <Helmet>
      <title>Sudoku Puzzle Generator - Create Custom Sudoku Games</title>
      <meta name="description" content="Create, customize, and share Sudoku puzzles of varying difficulties. Generate puzzles with adjustable settings and unique solutions." />
      <meta name="keywords" content="sudoku generator, puzzle creator, sudoku maker, custom sudoku, puzzle generator" />
      
      {/* Open Graph / Social Media */}
      <meta property="og:title" content="Sudoku Puzzle Generator" />
      <meta property="og:description" content="Create and share custom Sudoku puzzles with adjustable difficulty levels." />
      <meta property="og:type" content="website" />
      
      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "Sudoku Puzzle Generator",
          "description": "Create and share custom Sudoku puzzles",
          "applicationCategory": "Game",
          "operatingSystem": "Any",
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
          }
        })}
      </script>
    </Helmet>
  </HelmetProvider>
);

const DifficultySelector = ({ difficulty, onSelect }) => {
  const difficulties = ['Easy', 'Medium', 'Hard', 'Expert'];
  
  return (
    <div className="flex gap-2">
      {difficulties.map((level) => (
        <Button
          key={level}
          variant={difficulty === level.toLowerCase() ? "default" : "outline"}
          className="flex-1"
          onClick={() => onSelect(level.toLowerCase())}
        >
          {level}
        </Button>
      ))}
    </div>
  );
};

const SaveLoadSystem = ({ onSave, onLoad }) => {
  const handleSave = async (puzzle) => {
    const saveData = {
      puzzle,
      timestamp: new Date().toISOString(),
      version: '1.0'
    };

    // Save to local storage
    const savedPuzzles = JSON.parse(localStorage.getItem('savedPuzzles') || '[]');
    savedPuzzles.push(saveData);
    localStorage.setItem('savedPuzzles', JSON.stringify(savedPuzzles));

    // Save as file
    const blob = new Blob([JSON.stringify(saveData, null, 2)], { 
      type: 'application/json' 
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `sudoku-${Date.now()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleLoad = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = JSON.parse(event.target.result);
          onLoad(data);
        } catch (error) {
          console.error('Failed to load puzzle:', error);
        }
      };
      reader.readAsText(file);
    };
    input.click();
  };

  return (
    <div className="flex gap-2">
      <Button onClick={handleSave} className="flex items-center gap-2">
        <Save className="w-4 h-4" />
        Save Puzzle
      </Button>
      <Button onClick={handleLoad} variant="outline" className="flex items-center gap-2">
        <FolderOpen className="w-4 h-4" />
        Load Puzzle
      </Button>
    </div>
  );
};

const AdvancedSettings = ({ settings, onSettingsChange }) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-700">
          Symmetry
        </label>
        <select 
          value={settings.symmetry} 
          onChange={(e) => onSettingsChange('symmetry', e.target.value)}
          className="border rounded-md p-1 text-sm"
        >
          <option value="none">None</option>
          <option value="diagonal">Diagonal</option>
          <option value="central">Central</option>
        </select>
      </div>

      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-700">
          Min Given Numbers
        </label>
        <input 
          type="number" 
          min="17" 
          max="81" 
          value={settings.minGivens} 
          onChange={(e) => onSettingsChange('minGivens', parseInt(e.target.value))}
          className="border rounded-md p-1 w-20 text-sm"
        />
      </div>

      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-700">
          Unique Solution
        </label>
        <input 
          type="checkbox" 
          checked={settings.uniqueSolution} 
          onChange={(e) => onSettingsChange('uniqueSolution', e.target.checked)}
          className="rounded text-blue-600"
        />
      </div>
    </div>
  );
};

const GenerationStats = ({ stats }) => {
  if (!stats) return null;
  
  return (
    <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
      <div>
        <div className="text-sm text-gray-500">Generation Time</div>
        <div className="text-lg font-semibold">{stats.time}ms</div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Given Numbers</div>
        <div className="text-lg font-semibold">{stats.givenNumbers}</div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Difficulty Score</div>
        <div className="text-lg font-semibold">{stats.difficultyScore}</div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Attempts</div>
        <div className="text-lg font-semibold">{stats.attempts || 1}</div>
      </div>
      {stats.techniques && (
        <div className="col-span-2">
          <div className="text-sm text-gray-500 mb-1">Techniques Required</div>
          <div className="flex flex-wrap gap-2">
            {stats.techniques.map(technique => (
              <span key={technique} className="px-2 py-1 bg-blue-100 text-blue-700 rounded-full text-sm">
                {technique.replace('_', ' ')}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ValidationFeedback = ({ isValid, message }) => {
  if (!message) return null;

  return (
    <Alert variant={isValid ? "default" : "destructive"}>
      <AlertTitle>
        {isValid ? "Valid Puzzle" : "Invalid Puzzle"}
      </AlertTitle>
      <AlertDescription>
        {message}
      </AlertDescription>
    </Alert>
  );
};

const SudokuGenerator = () => {
  const [difficulty, setDifficulty] = useState('medium');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [validationMessage, setValidationMessage] = useState(null);
  const [generationStats, setGenerationStats] = useState(null);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [isGridLocked, setIsGridLocked] = useState(false);
  const [settings, setSettings] = useState({
    symmetry: 'central',
    minGivens: 25,
    uniqueSolution: true
  });
  
  const {
    grid,
    selectedCell,
    updateCell,
    selectCell,
    resetGrid,
    validateGrid,
    setFullGrid,
    solution
  } = useSudokuSolver();

  const handleSettingsChange = (key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const generatePuzzle = useCallback(async () => {
    setIsGenerating(true);
    setValidationMessage(null);
    const startTime = performance.now();
    
    try {
      // Start generation
      setGenerationProgress(5);
      console.log('Starting puzzle generation...');
      
      // Generate the puzzle with progress updates
      const result = await generateSudokuPuzzle({
        difficulty,
        symmetry: settings.symmetry,
        onProgress: (progress) => {
          setGenerationProgress(5 + Math.floor(progress * 90));
        }
      });
      
      // Validate result
      if (!result?.puzzle || !result?.solution) {
        throw new Error('Invalid puzzle structure generated');
      }

      // Set the puzzle in the grid
      setFullGrid(result.puzzle);
      
      // Update stats
      setGenerationProgress(100);
      setGenerationStats({
        time: Math.round(performance.now() - startTime),
        givenNumbers: result.stats.givenNumbers,
        difficulty: result.stats.difficulty,
        attempts: result.stats.attempts || 1
      });

      // Show success message
      setValidationMessage({
        isValid: true,
        message: `Successfully generated a ${difficulty} puzzle with ${result.stats.givenNumbers} given numbers`
      });

    } catch (error) {
      console.error('Generation error:', error);
      setValidationMessage({
        isValid: false,
        message: error.message || 'Failed to generate puzzle'
      });
    } finally {
      setIsGenerating(false);
      setGenerationProgress(0);
    }
  }, [difficulty, settings.symmetry, setFullGrid]);

  const validatePuzzle = useCallback(() => {
    try {
      const result = validateGrid();
      if (result.isValid) {
        setValidationMessage({
          isValid: true,
          message: "Puzzle is valid and has a unique solution"
        });
      } else {
        setValidationMessage({
          isValid: false,
          message: result.error
        });
      }
    } catch (error) {
      setValidationMessage({
        isValid: false,
        message: error.message
      });
    }
  }, [validateGrid]);

  const handleExport = useCallback(() => {
    try {
      const puzzleData = {
        grid,
        solution,
        difficulty,
        settings,
        stats: generationStats,
        timestamp: new Date().toISOString()
      };

      const blob = new Blob([JSON.stringify(puzzleData, null, 2)], {
        type: 'application/json'
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `sudoku-puzzle-${difficulty}-${Date.now()}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export puzzle:', error);
    }
  }, [grid, solution, difficulty, settings, generationStats]);

  const handleShare = useCallback(() => {
    const puzzleString = grid.flat().join('');
    const shareUrl = `${window.location.origin}/sudoku-solver?puzzle=${puzzleString}&difficulty=${difficulty}`;
    
    if (navigator.share) {
      navigator.share({
        title: 'Try this Sudoku puzzle!',
        text: `I created a ${difficulty} difficulty Sudoku puzzle. Can you solve it?`,
        url: shareUrl
      }).catch(console.error);
    } else {
      navigator.clipboard.writeText(shareUrl)
        .then(() => {
          setValidationMessage({
            isValid: true,
            message: "Puzzle URL copied to clipboard!"
          });
        })
        .catch(console.error);
    }
  }, [grid, difficulty]);

  const handleNumberSelect = useCallback((number) => {
    if (selectedCell && !isGridLocked) {
      updateCell(selectedCell.row, selectedCell.col, number);
    }
  }, [selectedCell, isGridLocked, updateCell]);

  const handleSavePuzzle = useCallback(() => {
    const puzzleData = {
      grid,
      solution,
      difficulty,
      settings,
      stats: generationStats
    };
    SaveLoadSystem.handleSave(puzzleData);
  }, [grid, solution, difficulty, settings, generationStats]);

  const handleLoadPuzzle = useCallback((data) => {
    if (data.grid) {
      setFullGrid(data.grid);
      setDifficulty(data.difficulty || 'medium');
      setSettings(data.settings || settings);
      setGenerationStats(data.stats || null);
    }
  }, [setFullGrid, settings]);

  return (
    <>
      <SudokuGeneratorMeta />
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <div className="max-w-7xl mx-auto p-4">
          <Card className="bg-white shadow-xl rounded-xl overflow-hidden">
            {/* Header remains the same */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-500 p-8">
              <div className="max-w-3xl">
                <h1 className="text-3xl font-bold text-white mb-2">
                  Sudoku Generator
                </h1>
                <p className="text-blue-100 text-lg">
                  Create and share your own Sudoku puzzles with custom difficulty levels
                </p>
              </div>
            </div>

            <div className="p-6 relative">
              {/* Loading Overlay */}
              <LoadingOverlay isVisible={isGenerating} />
              
              {/* Main content layout fix */}
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
                {/* Left Column - Controls */}
                <div className="lg:col-span-4 space-y-4">
                  {/* Generator Controls Card */}
                  <Card className="p-6">
                    {/* Difficulty Selection */}
                    <div className="mb-6">
                      <h3 className="text-lg font-medium mb-4">Difficulty</h3>
                      <div className="grid grid-cols-2 gap-2">
                        {['Easy', 'Medium', 'Hard', 'Expert'].map((level) => (
                          <button
                            key={level}
                            onClick={() => setDifficulty(level.toLowerCase())}
                            className={`px-4 py-2 rounded-lg transition-all ${
                              difficulty === level.toLowerCase()
                                ? 'bg-blue-600 text-white'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                          >
                            {level}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="grid grid-cols-2 gap-3 mb-6">
                      <button
                        onClick={generatePuzzle}
                        className="flex items-center justify-center gap-2 px-4 py-3
                          bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                      >
                        <Wand2 className="w-5 h-5" />
                        Generate
                      </button>
                      <button
                        onClick={() => setIsGridLocked(!isGridLocked)}
                        className="flex items-center justify-center gap-2 px-4 py-3
                          bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                      >
                        {isGridLocked ? <Lock className="w-5 h-5" /> : <Unlock className="w-5 h-5" />}
                        {isGridLocked ? 'Unlock' : 'Lock'}
                      </button>
                    </div>

                    {/* Share & Export Section */}
                    <div className="mb-6">
                      <h3 className="text-lg font-medium mb-4">Share & Export</h3>
                      <div className="grid grid-cols-2 gap-3">
                        <button 
                          onClick={handleShare}
                          className="flex items-center justify-center gap-2 px-4 py-3
                            bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                        >
                          <Share2 className="w-5 h-5" />
                          Share
                        </button>
                        <button 
                          onClick={handleExport}
                          className="flex items-center justify-center gap-2 px-4 py-3
                            bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                        >
                          <Download className="w-5 h-5" />
                          Export
                        </button>
                      </div>
                    </div>

                    {/* Save & Load Section */}
                    <div>
                      <h3 className="text-lg font-medium mb-4">Save & Load</h3>
                      <div className="grid grid-cols-2 gap-3">
                        <button 
                          onClick={handleSavePuzzle}
                          className="flex items-center justify-center gap-2 px-4 py-3
                            bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          <Save className="w-5 h-5" />
                          Save
                        </button>
                        <button 
                          onClick={handleLoadPuzzle}
                          className="flex items-center justify-center gap-2 px-4 py-3
                            bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                        >
                          <FolderOpen className="w-5 h-5" />
                          Load
                        </button>
                      </div>
                    </div>
                  </Card>

                  {/* Generation Progress */}
                  {isGenerating && (
                    <Card className="p-4">
                      <GenerationProgress progress={generationProgress} />
                    </Card>
                  )}

                  {/* Stats Display */}
                  {generationStats && (
                    <Card className="p-6">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-semibold">Generation Stats</h3>
                        <DifficultyBadge difficulty={difficulty} />
                      </div>
                      <GenerationStats stats={generationStats} />
                    </Card>
                  )}
                </div>

                {/* Right Column - Grid and Number Pad */}
                <div className="lg:col-span-8">
                  <div className="space-y-6">
                    {/* Grid Input */}
                    <div className="relative">
                      <GridInput
                        grid={grid}
                        onCellChange={isGridLocked ? undefined : updateCell}
                        selectedCell={selectedCell}
                        onCellSelect={isGridLocked ? undefined : selectCell}
                        highlightedCells={[]}
                        validationErrors={[]}
                      />
                      {isGridLocked && (
                        <div className="absolute inset-0 bg-black/5 cursor-not-allowed" />
                      )}
                    </div>

                    {/* Number Pad */}
                    <div className="relative">
                      <NumberPad
                        onNumberSelect={handleNumberSelect}
                        onClear={() => selectedCell && handleNumberSelect(0)}
                        selectedNumber={selectedCell ? grid[selectedCell.row][selectedCell.col] : null}
                        disabled={isGridLocked}
                      />
                      {isGridLocked && (
                        <div className="absolute inset-0 bg-black/5 cursor-not-allowed" />
                      )}
                    </div>

                    {/* Validation Feedback */}
                    {validationMessage && (
                      <ValidationFeedback
                        isValid={validationMessage.isValid}
                        message={validationMessage.message}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SudokuGenerator;