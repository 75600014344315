// src/utils/sudoku/techniques/xyWing.js
import { canPlaceNumber } from '../helpers';

// Export this function so test can use it
export const getCandidatesForCell = (grid, row, col) => {
    if (grid[row][col] !== 0) return [];
    
    // Get numbers already in the same row
    const rowNums = new Set(grid[row].filter(n => n !== 0));
    
    // Get numbers already in the same column
    const colNums = new Set(grid.map(r => r[col]).filter(n => n !== 0));
    
    // Get numbers in the same 3x3 box
    const boxRow = Math.floor(row / 3) * 3;
    const boxCol = Math.floor(col / 3) * 3;
    const boxNums = new Set();
    for (let r = boxRow; r < boxRow + 3; r++) {
        for (let c = boxCol; c < boxCol + 3; c++) {
            if (grid[r][c] !== 0) boxNums.add(grid[r][c]);
        }
    }
    
    // Find candidates
    const candidates = [];
    for (let num = 1; num <= 9; num++) {
        if (!rowNums.has(num) && !colNums.has(num) && !boxNums.has(num)) {
            candidates.push(num);
        }
    }
    
    return candidates;
};

// Export an alias for getPossibleNumbers for backwards compatibility
export const getPossibleNumbers = getCandidatesForCell;

export const shareUnit = (cell1, cell2) => {
    return (
        cell1.row === cell2.row || 
        cell1.col === cell2.col || 
        (Math.floor(cell1.row / 3) === Math.floor(cell2.row / 3) && 
         Math.floor(cell1.col / 3) === Math.floor(cell2.col / 3))
    );
};

const findBiValueCells = (grid) => {
    const cells = [];
    
    for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
            if (grid[row][col] === 0) {
                const candidates = getCandidatesForCell(grid, row, col);
                if (candidates.length === 2) {
                    cells.push({
                        row,
                        col,
                        candidates: candidates.sort((a, b) => a - b)
                    });
                    console.log(`Found bi-value cell at (${row + 1},${col + 1}): ${candidates.join('/')}`);
                }
            }
        }
    }
    
    return cells;
};

// Find cells that can see both wings
const findEliminationCells = (grid, wings, eliminationNumber) => {
  const eliminations = [];
  
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      // Skip wing cells
      if (wings.some(w => w.row === row && w.col === col)) {
        continue;
      }
      
      // Check if cell can see both wings and has the elimination number
      const seesWing1 = shareUnit({ row, col }, wings[0]);
      const seesWing2 = shareUnit({ row, col }, wings[1]);
      
      if (seesWing1 && seesWing2 && grid[row][col] === 0) {
        const candidates = getCandidatesForCell(grid, row, col);
        if (candidates.includes(eliminationNumber)) {
          eliminations.push({ row, col });
        }
      }
    }
  }
  
  return eliminations;
};

const isValidXYWing = (pivot, wing1, wing2) => {
  console.log('\nChecking potential XY-Wing:');
  console.log(`Pivot at (${pivot.row + 1},${pivot.col + 1}): ${pivot.candidates.join('/')}`);
  console.log(`Wing1 at (${wing1.row + 1},${wing1.col + 1}): ${wing1.candidates.join('/')}`);
  console.log(`Wing2 at (${wing2.row + 1},${wing2.col + 1}): ${wing2.candidates.join('/')}`);

  // Must share a unit with pivot
  if (!shareUnit(pivot, wing1) || !shareUnit(pivot, wing2)) {
    console.log('Failed: Wings do not share units with pivot');
    return false;
  }

  // Check candidate sharing
  const pivotSet = new Set(pivot.candidates);
  const wing1Common = wing1.candidates.filter(n => pivotSet.has(n));
  const wing2Common = wing2.candidates.filter(n => pivotSet.has(n));
  
  console.log(`Common with Wing1: ${wing1Common.join(',')}`);
  console.log(`Common with Wing2: ${wing2Common.join(',')}`);

  if (wing1Common.length !== 1 || wing2Common.length !== 1) {
    console.log('Failed: Wings do not share exactly one number each with pivot');
    return false;
  }

  if (wing1Common[0] === wing2Common[0]) {
    console.log('Failed: Wings share the same number with pivot');
    return false;
  }

  // Check wing common number
  const commonBetweenWings = wing1.candidates.filter(n => 
    wing2.candidates.includes(n) && !pivotSet.has(n)
  );
  
  console.log(`Common between wings (not in pivot): ${commonBetweenWings.join(',')}`);

  return commonBetweenWings.length === 1;
};

export const findXYWingPattern = (grid) => {
  if (!grid) {
    console.log('No grid provided to findXYWingPattern');
    return null;
  }

  console.log('\nSearching for XY-Wing pattern...');
  
  const biValueCells = findBiValueCells(grid);
  console.log(`Found ${biValueCells.length} bi-value cells`);

  // Try each bi-value cell as pivot
  for (const pivot of biValueCells) {
    // Try each pair of remaining cells as wings
    for (let i = 0; i < biValueCells.length; i++) {
      const wing1 = biValueCells[i];
      if (wing1 === pivot) continue;

      for (let j = i + 1; j < biValueCells.length; j++) {
        const wing2 = biValueCells[j];
        if (wing2 === pivot) continue;

        if (isValidXYWing(pivot, wing1, wing2)) {
          // Find common number between wings that's not in pivot
          const commonNumber = wing1.candidates.find(n => 
            wing2.candidates.includes(n) && !pivot.candidates.includes(n)
          );

          const eliminations = findEliminationCells(grid, [wing1, wing2], commonNumber);
          
          if (eliminations.length > 0) {
            return {
              technique: 'xy_wing',
              pivot: { ...pivot, type: 'pivot' },
              wings: [
                { ...wing1, type: 'wing' },
                { ...wing2, type: 'wing' }
              ],
              eliminations,
              eliminationNumber: commonNumber,
              highlightCells: [
                { ...pivot, type: 'pivot' },
                { ...wing1, type: 'wing' },
                { ...wing2, type: 'wing' },
                ...eliminations.map(cell => ({ ...cell, type: 'elimination' }))
              ]
            };
          }
        }
      }
    }
  }

  return null;
};