import React, { useState } from 'react';

const TechniqueDemo = ({ technique = 'hidden-single', isPractice = false }) => {
  const [grid, setGrid] = useState(isPractice ? [
    [0,0,5, 3,0,0, 0,0,0],
    [0,0,0, 0,0,5, 0,1,0],
    [0,1,0, 0,0,0, 0,0,8],
    
    [0,5,0, 0,3,0, 0,0,0],
    [0,0,1, 0,0,0, 4,0,0],
    [0,0,0, 0,7,0, 0,2,0],
    
    [3,0,0, 0,0,0, 0,6,0],
    [0,4,0, 1,0,0, 0,0,0],
    [0,0,0, 0,0,9, 8,0,0]
  ] : [
    [5,3,0, 0,7,0, 0,0,0],
    [6,0,0, 1,9,5, 0,0,0],
    [0,9,8, 0,0,0, 0,6,0],
    
    [8,0,0, 0,6,0, 0,0,3],
    [4,0,0, 8,0,3, 0,0,1],
    [7,0,0, 0,2,0, 0,0,6],
    
    [0,6,0, 0,0,0, 2,8,0],
    [0,0,0, 4,1,9, 0,0,5],
    [0,0,0, 0,8,0, 0,7,9]
  ]);

  const [highlightCells, setHighlightCells] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const hiddenSingleSteps = [
    {
      description: "First, look at row 1 (cells marked in blue)",
      highlights: [
        { row: 0, col: 2, type: 'focus' },
        { row: 0, col: 3, type: 'focus' },
        { row: 0, col: 6, type: 'focus' },
        { row: 0, col: 7, type: 'focus' },
        { row: 0, col: 8, type: 'focus' }
      ]
    },
    {
      description: "We already have 5, 3, and 7 in this row",
      highlights: [
        { row: 0, col: 0, type: 'existing' },
        { row: 0, col: 1, type: 'existing' },
        { row: 0, col: 4, type: 'existing' }
      ]
    },
    {
      description: "In the top-right box, we need to place 4",
      highlights: [
        { row: 0, col: 6, type: 'box' },
        { row: 0, col: 7, type: 'box' },
        { row: 0, col: 8, type: 'box' },
        { row: 1, col: 6, type: 'box' },
        { row: 1, col: 7, type: 'box' },
        { row: 1, col: 8, type: 'box' },
        { row: 2, col: 6, type: 'box' },
        { row: 2, col: 7, type: 'box' },
        { row: 2, col: 8, type: 'box' }
      ]
    },
    {
      description: "Due to the numbers in column 7 and 8, 4 can only go here! (marked in green)",
      highlights: [
        { row: 0, col: 6, type: 'solution' },
        { row: 7, col: 7, type: 'blocking' },
        { row: 7, col: 8, type: 'blocking' }
      ]
    }
  ];

  const steps = technique === 'hidden-single' ? hiddenSingleSteps : [];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      setHighlightCells(steps[currentStep + 1].highlights);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setHighlightCells(steps[currentStep - 1].highlights);
    }
  };

  const getCellClassName = (rowIndex, colIndex) => {
    const highlight = highlightCells.find(
      cell => cell.row === rowIndex && cell.col === colIndex
    );
    
    let className = "w-10 h-10 border flex items-center justify-center font-medium transition-all duration-300";
    
    // Add border styles
    if (rowIndex % 3 === 0) className += " border-t-2";
    if (colIndex % 3 === 0) className += " border-l-2";
    if (rowIndex === 8) className += " border-b-2";
    if (colIndex === 8) className += " border-r-2";
    
    // Add highlight styles
    if (highlight) {
      switch (highlight.type) {
        case 'focus':
          className += " bg-blue-100 shadow-inner";
          break;
        case 'solution':
          className += " bg-green-100 shadow-lg ring-2 ring-green-500";
          break;
        case 'existing':
          className += " bg-yellow-50";
          break;
        case 'box':
          className += " bg-gray-50";
          break;
        case 'blocking':
          className += " bg-red-50";
          break;
        default:
          break;
      }
    }

    return className;
  };

  if (isPractice) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 space-y-4">
        <h3 className="text-lg font-semibold">Practice Puzzle</h3>
        <p className="text-gray-600">This puzzle contains several Hidden Singles. Try to find them!</p>
        
        <div className="flex justify-center">
          <div className="grid grid-cols-9 border-2">
            {grid.map((row, rowIndex) => (
              row.map((cell, colIndex) => (
                <div 
                  key={`${rowIndex}-${colIndex}`} 
                  className={getCellClassName(rowIndex, colIndex)}
                >
                  {cell !== 0 ? cell : ''}
                </div>
              ))
            ))}
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded">
          <p className="text-sm text-blue-800">
            💡 Tip: Look carefully at row 2 and column 5 - there's a Hidden Single waiting to be found!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 space-y-4">
      <h3 className="text-lg font-semibold">Interactive Example</h3>
      
      <div className="flex justify-center">
        <div className="grid grid-cols-9 border-2">
          {grid.map((row, rowIndex) => (
            row.map((cell, colIndex) => (
              <div 
                key={`${rowIndex}-${colIndex}`} 
                className={getCellClassName(rowIndex, colIndex)}
              >
                {cell !== 0 ? cell : ''}
              </div>
            ))
          ))}
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded">
        <p className="text-gray-700 mb-4">{steps[currentStep]?.description}</p>
        
        <div className="mb-4 bg-gray-200 rounded-full h-2">
          <div 
            className="bg-blue-500 h-2 rounded-full transition-all duration-300"
            style={{ width: `${(currentStep + 1) / steps.length * 100}%` }}
          ></div>
        </div>
        
        <div className="flex justify-between items-center">
          <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 transition-colors flex items-center"
          >
            <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Previous
          </button>
          <span className="text-gray-500">Step {currentStep + 1} of {steps.length}</span>
          <button
            onClick={nextStep}
            disabled={currentStep === steps.length - 1}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 transition-colors flex items-center"
          >
            Next
            <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TechniqueDemo;