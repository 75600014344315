import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  ArrowLeft, 
  ArrowRight,
  Search,
  Grid3X3,
  Eye,
  Crosshair,
  Workflow,
  Binary,
  Network
} from 'lucide-react';

const Techniques = () => {
  const [activeLevel, setActiveLevel] = useState('beginner');

  const techniques = {
    beginner: [
      {
        icon: Grid3X3,
        title: "Single Candidate",
        description: "Find cells where only one number can legally be placed based on current puzzle state.",
        steps: [
          "Check empty cells in a region (row, column, or 3x3 box)",
          "List possible numbers for each cell",
          "Look for cells with only one possible number",
          "That number must go in that cell"
        ],
        example: "If a cell can only contain 4 based on the rules, then 4 is the answer."
      },
      {
        icon: Eye,
        title: "Hidden Singles",
        description: "Identify numbers that can only appear in one position within a row, column, or box.",
        steps: [
          "Look at all empty cells in a region",
          "List possible numbers for each cell",
          "Find numbers that only appear once in the lists",
          "That number must go in that cell"
        ],
        example: "If 7 can only go in one cell of a row, even if that cell has other possibilities, 7 must go there."
      }
    ],
    intermediate: [
      {
        icon: Crosshair,
        title: "X-Wing Pattern",
        description: "When a number appears in exactly two cells in two different rows/columns, creating elimination opportunities.",
        steps: [
          "Find a number that appears only twice in two different rows",
          "Check if these cells line up in the same columns",
          "The number must be in one of these positions in each row",
          "Eliminate the number from other cells in these columns"
        ],
        example: "If 4 appears only in columns 2 and 7 in rows 3 and 6, eliminate 4 from other cells in columns 2 and 7."
      },
      {
        icon: Workflow,
        title: "Y-Wing",
        description: "A three-cell pattern that allows for eliminations based on paired possibilities.",
        steps: [
          "Find a 'pivot' cell with two possibilities",
          "Find two 'wing' cells that share one possibility each with the pivot",
          "The wings must have different shared numbers",
          "Eliminate the common candidate from cells seeing both wings"
        ],
        example: "Detailed example with grid visualization"
      }
    ],
    advanced: [
      {
        icon: Binary,
        title: "Swordfish",
        description: "An extension of X-Wing involving three rows and columns.",
        steps: [
          "Find a candidate in three rows",
          "The candidate appears 2-3 times in each row",
          "These appearances align in three columns",
          "Eliminate the candidate from other cells in these columns"
        ],
        example: "Visual example with step-by-step elimination process"
      },
      {
        icon: Network,
        title: "XY-Chain",
        description: "A chain of bivalue cells that allows for eliminations.",
        steps: [
          "Find cells with exactly two candidates",
          "Connect cells that share a candidate",
          "Form a chain that starts and ends with the same number",
          "Eliminate that number from cells seeing both ends"
        ],
        example: "Interactive chain building demonstration"
      }
    ]
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      {/* Header */}
      <div className="mb-12">
        <div className="flex items-center gap-2 text-blue-600 mb-4">
          <Link to="/sudoku/docs" className="flex items-center gap-1 hover:underline">
            <ArrowLeft className="w-4 h-4" />
            Back to Docs
          </Link>
        </div>
        <h1 className="text-4xl font-bold mb-4">Solving Techniques</h1>
        <p className="text-xl text-gray-600 mb-8">
          Master advanced Sudoku solving strategies from basic to expert level.
        </p>

        {/* Level Selection */}
        <div className="flex space-x-2 mb-8">
          {['beginner', 'intermediate', 'advanced'].map((level) => (
            <button
              key={level}
              onClick={() => setActiveLevel(level)}
              className={`px-4 py-2 rounded-lg font-medium capitalize transition-colors
                ${activeLevel === level 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
            >
              {level}
            </button>
          ))}
        </div>
      </div>

      {/* Techniques Grid */}
      <div className="space-y-8">
        {techniques[activeLevel].map((technique, index) => (
          <div key={index} className="bg-white rounded-xl p-8 border border-gray-100 shadow-sm">
            <div className="flex items-start gap-4 mb-6">
              <div className="p-3 bg-blue-100 rounded-lg">
                <technique.icon className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h2 className="text-2xl font-bold mb-2">{technique.title}</h2>
                <p className="text-gray-600">{technique.description}</p>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {/* Steps */}
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">How to Apply</h3>
                <ol className="space-y-3">
                  {technique.steps.map((step, stepIndex) => (
                    <li key={stepIndex} className="flex items-start gap-2">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 
                        flex items-center justify-center font-medium">
                        {stepIndex + 1}
                      </span>
                      <span className="text-gray-600">{step}</span>
                    </li>
                  ))}
                </ol>
              </div>

              {/* Example */}
              <div className="bg-blue-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Example</h3>
                <p className="text-gray-600">{technique.example}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Footer */}
      <div className="mt-12 flex justify-between items-center">
        <Link 
          to="/sudoku/docs/getting-started"
          className="flex items-center gap-2 text-blue-600 hover:underline"
        >
          <ArrowLeft className="w-5 h-5" />
          Getting Started
        </Link>
        <Link 
          to="/sudoku/docs/tips"
          className="flex items-center gap-2 text-blue-600 hover:underline"
        >
          Tips & Best Practices
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  );
};

export default Techniques;