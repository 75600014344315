// src/utils/sudoku/gridAnalysis.js
import cv from '@techstark/opencv-js';

// Grid validation functions
export const isValidGrid = (grid) => {
  // Check if grid is complete and valid
  for (let i = 0; i < 9; i++) {
    // Check rows
    const rowNums = new Set();
    for (let j = 0; j < 9; j++) {
      if (grid[i][j] !== 0) {
        if (rowNums.has(grid[i][j])) return false;
        rowNums.add(grid[i][j]);
      }
    }

    // Check columns 
    const colNums = new Set();
    for (let j = 0; j < 9; j++) {
      if (grid[j][i] !== 0) {
        if (colNums.has(grid[j][i])) return false;
        colNums.add(grid[j][i]);
      }
    }

    // Check 3x3 boxes
    const boxRow = Math.floor(i / 3) * 3;
    const boxCol = (i % 3) * 3;
    const boxNums = new Set();
    for (let j = 0; j < 3; j++) {
      for (let k = 0; k < 3; k++) {
        const num = grid[boxRow + j][boxCol + k];
        if (num !== 0) {
          if (boxNums.has(num)) return false;
          boxNums.add(num);
        }
      }
    }
  }
  return true;
};

// Enhanced preprocessing for better line detection
const preprocessImage = (src) => {
  const preprocessed = new cv.Mat();
  const gray = new cv.Mat();
  
  try {
    // Convert to grayscale if needed
    if (src.channels() === 4) {
      cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
    } else {
      src.copyTo(gray);
    }

    // Apply adaptive histogram equalization
    const clahe = new cv.CLAHE(3.0, new cv.Size(8, 8));
    clahe.apply(gray, preprocessed);

    // Denoise
    cv.fastNlMeansDenoising(preprocessed, preprocessed);

    return preprocessed;
  } finally {
    gray.delete();
  }
};

// Enhanced grid detection with multiple strategies
export const detectGridLines = (src, debug = false, params = {}) => {
  const lines = new cv.Mat();
  const edges = new cv.Mat();
  const preprocessed = preprocessImage(src);
  
  try {
    // Apply multiple edge detection methods and combine results
    const edgeResults = [];
    
    // 1. Canny edge detection
    const cannyEdges = new cv.Mat();
    const mean = cv.mean(preprocessed)[0];
    const threshold1 = params.threshold1 || Math.max(mean * 0.5, 30);
    const threshold2 = params.threshold2 || Math.min(mean * 1.5, 200);
    cv.Canny(preprocessed, cannyEdges, threshold1, threshold2, 3, true);
    edgeResults.push(cannyEdges);

    // 2. Adaptive thresholding
    const adaptiveEdges = new cv.Mat();
    cv.adaptiveThreshold(
      preprocessed,
      adaptiveEdges,
      255,
      cv.ADAPTIVE_THRESH_GAUSSIAN_C,
      cv.THRESH_BINARY_INV,
      11,
      2
    );
    edgeResults.push(adaptiveEdges);

    // Combine edge detection results
    cv.add(cannyEdges, adaptiveEdges, edges);

    // Enhance edges
    const kernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(3, 3));
    cv.morphologyEx(edges, edges, cv.MORPH_CLOSE, kernel);
    kernel.delete();

    // Detect lines using probabilistic Hough transform
    cv.HoughLinesP(
      edges,
      lines,
      1,              // rho
      Math.PI / 180,  // theta
      params.threshold || 50,      // threshold
      params.minLineLength || 50,  // minLineLength
      params.maxLineGap || 10      // maxLineGap
    );

    // Cleanup edge detection results
    cannyEdges.delete();
    adaptiveEdges.delete();

    // Group and analyze lines
    const { horizontal, vertical } = groupLinesEnhanced(lines, src.size());
    const gridLines = {
      horizontal: clusterLinesEnhanced(horizontal, src.rows, 'y'),
      vertical: clusterLinesEnhanced(vertical, src.cols, 'x')
    };

    // Create debug visualization if requested
    if (debug) {
      const debugMat = createEnhancedDebugVisualization(src, lines, gridLines);
      return { lines: gridLines, debug: debugMat };
    }

    return { lines: gridLines };
  } finally {
    lines.delete();
    edges.delete();
    preprocessed.delete();
  }
};

// Enhanced line grouping with better angle analysis
const groupLinesEnhanced = (lines, imageSize) => {
  const horizontal = [];
  const vertical = [];
  const angleThreshold = Math.PI / 6;
  const minLength = Math.min(imageSize.width, imageSize.height) * 0.15; // 15% of image size

  for (let i = 0; i < lines.rows; i++) {
    const line = lines.data32S.slice(i * 4, (i + 1) * 4);
    const [x1, y1, x2, y2] = line;
    
    const angle = Math.atan2(y2 - y1, x2 - x1);
    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    
    // Filter out short lines
    if (length < minLength) continue;

    // Calculate line strength based on length and position
    const strength = calculateLineStrength(line, length, imageSize);
    
    if (Math.abs(angle) < angleThreshold || Math.abs(angle - Math.PI) < angleThreshold) {
      horizontal.push({ line, length, angle, strength });
    } else if (Math.abs(angle - Math.PI/2) < angleThreshold || 
               Math.abs(angle + Math.PI/2) < angleThreshold) {
      vertical.push({ line, length, angle, strength });
    }
  }

  // Sort by strength and length
  const sortByImportance = (a, b) => {
    const importanceA = a.strength * a.length;
    const importanceB = b.strength * b.length;
    return importanceB - importanceA;
  };

  return {
    horizontal: horizontal.sort(sortByImportance).slice(0, 10),
    vertical: vertical.sort(sortByImportance).slice(0, 10)
  };
};

// Calculate line strength based on position and length
const calculateLineStrength = (line, length, imageSize) => {
  const [x1, y1, x2, y2] = line;
  const centerX = imageSize.width / 2;
  const centerY = imageSize.height / 2;
  
  // Calculate distance from image center
  const lineCenterX = (x1 + x2) / 2;
  const lineCenterY = (y1 + y2) / 2;
  const distanceFromCenter = Math.sqrt(
    Math.pow(lineCenterX - centerX, 2) + 
    Math.pow(lineCenterY - centerY, 2)
  );
  
  // Normalize distance (closer to center = stronger)
  const maxDistance = Math.sqrt(
    Math.pow(imageSize.width / 2, 2) + 
    Math.pow(imageSize.height / 2, 2)
  );
  const distanceScore = 1 - (distanceFromCenter / maxDistance);
  
  // Combine with length score
  const lengthScore = length / Math.max(imageSize.width, imageSize.height);
  
  return (distanceScore + lengthScore) / 2;
};

// Enhanced line clustering with better gap handling
const clusterLinesEnhanced = (lines, dimension, coordKey) => {
  const clusters = [];
  const threshold = dimension * 0.05;
  
  // Sort lines by coordinate for better clustering
  const sortedLines = lines.slice().sort((a, b) => {
    const coordA = getLineCoordinate(a.line, coordKey);
    const coordB = getLineCoordinate(b.line, coordKey);
    return coordA - coordB;
  });
  
  for (const line of sortedLines) {
    const coord = getLineCoordinate(line.line, coordKey);
    let foundCluster = false;
    
    for (const cluster of clusters) {
      const clusterCoord = getClusterCoordinate(cluster, coordKey);
      if (Math.abs(coord - clusterCoord) < threshold) {
        // Add line to cluster with weighted average
        addLineToCluster(cluster, line, coord);
        foundCluster = true;
        break;
      }
    }
    
    if (!foundCluster) {
      clusters.push({
        lines: [line],
        coords: [coord],
        weights: [line.strength]
      });
    }
  }
  
  // Post-process clusters
  return clusters.map(cluster => ({
    coord: getWeightedAverage(cluster.coords, cluster.weights),
    lines: cluster.lines,
    strength: cluster.weights.reduce((a, b) => a + b, 0)
  }));
};

const getLineCoordinate = (line, coordKey) => {
  const [x1, y1, x2, y2] = line;
  return coordKey === 'y' ? (y1 + y2) / 2 : (x1 + x2) / 2;
};

const getClusterCoordinate = (cluster, coordKey) => {
  return getWeightedAverage(cluster.coords, cluster.weights);
};

const getWeightedAverage = (values, weights) => {
  const sum = weights.reduce((acc, w, i) => acc + w * values[i], 0);
  const weightSum = weights.reduce((a, b) => a + b, 0);
  return sum / weightSum;
};

const addLineToCluster = (cluster, line, coord) => {
  cluster.lines.push(line);
  cluster.coords.push(coord);
  cluster.weights.push(line.strength);
};

// Enhanced debug visualization
const createEnhancedDebugVisualization = (src, allLines, gridLines) => {
  const debugMat = cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC3);
  
  // Draw original image as background with reduced opacity
  const bgMat = new cv.Mat();
  cv.cvtColor(src, bgMat, cv.COLOR_GRAY2BGR);
  cv.addWeighted(debugMat, 0.3, bgMat, 0.7, 0, debugMat);
  bgMat.delete();

  // Draw all detected lines in gray with reduced opacity
  for (let i = 0; i < allLines.rows; i++) {
    const line = allLines.data32S.slice(i * 4, (i + 1) * 4);
    cv.line(
      debugMat,
      new cv.Point(line[0], line[1]),
      new cv.Point(line[2], line[3]),
      new cv.Scalar(128, 128, 128, 128),
      1,
      cv.LINE_AA
    );
  }
  
  // Draw grid lines with variable thickness based on strength
  const drawClusteredLines = (clusters, isHorizontal) => {
    const baseColor = isHorizontal ? 
      new cv.Scalar(0, 255, 0) :  // Green for horizontal
      new cv.Scalar(0, 0, 255);   // Red for vertical
      
    clusters.forEach(cluster => {
      cluster.lines.forEach(line => {
        const thickness = Math.max(1, Math.min(3, Math.floor(line.strength * 5)));
        const [x1, y1, x2, y2] = line.line;
        cv.line(
          debugMat,
          new cv.Point(x1, y1),
          new cv.Point(x2, y2),
          baseColor,
          thickness,
          cv.LINE_AA
        );
      });
    });
  };

  drawClusteredLines(gridLines.horizontal, true);
  drawClusteredLines(gridLines.vertical, false);
  
  return debugMat;
};

export const extractGridCorners = (horizontalLines, verticalLines, imageSize) => {
  const sortedHorizontal = horizontalLines.sort((a, b) => a.coord - b.coord);
  const sortedVertical = verticalLines.sort((a, b) => a.coord - b.coord);

  const gridLines = {
    top: sortedHorizontal[0],
    bottom: sortedHorizontal[sortedHorizontal.length - 1],
    left: sortedVertical[0],
    right: sortedVertical[sortedVertical.length - 1]
  };

  const corners = [
    findIntersection(gridLines.top, gridLines.left),
    findIntersection(gridLines.top, gridLines.right),
    findIntersection(gridLines.bottom, gridLines.right),
    findIntersection(gridLines.bottom, gridLines.left)
  ];

  return corners.every(corner => 
    corner && 
    corner.x >= 0 && corner.x < imageSize.width &&
    corner.y >= 0 && corner.y < imageSize.height
  ) ? corners : null;
};

const findIntersection = (line1, line2) => {
  const l1 = line1.lines[0].line;
  const l2 = line2.lines[0].line;
  
  const x1 = l1[0], y1 = l1[1];
  const x2 = l1[2], y2 = l1[3];
  const x3 = l2[0], y3 = l2[1];
  const x4 = l2[2], y4 = l2[3];
  
  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (denominator === 0) return null;
  
  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
  
  return {
    x: x1 + t * (x2 - x1),
    y: y1 + t * (y2 - y1)
  };
};

// Functions from file_context_0
export const enhanceGridDetection = (src) => {
  const result = {
    success: false,
    corners: null,
    debugImages: {},
    errors: []
  };

  try {
    // 1. Enhance image contrast
    const enhanced = new cv.Mat();
    cv.normalize(src, enhanced, 0, 255, cv.NORM_MINMAX);

    // 2. Try multiple preprocessing combinations
    const attempts = [
      { blur: 5, threshold1: 50, threshold2: 150 },
      { blur: 3, threshold1: 30, threshold2: 200 },
      { blur: 7, threshold1: 70, threshold2: 100 }
    ];

    for (const params of attempts) {
      const { lines, debug } = detectGridLines(enhanced, true, params);
      result.debugImages[`attempt-${attempts.indexOf(params)}`] = debug;

      if (lines.horizontal.length >= 9 && lines.vertical.length >= 9) {
        const corners = extractGridCorners(lines.horizontal, lines.vertical, src.size());
        if (corners && validateCorners(corners, src.size())) {
          result.success = true;
          result.corners = corners;
          break;
        }
      }
    }

    if (!result.success) {
      // Try Hough transform as fallback
      const houghResult = detectGridWithHoughTransform(enhanced);
      if (houghResult.success) {
        result.success = true;
        result.corners = houghResult.corners;
        result.debugImages['hough'] = houghResult.debug;
      }
    }

    enhanced.delete();
    return result;
  } catch (error) {
    console.error('Error in grid detection:', error);
    result.errors.push(error.message);
    return result;
  }
};

const validateCorners = (corners, imageSize) => {
  // Check if corners form a reasonable quadrilateral
  const minArea = imageSize.width * imageSize.height * 0.2;  // At least 20% of image
  const maxArea = imageSize.width * imageSize.height * 0.95; // At most 95% of image
  
  const area = calculateQuadArea(corners);
  if (area < minArea || area > maxArea) return false;

  // Check aspect ratio
  const aspectRatio = calculateAspectRatio(corners);
  if (aspectRatio < 0.7 || aspectRatio > 1.3) return false;

  return true;
};

const calculateQuadArea = (corners) => {
  // Calculate area using shoelace formula
  let area = 0;
  for (let i = 0; i < corners.length; i++) {
    const j = (i + 1) % corners.length;
    area += corners[i].x * corners[j].y;
    area -= corners[j].x * corners[i].y;
  }
  return Math.abs(area) / 2;
};

const calculateAspectRatio = (corners) => {
  // Calculate width and height of bounding box
  const xs = corners.map(c => c.x);
  const ys = corners.map(c => c.y);
  const width = Math.max(...xs) - Math.min(...xs);
  const height = Math.max(...ys) - Math.min(...ys);
  return width / height;
};

export const detectGridWithHoughTransform = (src) => {
  const result = {
    success: false,
    corners: null,
    debug: null
  };

  try {
    const edges = new cv.Mat();
    const lines = new cv.Mat();
    
    // Apply Canny edge detection
    cv.Canny(src, edges, 50, 150, 3, false);
    
    // Apply Hough transform
    cv.HoughLines(
      edges,
      lines,
      1,
      Math.PI / 180,
      100  // threshold
    );

    // Process detected lines
    const horizontalLines = [];
    const verticalLines = [];
    
    // Sort lines by angle
    for (let i = 0; i < lines.rows; i++) {
      const rho = lines.data32F[i * 2];
      const theta = lines.data32F[i * 2 + 1];
      
      // Group lines into horizontal and vertical
      if (Math.abs(theta) < Math.PI / 4 || Math.abs(theta - Math.PI) < Math.PI / 4) {
        verticalLines.push({ rho, theta });
      } else {
        horizontalLines.push({ rho, theta });
      }
    }

    // Need at least 2 lines in each direction
    if (horizontalLines.length >= 2 && verticalLines.length >= 2) {
      // Find the strongest lines
      const top = horizontalLines[0];
      const bottom = horizontalLines[horizontalLines.length - 1];
      const left = verticalLines[0];
      const right = verticalLines[verticalLines.length - 1];

      // Convert to points
      const corners = [
        findIntersectionFromHough(top, left),    // Top-left
        findIntersectionFromHough(top, right),   // Top-right
        findIntersectionFromHough(bottom, right), // Bottom-right
        findIntersectionFromHough(bottom, left)   // Bottom-left
      ];

      if (corners.every(corner => corner !== null)) {
        result.success = true;
        result.corners = corners;
      }
    }

    // Create debug visualization
    if (src.rows > 0 && src.cols > 0) {
      const debugMat = new cv.Mat();
      cv.cvtColor(edges, debugMat, cv.COLOR_GRAY2BGR);
      
      if (result.corners) {
        // Draw detected corners
        result.corners.forEach((corner, i) => {
          const nextCorner = result.corners[(i + 1) % 4];
          cv.line(
            debugMat,
            new cv.Point(corner.x, corner.y),
            new cv.Point(nextCorner.x, nextCorner.y),
            new cv.Scalar(0, 255, 0), // Green
            2
          );
        });
      }
      
      result.debug = debugMat;
    }

    edges.delete();
    lines.delete();
    
    return result;

  } catch (error) {
    console.error('Error in Hough transform grid detection:', error);
    return result;
  }
};

// Helper function to find intersection of two lines in Hough space
const findIntersectionFromHough = (line1, line2) => {
  const rho1 = line1.rho, theta1 = line1.theta;
  const rho2 = line2.rho, theta2 = line2.theta;
  
  const cos1 = Math.cos(theta1);
  const sin1 = Math.sin(theta1);
  const cos2 = Math.cos(theta2);
  const sin2 = Math.sin(theta2);
  
  const det = cos1 * sin2 - sin1 * cos2;
  
  if (Math.abs(det) < 1e-6) {
    return null; // Lines are parallel
  }
  
  const x = (sin2 * rho1 - sin1 * rho2) / det;
  const y = (-cos2 * rho1 + cos1 * rho2) / det;
  
  return { x, y };
};