// src/components/sudoku/ui/alert.js
import React from 'react';
import { AlertTriangle, CheckCircle2, Info, X } from 'lucide-react';

const variants = {
  default: {
    container: 'bg-blue-50 text-blue-900 border border-blue-200',
    icon: Info
  },
  destructive: {
    container: 'bg-red-50 text-red-900 border border-red-200',
    icon: AlertTriangle
  },
  success: {
    container: 'bg-green-50 text-green-900 border border-green-200',
    icon: CheckCircle2
  },
  warning: {
    container: 'bg-yellow-50 text-yellow-900 border border-yellow-200',
    icon: AlertTriangle
  }
};

export function Alert({ variant = 'default', className = '', children, ...props }) {
  const variantStyles = variants[variant] || variants.default;
  const Icon = variantStyles.icon;

  return (
    <div
      role="alert"
      className={`relative w-full rounded-lg p-4 ${variantStyles.container} ${className}`}
      {...props}
    >
      {Icon && <Icon className="absolute left-4 top-4 h-5 w-5" />}
      <div className="pl-7">{children}</div>
    </div>
  );
}

export function AlertTitle({ className = '', children, ...props }) {
  return (
    <h5
      className={`mb-1 font-medium leading-none tracking-tight ${className}`}
      {...props}
    >
      {children}
    </h5>
  );
}

export function AlertDescription({ className = '', children, ...props }) {
  return (
    <div
      className={`text-sm [&_p]:leading-relaxed ${className}`}
      {...props}
    >
      {children}
    </div>
  );
}